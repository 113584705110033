// src/components/fileExplorer/FileExplorer.js

import React, { useContext, useState, useEffect, useRef } from 'react';
import './fileExplorer.css';
import {
  AiOutlineDelete,
  AiOutlineFilePdf,
  AiOutlineClose,
  AiOutlineEdit,
  AiOutlineCheck,
  AiOutlinePlus,
  AiOutlineSearch,
  AiOutlineArrowLeft,
  AiOutlineFileZip,
  AiOutlineFile,
  AiOutlineEye,
  AiOutlineShareAlt,
} from 'react-icons/ai';
import { FaFolder, FaFileWord, FaFileAlt, FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';
import LoadingSpinner from '../assets/loadingSpinner';
import XLSXViewer from './xlsxViewer';
import SharePopup from './sharePopup';
import ProjectSelector from '../projectSelector';
import ConfirmationModal from '../confirmationModal';
import { DocumentContext } from '../../context/DocumentContext';
import { getIdToken } from '../../services/userDataService';
import axios from 'axios';
import { useNotification } from '../useNotification';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import OneDriveModal from '../../integrations/OneDriveModal';
import { useMsal, useAccount } from '@azure/msal-react';
import { loginRequest } from '../msalConfig';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
// Import the new GoogleDriveModal at the top with other imports
import GoogleDriveModal from '../../integrations/GoogleDriveModal';
import VersionConflictModal from './VersionConflictModal'; // Adjust the path as necessary
import { useNavigate } from 'react-router-dom'; // Add this import at the top
import MoveModal from './MoveModal'; // Adjust path accordingly




/**
 * FileExplorer Component
 * Displays a list of documents and folders with options to edit and delete.
 */
const FileExplorer = ({ userId, userData }) => {
  // Consume DocumentContext
  const {
    documents,
    folders,
    isLoading,
    error,
    navigateToFolder,
    addOrUpdateDocument,
    currentFolder,
    breadcrumbs,
  } = useContext(DocumentContext);

  const { addNotification } = useNotification();


  // MSAL Hooks
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [accessToken, setAccessToken] = useState(null);

  // State variables for modals and UI elements
  const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);
  const [sharingDocument, setSharingDocument] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState('document_name');
  const [sortDirection, setSortDirection] = useState('asc');
  const [editingDocId, setEditingDocId] = useState(null);
  const [editedProject, setEditedProject] = useState('');
  const [isNewFolderModalOpen, setIsNewFolderModalOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isAddDropdownOpen, setIsAddDropdownOpen] = useState(false);
  const [isOneDriveModalOpen, setIsOneDriveModalOpen] = useState(false);
  const [isGoogleDriveModalOpen, setIsGoogleDriveModalOpen] = useState(false);
  const [documentVersion, setDocumentVersion] = useState(null);
  const selectAllRef = useRef(null);
  const pickerRef = useRef(null);
  const [newTag, setNewTag] = useState('');

  // State variables for bulk actions
  const [isBulkAddTagModalOpen, setIsBulkAddTagModalOpen] = useState(false);
  const [bulkAddTags, setBulkAddTags] = useState('');
  const [isBulkDeleteTagModalOpen, setIsBulkDeleteTagModalOpen] = useState(false);
  const [bulkDeleteTags, setBulkDeleteTags] = useState('');


  // // Column widths state
  const [columnWidths, setColumnWidths] = useState({
    type: 60, // Fixed width for "Type" column
    name: 700,
    version: 100,
    tags: 140,
    addedBy: 100,
    size: 100,
    status: 120,
    project: 180,
    actions: 200,
  });

  // // Resizer refs
  // const resizerRefs = useRef({});

  // // State for dragging
  // const [isResizing, setIsResizing] = useState(false);
  // const [currentColumn, setCurrentColumn] = useState(null);
  // const startXRef = useRef(0);
  // const startWidthRef = useRef(0);

  function formatFileSize(sizeInBytes) {
    if (sizeInBytes === undefined || sizeInBytes === null) return 'N/A';

    const i = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    // Make sure we don’t go beyond 'TB'
    const index = Math.min(i, units.length - 1);
    const size = sizeInBytes / Math.pow(1024, index);
    return `${size.toFixed(2)} ${units[index]}`;
  }

  // States for file upload
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);

  const [isMoveModalOpen, setIsMoveModalOpen] = useState(false);
  const [targetFolderId, setTargetFolderId] = useState(null);
  const [allFolders, setAllFolders] = useState([]);


  const fileInputRef = useRef(null);

  // State to control the visibility of the Version Conflict Modal
  const [isVersionConflictModalOpen, setIsVersionConflictModalOpen] = useState(false);

  // State to store the file that caused the conflict
  const [conflictingFile, setConflictingFile] = useState(null);

  // State to store the current version of the conflicting document
  const [currentVersion, setCurrentVersion] = useState(null);

  const navigate = useNavigate(); // Add this line inside the FileExplorer component
  // Add this handler function with other handlers
  const handleGoogleDriveSelect = async (selectedFiles, token) => {
    try {
      setIsUploading(true);

      for (const file of selectedFiles) {
        // Get file metadata from Google Drive
        const response = await fetch(
          `https://www.googleapis.com/drive/v3/files/${file.id}?alt=media`,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );

        // Create a proper File object with the correct MIME type
        const fileBlob = await response.blob();
        const actualFile = new File(
          [fileBlob],
          file.name,
          {
            type: file.mimeType || getMimeTypeFromFilename(file.name)
          }
        );
        // Prepare file for upload with correct contentType
        const preparedFile = {
          file: actualFile,
          metadata: {
            keywords: '',
            project: '',
            contentType: file.mimeType || getMimeTypeFromFilename(file.name)
          },
          customFields: [],
        };

        // Use existing upload logic
        await handleSubmit([preparedFile]);
      }

      addNotification('Google Drive files imported successfully.', 'success');
      setIsGoogleDriveModalOpen(false);
    } catch (error) {
      console.error('Error importing Google Drive files:', error);
      addNotification('Failed to import Google Drive files.', 'error');
    } finally {
      setIsUploading(false);
    }
  };

  // Helper function to determine MIME type from filename if not provided by Google Drive
  const getMimeTypeFromFilename = (filename) => {
    const extension = filename.split('.').pop().toLowerCase();
    const mimeTypes = {
      'pdf': 'application/pdf',
      'doc': 'application/msword',
      'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'xls': 'application/vnd.ms-excel',
      'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'ppt': 'application/vnd.ms-powerpoint',
      'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'txt': 'text/plain',
      'csv': 'text/csv',
      'jpg': 'image/jpeg',
      'jpeg': 'image/jpeg',
      'png': 'image/png',
      'gif': 'image/gif',
      'zip': 'application/zip',
      // Add more as needed
    };
    return mimeTypes[extension] || 'application/octet-stream';
  };

  // Acquire Token for FileExplorer (if needed)
  const acquireTokenForFileExplorer = async () => {
    if (!account) {
      try {
        const response = await instance.loginPopup(loginRequest);
        setAccessToken(response.accessToken);
      } catch (error) {
        console.error(error);
        addNotification('Failed to acquire token', 'error');
      }
    } else {
      try {
        const response = await instance.acquireTokenSilent({
          ...loginRequest,
          account: account,
        });
        setAccessToken(response.accessToken);
      } catch (err) {
        if (err instanceof InteractionRequiredAuthError) {
          try {
            const response = await instance.acquireTokenPopup(loginRequest);
            setAccessToken(response.accessToken);
          } catch (error) {
            console.error(error);
            addNotification('Failed to acquire token', 'error');
          }
        } else {
          console.error(err);
          addNotification('Failed to acquire token', 'error');
        }
      }
    }
  };

  // /**
  //  * Load persisted column widths from localStorage on component mount
  //  */
  // useEffect(() => {
  //   const savedWidths = localStorage.getItem('columnWidths');
  //   if (savedWidths) {
  //     setColumnWidths(JSON.parse(savedWidths));
  //   }
  // }, []);


  useEffect(() => {
    const fetchFolders = async () => {
      if (!userData?.user_data?.org_id) return;
      try {
        const token = await getIdToken();
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/folders`, {
          params: {
            org_id: userData.user_data.org_id,
          },
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        console.log('Fetched folders:', response.data.folders);
        const fetchedFolders = response.data.folders || [];
        setAllFolders(fetchedFolders);
      } catch (error) {
        console.error("Error fetching folders:", error);
        addNotification("Failed to fetch folders", "error");
      }
    };

    fetchFolders();
  }, [userData, addNotification, setAllFolders]);
  /**
   * Handle selection from OneDriveModal
   * Receives both the selected item and the access token
   */
  const handleOneDriveSelect = async (item, oneDriveAccessToken) => {
    if (item.folder) {
      // Handle folder selection if needed
      addNotification('Please select a file to import.', 'info');
      return;
    }

    try {
      setIsUploading(true);

      // Get the download URL
      // Option 1: Use the access token to download the file content directly
      // Option 2: Use the Microsoft Graph API to get a download URL

      // Option 1: Direct download
      const downloadResponse = await axios.get(`https://graph.microsoft.com/v1.0/me/drive/items/${item.id}/content`, {
        headers: {
          Authorization: `Bearer ${oneDriveAccessToken}`,
        },
        responseType: 'blob', // Get the file as a blob
      });

      const fileBlob = downloadResponse.data;
      const file = new File([fileBlob], item.name, { type: item.file.mimeType });

      // Prepare file for upload to Argus
      const preparedFile = {
        file,
        metadata: { keywords: '', project: '' },
        customFields: [],
      };

      // Upload the file using your existing handleSubmit function
      await handleSubmit([preparedFile]);

      addNotification('OneDrive file imported successfully.', 'success');
      onCloseOneDriveModal();
    } catch (error) {
      console.error('Error importing OneDrive file:', error);
      addNotification('Failed to import OneDrive file.', 'error');
    } finally {
      setIsUploading(false);
    }
  };

  const onCloseOneDriveModal = () => {
    setIsOneDriveModalOpen(false);
  };


  // /**
  //  * Persist column widths to localStorage whenever they change
  //  */
  // useEffect(() => {
  //   localStorage.setItem('columnWidths', JSON.stringify(columnWidths));
  // }, [columnWidths]);

  // /**
  //  * Handle mouse move for resizing
  //  */
  // useEffect(() => {
  //   const handleMouseMove = (e) => {
  //     if (!isResizing || !currentColumn) return;
  //     const deltaX = e.clientX - startXRef.current;
  //     const newWidth = startWidthRef.current + deltaX;
  //     if (newWidth > 50 && newWidth < 1000) { // Set min and max widths
  //       setColumnWidths((prev) => ({
  //         ...prev,
  //         [currentColumn]: newWidth,
  //       }));
  //     }
  //   };

  //   const handleMouseUp = () => {
  //     if (isResizing) {
  //       setIsResizing(false);
  //       setCurrentColumn(null);
  //     }
  //   };

  //   if (isResizing) {
  //     document.addEventListener('mousemove', handleMouseMove);
  //     document.addEventListener('mouseup', handleMouseUp);
  //   } else {
  //     document.removeEventListener('mousemove', handleMouseMove);
  //     document.removeEventListener('mouseup', handleMouseUp);
  //   }

  //   return () => {
  //     document.removeEventListener('mousemove', handleMouseMove);
  //     document.removeEventListener('mouseup', handleMouseUp);
  //   };
  // }, [isResizing, currentColumn]);

  /**
   * Toggle the "Add" dropdown menu
   */
  const toggleAddDropdown = () => {
    setIsAddDropdownOpen(!isAddDropdownOpen);
  };

  /**
   * Close the "Add" dropdown when clicking outside
   */
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isAddDropdownOpen && !event.target.closest('.add-button-container')) {
        setIsAddDropdownOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isAddDropdownOpen]);

  // /**
  //  * Handle resizing of columns
  //  */
  // const handleMouseDown = (e, column) => {
  //   e.preventDefault();
  //   setIsResizing(true);
  //   setCurrentColumn(column);
  //   startXRef.current = e.clientX;
  //   startWidthRef.current = columnWidths[column];
  // };

  /**
   * Trigger the hidden file input
   */
  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  /**
   * Handle file selection
   */
  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    if (selectedFiles.length === 0) return;

    // Prepare files for upload
    const preparedFiles = selectedFiles.map((file) => ({
      file,
      metadata: { keywords: '', project: '' },
      customFields: [],
    }));

    setFilesToUpload(preparedFiles);
    handleSubmit(preparedFiles);
  };

  /**
   * Acquire presigned URL from backend
   * @param {string} fileName - Name of the file
   * @param {string} contentType - MIME type of the file
   * @returns {object} - Presigned URL data
   */
  const getPresignedUrl = async (fileName, contentType) => {
    try {
      console.log('Getting presigned URL for:', { fileName, contentType }); // Debugging
      const token = await getIdToken();
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/getPresignedURL`,
        {
          fileName,
          contentType,
          orgId: userData.user_data.org_id,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error getting presigned URL:', error);
      addNotification('Failed to get upload URL', 'error');
      throw error;
    }
  };

  /**
 * Uploads a single file using a presigned URL
 */
  const uploadFile = async (fileObj, token, version = null) => {
    try {
      const { file, metadata, customFields } = fileObj;

      // Get presigned URL from backend
      const presignedResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/getPresignedURL`,
        {
          fileName: file.name,
          contentType: file.type,
          orgId: userData.user_data.org_id,
          version: version, // Pass version if provided
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const { presignedUrl, bucket, key } = presignedResponse.data;

      // Upload to S3 using presigned URL
      await axios.put(presignedUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      });

      // Notify backend of successful upload
      const uploadNotification = {
        fileName: file.name,
        userId: userData.user_data.user_id,
        orgId: userData.user_data.org_id,
        database: userData.vector_databases[0].database_id,
        bucket: bucket,
        key: key,
        contentType: file.type,
        fileSize: file.size,
        source: 'local',
        folderId: currentFolder,
        metadata: {
          ...metadata,
          customFields: customFields,
        },
        version: version || 1, // Default to version 1 if not provided
        document_id: fileObj.document_id || '', // Ensure this is available in fileObj
      };

      await axios.post(`${process.env.REACT_APP_BASE_URL}/uploadFile`, uploadNotification, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      console.error('Error uploading file:', error);
      addNotification(`Failed to upload file ${fileObj.file.name}`, 'error');
      throw error;
    }
  };

  /**
   * Handle file submission with version conflict detection
   */
  const handleSubmit = async (files) => {
    setIsUploading(true);
    setUploadError(null);

    try {
      const token = await getIdToken();

      for (const fileObj of files) {
        const { file } = fileObj;

        // Check if the document exists
        const checkResponse = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/documents/exists`,
          {
            org_id: userData.user_data.org_id,
            document_name: file.name,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          }
        );

        if (checkResponse.data.exists) {
          // Document exists, set the conflicting file and current version, then open modal
          setConflictingFile({
            ...fileObj,
            document_id: checkResponse.data.document_id, // Ensure backend returns this
          });
          setCurrentVersion(checkResponse.data.current_version);
          setIsVersionConflictModalOpen(true);
          break; // Stop processing further files until conflict is resolved
        }

        // If no conflict, proceed with upload
        await uploadFile(fileObj, token);
      }

      if (!isVersionConflictModalOpen) {
        // If no conflict detected for any file
        addNotification('Files uploaded successfully', 'success');
        setFilesToUpload([]);
        if (fileInputRef.current) fileInputRef.current.value = '';
      }
    } catch (error) {
      console.error('Upload error:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
      }
      addNotification('Failed to upload file(s)', 'error');
    } finally {
      setIsUploading(false);
    }
  };

  /**
 * Handler for adding a new version
 */
  const handleAddNewVersion = async () => {
    if (!conflictingFile) {
      addNotification('No file selected for uploading.', 'error');
      return;
    }

    try {
      const token = await getIdToken();
      const newVersion = currentVersion + 1;

      // Proceed to upload the file with new version
      await uploadFile(conflictingFile, token, newVersion);

      addNotification(`File uploaded as version ${newVersion}`, 'success');
      setIsVersionConflictModalOpen(false);
      setConflictingFile(null);
      setCurrentVersion(null);
      setFilesToUpload([]);
      if (fileInputRef.current) fileInputRef.current.value = '';
    } catch (error) {
      console.error('Error uploading new version:', error);
      addNotification('Failed to upload new version.', 'error');
    }
  };

  /**
   * Handler for replacing the current version
   */
  const handleReplaceVersion = async () => {
    if (!conflictingFile) {
      addNotification('No file selected for uploading.', 'error');
      return;
    }

    try {
      const token = await getIdToken();
      const versionToReplace = currentVersion; // Replace the current version

      // Call backend to delete the existing version
      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/documents/replaceVersion`,
        {
          data: {
            document_id: conflictingFile.document_id, // Ensure this is available in fileObj
            version: versionToReplace,
          },
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // Proceed to upload the new file with the same version
      await uploadFile(conflictingFile, token, versionToReplace);

      addNotification(`File replaced successfully with version ${versionToReplace}`, 'success');
      setIsVersionConflictModalOpen(false);
      setConflictingFile(null);
      setCurrentVersion(null);
      setFilesToUpload([]);
      if (fileInputRef.current) fileInputRef.current.value = '';
    } catch (error) {
      console.error('Error replacing version:', error);
      addNotification('Failed to replace the current version.', 'error');
    }
  };

  /**
   * Handler for canceling the upload
   */
  const handleCancelUpload = () => {
    setIsVersionConflictModalOpen(false);
    setConflictingFile(null);
    setCurrentVersion(null);
    setFilesToUpload([]);
    if (fileInputRef.current) fileInputRef.current.value = '';
    addNotification('File upload canceled.', 'info');
  };


  /**
   * Handle sorting when a column header is clicked
   */
  const handleSort = (field) => {
    if (sortField === field) {
      // Toggle sort direction if the same field is clicked
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // Set new sort field and default to ascending
      setSortField(field);
      setSortDirection('asc');
    }
  };

  /**
   * Render sort icons based on the current sort field and direction
   */
  const renderSortIcon = (field) => {
    if (sortField !== field) return <FaSort />;
    return sortDirection === 'asc' ? <FaSortUp /> : <FaSortDown />;
  };

  /**
   * Handle selecting all items (documents and folders)
   */
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allIds = [...folders.map((folder) => folder.id), ...documents.map((doc) => doc.document_uuid)];
      setSelectedItems(allIds);
    } else {
      setSelectedItems([]);
    }
  };

  /**
   * Handle individual item selection
   */
  const handleSelectItem = (e, id) => {
    if (e.target.checked) {
      setSelectedItems((prev) => [...prev, id]);
    } else {
      setSelectedItems((prev) => prev.filter((itemId) => itemId !== id));
    }
  };

  /**
   * Update the "Select All" checkbox's indeterminate state
   */
  useEffect(() => {
    if (selectAllRef.current) {
      selectAllRef.current.indeterminate =
        selectedItems.length > 0 && selectedItems.length < folders.length + documents.length;
    }
  }, [selectedItems, folders.length, documents.length]);

  /**
   * Handle viewing a document
   */
  const handleView = (url, type) => {
    callViewFunction(url, type);
  };

  /**
   * Call the view function to fetch the presigned URL and set viewer state
   */
  const callViewFunction = async (url, documentType) => {
    console.log('Calling view function with:', { url, documentType });
    try {
      const encodedUrl = encodeURIComponent(url);
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/inClientViewer?file_url=${encodedUrl}`, {}, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await getIdToken()}`,
        },
      });

      const parsed_data = response.data;
      console.log('Parsed data:', parsed_data);

      if (parsed_data.presigned_url) {
        setViewerUrl(parsed_data.presigned_url);
        setViewerType(documentType);
        console.log('Set viewer URL and type:', { url: parsed_data.presigned_url, type: documentType });
      } else {
        throw new Error('No URL found in the response');
      }
    } catch (error) {
      console.error('Error with fetch:', error);
      addNotification('Failed to load viewer', 'error');
    }
  };

  /**
   * Adds a new tag to a specific document.
   *
   * @param {string} documentId - The UUID of the document.
   * @param {string} tag - The name of the tag to add.
   */
  const handleAddTag = async (documentId, tag) => {
    const trimmedTag = tag.trim();
    // Validate the tag input
    if (!trimmedTag) {
      addNotification('Tag cannot be empty.', 'error');
      return;
    }

    // Find the document in the context
    const document = documents.find(doc => doc.document_uuid === documentId);

    if (!document) {
      addNotification('Document not found.', 'error');
      return;
    }

    // Ensure tags is an array; default to empty array if undefined
    const currentTags = Array.isArray(document.tags) ? document.tags : [];

    // Prevent adding duplicate tags
    if (currentTags.includes(trimmedTag)) {
      addNotification(`Tag "${trimmedTag}" already exists for this document.`, 'info');
      return;
    }

    try {
      // Acquire the authentication token
      const token = await getIdToken();

      // Make the API call to add the tag
      // Make the API call to add the tag
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/tags`,
        {
          action: 'add',
          filename: document.document_name,
          org_id: userData.user_data.org_id,
          tags: [trimmedTag],
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // Update the document's tags in the context
        addOrUpdateDocument({
          ...document,
          tags: [...currentTags, trimmedTag],
        });

        // Notify the user of success
        addNotification(`Tag "${trimmedTag}" added successfully.`, 'success');

        // Reset input states if applicable
        setNewTag('');
        setEditingDocId(null);
      } else {
        throw new Error('Failed to add tag.');
      }
    } catch (error) {
      console.error('Error adding tag:', error);
      addNotification('Failed to add tag. Please try again.', 'error');
    }
  };


  /**
 * Removes a tag from a specific document.
 *
 * @param {string} documentId - The UUID of the document.
 * @param {string} tag - The name of the tag to remove.
 */
  const handleDeleteTag = async (documentId, tag) => {
    try {
      // Acquire the authentication token
      const token = await getIdToken();

      const document = documents.find(doc => doc.document_uuid === documentId);

      // Make the API call to remove the tag
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/tags`,
        {
          action: 'remove',
          filename: document.document_name,
          org_id: userData.user_data.org_id,
          tags: [tag],
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // Update the document's tags in the context
        if (document) {
          const updatedTags = document.tags.filter(t => t !== tag);
          addOrUpdateDocument({
            ...document,
            tags: updatedTags,
          });
        }

        // Notify the user of success
        addNotification(`Tag "${tag}" removed successfully.`, 'success');
      } else {
        throw new Error('Failed to remove tag.');
      }
    } catch (error) {
      console.error('Error removing tag:', error);
      addNotification('Failed to remove tag. Please try again.', 'error');
    }
  };


  /**
 * Adds multiple tags to multiple selected documents.
 */
  const handleBulkAddTags = async () => {
    // Split the input string into an array of trimmed tags
    const tags = bulkAddTags.split(',').map(tag => tag.trim()).filter(tag => tag);

    // Validate the tag inputs
    if (tags.length === 0) {
      addNotification('Please enter at least one tag.', 'error');
      return;
    }

    try {
      // Acquire the authentication token
      const token = await getIdToken();

      // Make the API call to bulk add tags
      const response = await axios.post(
        '/api/documents/bulk-tags',
        {
          document_ids: selectedItems, // Array of document UUIDs
          tags: tags,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // Update the tags for all selected documents in the context
        selectedItems.forEach(documentId => {
          const document = documents.find(doc => doc.document_uuid === documentId);
          if (document) {
            // Merge existing tags with new tags, ensuring uniqueness
            const updatedTags = [...document.tags, ...tags].filter((tag, index, self) => self.indexOf(tag) === index);
            addOrUpdateDocument({
              ...document,
              tags: updatedTags,
            });
          }
        });

        // Notify the user of success
        addNotification('Tags added successfully to selected documents.', 'success');

        // Reset bulk action states
        setIsBulkAddTagModalOpen(false);
        setBulkAddTags('');
        setSelectedItems([]); // Clear selection after bulk action
      } else {
        throw new Error('Failed to add tags.');
      }
    } catch (error) {
      console.error('Error bulk adding tags:', error);
      addNotification('Failed to add tags to selected documents.', 'error');
    }
  };



  /**
 * Removes multiple tags from multiple selected documents.
 */
  const handleBulkDeleteTags = async () => {
    // Split the input string into an array of trimmed tags
    const tags = bulkDeleteTags.split(',').map(tag => tag.trim()).filter(tag => tag);

    // Validate the tag inputs
    if (tags.length === 0) {
      addNotification('Please enter at least one tag to delete.', 'error');
      return;
    }

    try {
      // Acquire the authentication token
      const token = await getIdToken();

      // Make the API call to bulk delete tags
      const response = await axios.delete(
        '/api/documents/bulk-tags',
        {
          data: {
            document_ids: selectedItems, // Array of document UUIDs
            tags: tags,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // Update the tags for all selected documents in the context
        selectedItems.forEach(documentId => {
          const document = documents.find(doc => doc.document_uuid === documentId);
          if (document) {
            // Remove the specified tags from existing tags
            const updatedTags = document.tags.filter(tag => !tags.includes(tag));
            addOrUpdateDocument({
              ...document,
              tags: updatedTags,
            });
          }
        });

        // Notify the user of success
        addNotification('Tags deleted successfully from selected documents.', 'success');

        // Reset bulk action states
        setIsBulkDeleteTagModalOpen(false);
        setBulkDeleteTags('');
        setSelectedItems([]); // Clear selection after bulk action
      } else {
        throw new Error('Failed to delete tags.');
      }
    } catch (error) {
      console.error('Error bulk deleting tags:', error);
      addNotification('Failed to delete tags from selected documents.', 'error');
    }
  };



  /**
   * Handle sharing a document
   */
  const handleShare = (document) => {
    setSharingDocument(document);
    setIsSharePopupOpen(true);
  };

  /**
   * Handle completion of sharing a document
   */
  const handleShareComplete = async (document, selectedUsers) => {
    try {
      const token = await getIdToken();
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/shareDocument`,
        {
          documentId: document.id,
          sharedWith: selectedUsers.map((user) => user.id),
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data;
      if (data.success) {
        // Optionally update the local state to reflect the new sharing status
        addNotification('Document shared successfully', 'success');
      } else {
        throw new Error(data.message || 'Failed to share document');
      }

      setIsSharePopupOpen(false);
      setSharingDocument(null);
    } catch (error) {
      console.error('Error sharing document:', error);
      addNotification('Failed to share document', 'error');
    }
  };

  /**
   * Handle editing a document
   */
  const handleEditClick = (document) => {
    console.log('Editing document:', document);
    setEditingDocId(document.document_uuid);
    setEditedProject(document.project || '');
  };

  /**
   * Handle saving edits to a document
   */
  const handleSaveEdit = async (document) => {
    try {
      const token = await getIdToken();
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + '/updateDocument',
        {
          document_id: document.document_uuid,
          org_id: document.org_id,
          project_id: editedProject,
          vector_database: userData.vector_databases[0].database_id,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const updatedDoc = response.data;
        // When updating documents in the context
        addOrUpdateDocument({
          ...updatedDoc,
          document_uuid: updatedDoc.document_uuid,
          project_name: updatedDoc.project_name || 'No Project',
          tags: updatedDoc.tags || [], // Ensure tags is at least an empty array
        });
        setEditingDocId(null);
        setEditedProject('');
        addNotification('Document updated successfully', 'success');
      } else {
        console.error('Failed to update document');
        addNotification('Failed to update document', 'error');
      }
    } catch (error) {
      console.error('Error updating document:', error);
      addNotification('Failed to update document', 'error');
    }
  };

  /**
   * Handle deleting a document
   */
  const handleDeleteClick = (document) => {
    console.log('Deleting document:', document);
    setDocumentToDelete(document);
    setIsDeleteModalOpen(true);
  };

  /**
   * Confirm deletion of a document
   */
  const handleDeleteConfirm = async () => {
    if (documentToDelete) {
      console.log('Confirming deletion for document:', documentToDelete);
      try {
        const token = await getIdToken();

        const payload = {
          user_id: userData.user_data.user_id,
          org_id: userData.user_data.org_id,
          delete_type: 'file',
          item_id: documentToDelete.document_uuid,
          vector_db: userData.vector_databases[0].database_id, // Ensure this exists
        };

        const response = await axios.post(process.env.REACT_APP_BASE_URL + '/deleteFile', payload, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          // Assuming your backend removes the document from the context
          addOrUpdateDocument({
            document_uuid: documentToDelete.document_uuid,
            document_name: null, // Signals deletion
          });
          setIsDeleteModalOpen(false);
          setDocumentToDelete(null);
          addNotification('Document deleted successfully', 'success');
        } else {
          throw new Error('Failed to delete document');
        }
      } catch (error) {
        console.error('Error deleting document:', error);
        addNotification('Failed to delete document', 'error');
      }
    }
  };

  /**
   * Cancel deletion of a document
   */
  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setDocumentToDelete(null);
  };

  /**
   * Handle creating a new folder
   */
  const handleCreateNewFolder = async () => {
    if (!newFolderName.trim()) {
      alert('Please enter a folder name');
      return;
    }

    try {
      const token = await getIdToken();
      console.log(currentFolder)
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + '/add-new-folder',
        {
          folderName: newFolderName,
          parentFolderId: currentFolder,
          userId: userData.user_data.user_id,
          orgId: userData.user_data.org_id,
          path: breadcrumbs.map((crumb) => crumb.name).join('/') + '/' + newFolderName,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const data = response.data;
        console.log('New folder created:', data);
        setIsNewFolderModalOpen(false);
        setNewFolderName('');
        console.log(currentFolder)
        navigateToFolder(currentFolder); // Refresh the view by re-fetching the current folder
        addNotification('Folder created successfully', 'success');
      } else {
        throw new Error('Failed to create new folder');
      }
    } catch (error) {
      console.error('Error creating new folder:', error);
      addNotification('Failed to create new folder', 'error');
    }
  };

  /**
   * Cancel creation of a new folder
   */
  const handleNewFolderCancel = () => {
    setIsNewFolderModalOpen(false);
    setNewFolderName('');
  };

  /**
   * Handle sorting and searching documents
   */
  const filteredAndSortedDocuments = (documents)
    .filter((doc) => {
      const name = doc.document_name ? doc.document_name.toLowerCase() : '';
      const status = doc.status ? doc.status.toLowerCase() : '';
      const type = doc.document_type ? doc.document_type.toLowerCase() : '';

      return (
        name.includes(searchTerm.toLowerCase()) ||
        status.includes(searchTerm.toLowerCase()) ||
        type.includes(searchTerm.toLowerCase())
      );
    })

    .sort((a, b) => {
      let fieldA = a[sortField];
      let fieldB = b[sortField];
      if (typeof fieldA === 'string') fieldA = fieldA.toLowerCase();
      if (typeof fieldB === 'string') fieldB = fieldB.toLowerCase();
      if (fieldA < fieldB) return sortDirection === 'asc' ? -1 : 1;
      if (fieldA > fieldB) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });

  /**
   * Render breadcrumbs for navigation
   */
  const renderBreadcrumbs = () => (
    <div className="breadcrumbs">
      {breadcrumbs &&
        breadcrumbs.map((crumb, index) => (
          <span key={`${crumb.id || 'home'}-${index}`}>
            {index > 0 && ' > '}
            <span
              className="breadcrumb-item"
              onClick={() => navigateToFolder(crumb.id)}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter') navigateToFolder(crumb.id);
              }}
            >
              {crumb.name}
            </span>
          </span>
        ))}
    </div>
  );

  /**
   * Render the document viewer (e.g., PDF, XLSX)
   */
  const [viewerUrl, setViewerUrl] = useState(null);
  const [viewerType, setViewerType] = useState(null);

  const renderViewer = () => {
    console.log('Rendering viewer with:', { viewerUrl, viewerType });
    if (error) {
      return <div className="viewer-error">Error: {error}</div>;
    }
    switch (viewerType) {
      case 'xlsx':
      case 'xls':
        return <XLSXViewer fileUrl={viewerUrl} />;
      case 'pdf':
      case 'docx':
      case 'doc':
      default:
        return (
          <iframe
            src={viewerUrl}
            title="Document Viewer"
            className="viewer-iframe"
            onLoad={() => console.log('iframe loaded')}
            onError={(e) => console.error('iframe error:', e)}
          />
        );
    }
  };

  /**
   * Handle drag over event to allow drop
   */
  const handleFileDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    console.log('Files dropped:', files);
  };

  /**
   * Handle sorting when a column header is clicked
   */
  const handleSortClick = (field) => {
    handleSort(field);
  };

  const handleMoveDocuments = async (destinationFolderId) => {
    if (selectedItems.length === 0) {
      addNotification('No files selected for moving.', 'error');
      return;
    }

    try {
      const token = await getIdToken();
      // Assuming selectedItems includes both folders and documents.
      // If you only want to move documents, filter them out:
      const docIdsToMove = selectedItems.filter(itemId =>
        documents.some(doc => doc.document_uuid === itemId)
      );

      if (docIdsToMove.length === 0) {
        addNotification('No documents selected for moving.', 'error');
        return;
      }

      const payload = {
        user_id: userData.user_data.user_id,
        org_id: userData.user_data.org_id,
        documents: docIdsToMove,
        new_parent_folder_id: destinationFolderId || null
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/files/move`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        addNotification('Files moved successfully', 'success');
        setSelectedItems([]);
        // Refresh the current folder view
        navigateToFolder(currentFolder);
      } else {
        throw new Error('Failed to move files');
      }
    } catch (error) {
      console.error('Error moving files:', error);
      addNotification('Failed to move files', 'error');
    }
  };


  /**
   * Handle navigating back in breadcrumbs
   */
  const handleBackClick = () => {
    if (breadcrumbs.length > 1) {
      const newBreadcrumbs = breadcrumbs.slice(0, -1);
      const newCurrentFolder = newBreadcrumbs[newBreadcrumbs.length - 1].id;
      navigateToFolder(newCurrentFolder);
    }
  };

  /**
   * Render the modal for creating a new folder
   */
  const renderNewFolderModal = () => (
    <div className="modal-overlay">
      <div className="modal">
        <h2>Create New Folder</h2>
        <input
          type="text"
          value={newFolderName}
          onChange={(e) => setNewFolderName(e.target.value)}
          placeholder="Folder name"
          aria-label="Folder name"
        />
        <div className="modal-buttons">
          <button onClick={handleCreateNewFolder} className="create-button">
            Create
          </button>
          <button onClick={handleNewFolderCancel} className="cancel-button">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );

  /**
   * Render the table with headers and body, including resizable columns
   */
  const renderTable = () => (
    <table className="file-explorer-table">
      <thead>
        <tr>
          {/* Checkbox Column */}
          <th
            className="checkbox-column"
            style={{ width: `${columnWidths.type}px` }}
          >
            <input
              type="checkbox"
              ref={selectAllRef}
              onChange={handleSelectAll}
              checked={selectedItems.length === folders.length + documents.length && selectedItems.length > 0}
              aria-label="Select All Documents and Folders"
            />
          </th>

          {/* Type Column (Fixed, Non-Resizable) */}
          <th
            className="type-column"
            style={{ width: `${columnWidths.type}px` }}
            role="columnheader"
            aria-sort={sortField === 'document_type' ? sortDirection : 'none'}
          >
            <div
              className="th-content"
              role="button"
              tabIndex={0}
              onClick={() => handleSortClick('document_type')}
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleSortClick('document_type');
              }}
            >
              Type {renderSortIcon('document_type')}
            </div>
          </th>

          {/* Name Column (Resizable) */}
          <th
            className="name-column"
            style={{ width: `${columnWidths.name}px` }}
            role="columnheader"
            aria-sort={sortField === 'document_name' ? sortDirection : 'none'}
          >
            <div
              className="th-content"
              role="button"
              tabIndex={0}
              onClick={() => handleSortClick('document_name')}
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleSortClick('document_name');
              }}
            >
              Name {renderSortIcon('document_name')}
            </div>
            {/* Resizer */}
            {/* <div
              className="resizer"
              onMouseDown={(e) => handleMouseDown(e, 'name')}
            /> */}
          </th>
          {/* Tags Column (Resizable) */}
          <th
            className="tags-column"
            style={{ width: `${columnWidths.tags}px` }}
            role="columnheader"
            aria-sort={sortField === 'tags' ? sortDirection : 'none'}
          >
            <div
              className="th-content"
              role="button"
              tabIndex={0}
              onClick={() => handleSortClick('tags')}
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleSortClick('tags');
              }}
            >
              Tags {renderSortIcon('tags')}
            </div>
            {/* Resizer */}
            {/* <div
              className="resizer"
              onMouseDown={(e) => handleMouseDown(e, 'tags')}
            /> */}
          </th>
          {/* Name Column (Resizable) */}
          <th
            className="version-column"
            style={{ width: `${columnWidths.version}px` }}
            role="columnheader"
            aria-sort={sortField === 'version' ? sortDirection : 'none'}
          >
            <div
              className="th-content"
              role="button"
              tabIndex={0}
              onClick={() => handleSortClick('version')}
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleSortClick('version');
              }}
            >
              Version {renderSortIcon('version')}
            </div>
            {/* Resizer */}
            {/* <div
              className="resizer"
              onMouseDown={(e) => handleMouseDown(e, 'version')}
            /> */}
          </th>

          {/* Added By Column (Resizable) */}
          <th
            className="addedBy-column"
            style={{ width: `${columnWidths.addedBy}px` }}
            role="columnheader"
            aria-sort={sortField === 'added_by_name' ? sortDirection : 'none'}
          >
            <div
              className="th-content"
              role="button"
              tabIndex={0}
              onClick={() => handleSortClick('added_by_name')}
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleSortClick('added_by_name');
              }}
            >
              Added By {renderSortIcon('added_by_name')}
            </div>
            {/* Resizer */}
            {/* <div
              className="resizer"
              onMouseDown={(e) => handleMouseDown(e, 'addedBy')}
            /> */}
          </th>

          {/* Size Column (Resizable) */}
          <th
            className="size-column"
            style={{ width: `${columnWidths.size}px` }}
            role="columnheader"
            aria-sort={sortField === 'size' ? sortDirection : 'none'}
          >
            <div
              className="th-content"
              role="button"
              tabIndex={0}
              onClick={() => handleSortClick('size')}
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleSortClick('size');
              }}
            >
              Size {renderSortIcon('size')}
            </div>
            {/* Resizer */}
            {/* <div
              className="resizer"
              onMouseDown={(e) => handleMouseDown(e, 'size')}
            /> */}
          </th>

          {/* Status Column (Resizable) */}
          <th
            className="status-column"
            style={{ width: `${columnWidths.status}px` }}
            role="columnheader"
            aria-sort={sortField === 'status' ? sortDirection : 'none'}
          >
            <div
              className="th-content"
              role="button"
              tabIndex={0}
              onClick={() => handleSortClick('status')}
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleSortClick('status');
              }}
            >
              Status {renderSortIcon('status')}
            </div>
            {/* Resizer */}
            {/* <div
              className="resizer"
              onMouseDown={(e) => handleMouseDown(e, 'status')}
            /> */}
          </th>

          {/* Project Column (Resizable) */}
          <th
            className="project-column"
            style={{ width: `${columnWidths.project}px` }}
            role="columnheader"
            aria-sort={sortField === 'project' ? sortDirection : 'none'}
          >
            <div
              className="th-content"
              role="button"
              tabIndex={0}
              onClick={() => handleSortClick('project')}
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleSortClick('project');
              }}
            >
              Project {renderSortIcon('project')}
            </div>
            {/* Resizer */}
            {/* <div
              className="resizer"
              onMouseDown={(e) => handleMouseDown(e, 'project')}
            /> */}
          </th>

          {/* Actions Column (Fixed, Non-Resizable) */}
          <th
            className="actions-column"
            style={{ width: `${columnWidths.actions}px` }}
            role="columnheader"
          >
            Actions
          </th>

        </tr>
      </thead>
      <tbody>
        {/* Render Folders */}
        {folders.map((folder) => (
          <tr
            key={folder.id}
            className="folder-row"
            onClick={() => navigateToFolder(folder.id, folder.name)}
          >
            <td className="checkbox-column">
              <input
                type="checkbox"
                checked={selectedItems.includes(folder.id)}
                onChange={(e) => handleSelectItem(e, folder.id)}
                aria-label={`Select folder ${folder.name}`}
                onClick={(e) => e.stopPropagation()} // Prevent row click
              />
            </td>
            <td className="type-cell">
              <div className="type-icon-container">
                <FaFolder className="type-icon" title="Folder" />
              </div>
            </td>
            <td className="name-cell" style={{ width: `${columnWidths.name}px` }}>
              {folder.name}
            </td>
            <td style={{ width: `${columnWidths.tags}px` }}>

            </td>
            <td className="version-cell" style={{ width: `${columnWidths.version}px` }}>

            </td>
            <td className="addedBy-cell" style={{ width: `${columnWidths.addedBy}px` }}>
              {folder.addedBy || 'N/A'}
            </td>
            <td className="size-cell" style={{ width: `${columnWidths.size}px` }}>

            </td>
            <td className="status-cell" style={{ width: `${columnWidths.status}px` }}>

            </td>
            <td className="project-cell" style={{ width: `${columnWidths.project}px` }}>

            </td>
            <td style={{ width: `${columnWidths.actions}px` }}>
              {/* You can add folder-specific actions here if needed */}

            </td>
          </tr>
        ))}


        {/* Render Documents */}
        {filteredAndSortedDocuments.map((document) => (
          <tr key={document.document_uuid} className="document-row">
            <td className="checkbox-column">
              <input
                type="checkbox"
                checked={selectedItems.includes(document.document_uuid)}
                onChange={(e) => handleSelectItem(e, document.document_uuid)}
                aria-label={`Select document ${document.document_name}`}
                onClick={(e) => e.stopPropagation()} // Prevent row click
              />
            </td>
            <td className="type-cell">
              <div className="type-icon-container">
                {(document?.document_type?.toLowerCase() === 'pdf') ? (
                  <AiOutlineFilePdf className="type-icon" title="PDF" />
                ) : (document?.document_type?.toLowerCase() === 'docx') ? (
                  <FaFileWord className="type-icon" title="DOCX" />
                ) : (
                  <FaFileAlt className="type-icon" title="File" />
                )}
              </div>
            </td>
            <td className="name-cell" style={{ width: `${columnWidths.name}px` }}>
              <button
                className="document-name-button"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering row click if any
                  navigate(`/document/${document.document_uuid}`);
                }}
                aria-label={`View versions for ${document.document_name}`}
              >
                {document.document_name}
              </button>
            </td>
            <td
              className={`tags-cell ${editingDocId ? 'editing' : ''}`}
              style={{ width: `${columnWidths.tags}px` }}
            >
              {/* Render existing tags */}
              {Array.isArray(document.tags) && document.tags.length > 0 ? (
                document.tags.map((tag, index) => (
                  <span key={index} className="tag-badge">
                    {tag}
                    <AiOutlineClose
                      className="tag-delete-icon"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering row click
                        handleDeleteTag(document.document_uuid, tag);
                      }}
                      title={`Remove tag ${tag}`}
                    />
                  </span>
                ))
              ) : (
                // Render 'No Tags' only if not editing
                !editingDocId && <span className="no-tags">No Tags</span>
              )}

              {/* Add Tag Button */}
              <button
                className="add-tag-button"
                onClick={() => {
                  setEditingDocId(document.document_uuid);
                  setNewTag('');
                }}
                aria-label={`Add tag to ${document.document_name}`}
              >
                <AiOutlinePlus className="icon" />
              </button>

              {/* Tag Input (if editing) */}
              {editingDocId === document.document_uuid && (
                <div className="tag-input-container">
                  <input
                    type="text"
                    value={newTag}
                    onChange={(e) => setNewTag(e.target.value)}
                    placeholder="Add a tag"
                    aria-label="Add a tag"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleAddTag(document.document_uuid, newTag, userData.user_data.org_id);
                      }
                    }}
                  />
                  <AiOutlineCheck
                    className="tag-add-icon"
                    onClick={() => handleAddTag(document.document_uuid, newTag)}
                    title="Add Tag"
                  />
                  <AiOutlineClose
                    className="tag-cancel-icon"
                    onClick={() => {
                      setEditingDocId(null);
                      setNewTag('');
                    }}
                    title="Cancel"
                  />
                </div>
              )}
            </td>
            <td className="version-cell" style={{ width: `${columnWidths.version}px` }}>
              {document.version}
            </td>
            <td className="addedBy-cell" style={{ width: `${columnWidths.addedBy}px` }}>
              {document.added_by_name || 'N/A'}
            </td>
            <td className="size-cell" style={{ width: `${columnWidths.size}px` }}>
              {formatFileSize(document.size)}
            </td>
            <td className="status-cell" style={{ width: `${columnWidths.status}px` }}>
              {document.status === 'processing' ? (
                <span className="status-processing">Processing...</span>
              ) : (
                document.status
              )}
            </td>
            <td className="project-cell" style={{ width: `${columnWidths.project}px` }}>
              {editingDocId === document.document_uuid ? (
                <ProjectSelector
                  userData={userData}
                  onProjectSelect={setEditedProject}
                  currentProject={editedProject}
                />
              ) : (
                document.project_name || 'No Project'
              )}
            </td>
            <td className="actions-cell" style={{ width: `${columnWidths.actions}px` }}>
              {editingDocId === document.document_uuid ? (
                <AiOutlineCheck
                  className="save-icon"
                  onClick={() => handleSaveEdit(document)}
                  title="Save"
                />
              ) : (
                <>
                  {/* Uncomment and implement these buttons if needed */}
                  <AiOutlineEdit
                    className='document-project-edit-icon'
                    onClick={() => handleEditClick(document)}
                    title="Edit Project"
                  />
                  {document?.url !== "none" &&
                    document?.document_type?.toLowerCase() === 'pdf' && (
                      <AiOutlineEye
                        className='document-view-icon'
                        onClick={() => handleView(document.url, 'pdf')}
                        title='View Document'
                      />
                    )}
                  <AiOutlineShareAlt
                    className='document-share-icon'
                    onClick={() => handleShare(document)}
                    title='Share Document'
                  />
                  <AiOutlineDelete
                    className='document-delete-icon'
                    onClick={() => handleDeleteClick(document)}
                    title="Delete Document"
                  />
                </>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table >
  );

  /**
   * Render the main FileExplorer UI
   */
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="file-explorer">
        {/* Hidden File Input */}
        <input
          type="file"
          ref={fileInputRef}
          multiple
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />

        {/* Header Section */}
        <div className="file-explorer-header">
          <div className="header-text">Files</div>
          <div className="file-search-bar">
            <AiOutlineSearch className="search-icon" />
            <input
              type="text"
              placeholder="Search documents..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              aria-label="Search documents"
            />
          </div>
          <div className="add-button-container">
            <button
              className={`file-explorer-add-button ${isAddDropdownOpen ? 'expanded' : ''}`}
              onClick={toggleAddDropdown}
              aria-haspopup="true"
              aria-expanded={isAddDropdownOpen}
              aria-label="Add"
            >
              <AiOutlinePlus className="button-folder-icon" />
              <span>Add</span>
            </button>
            <div className={`add-dropdown ${isAddDropdownOpen ? 'show' : ''}`}>
              <div className="dropdown-content">
                <button
                  onClick={() => {
                    setIsNewFolderModalOpen(true);
                    toggleAddDropdown();
                  }}
                >
                  <FaFolder /> Folder
                </button>
                <button
                  onClick={() => {
                    triggerFileInput(); // Trigger file picker
                    toggleAddDropdown();
                  }}
                >
                  <AiOutlineFile /> File
                </button>
                {/* <button
                  onClick={() => {
                    setIsOneDriveModalOpen(true);
                    toggleAddDropdown();
                  }}
                >
                  OneDrive
                </button>
                <button onClick={() => {
                  setIsGoogleDriveModalOpen(true);
                  toggleAddDropdown();
                }}>Google Drive
                </button>
                <button onClick={() => {toggleAddDropdown();}}>
                  <AiOutlineFileZip /> Zip
                </button> */}
              </div>
            </div>
          </div>
        </div>

        {/* Breadcrumbs Navigation */}
        {renderBreadcrumbs()}

        {/* Back Button (if not in Home) */}
        {breadcrumbs.length > 1 && (
          <div className="top-row-buttons">
            <button className="back-button" onClick={handleBackClick}>
              <AiOutlineArrowLeft /> Back
            </button>
          </div>
        )}

        {selectedItems.length > 0 && (
          <div className="bulk-actions">
            <button onClick={() => {/* Bulk Edit Logic */ }}>Bulk Edit</button>
            <button onClick={() => {/* Bulk Delete Logic */ }}>Delete</button>
            <button onClick={() => setIsMoveModalOpen(true)}>Move</button>
          </div>
        )}

        {/* Upload Status Overlay */}
        {isUploading && (
          <div className="upload-overlay" role="alert" aria-live="assertive">
            <LoadingSpinner />
            <span>Uploading files...</span>
          </div>
        )}

        {uploadError && (
          <div className="upload-error" role="alert">
            <p>{uploadError}</p>
          </div>
        )}

        {/* Main Area with Table */}
        <div
          className="file-main-area"
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => handleFileDrop(e)}
        >
          {isLoading ? (
            <div className="loading-spinner-container">
              <LoadingSpinner />
            </div>
          ) : (
            <div className="table-container">{renderTable()}</div>
          )}
        </div>

        {/* Document Viewer Modal */}
        {viewerUrl && (
          <div className="fullscreen-viewer">
            <div className="viewer-header">
              <button
                className="close-button"
                onClick={() => {
                  setViewerUrl(null);
                  setViewerType(null);
                }}
                aria-label="Close Viewer"
              >
                <AiOutlineClose />
              </button>
            </div>
            <div className="viewer-content">{renderViewer()}</div>
          </div>
        )}

        {/* Share Popup */}
        {sharingDocument && (
          <SharePopup
            isOpen={isSharePopupOpen}
            onClose={() => {
              setIsSharePopupOpen(false);
              setSharingDocument(null);
            }}
            documentToShare={sharingDocument}
            onShare={handleShareComplete}
            userData={userData}
          />
        )}

        {/* New Folder Modal */}
        {isNewFolderModalOpen && renderNewFolderModal()}

        {/* Confirmation Modal for Deletion */}
        {isDeleteModalOpen && (
          <ConfirmationModal
            message={`Are you sure you want to delete "${documentToDelete?.document_name || 'this document'}"? This action cannot be undone.`}
            onConfirm={handleDeleteConfirm}
            onCancel={handleDeleteCancel}
          />
        )}

        {/* Version Conflict Modal */}
        <VersionConflictModal
          isOpen={isVersionConflictModalOpen}
          onClose={handleCancelUpload}
          onAddNewVersion={handleAddNewVersion}
          onReplaceVersion={handleReplaceVersion}
        />

        {/* OneDrive Modal */}
        <OneDriveModal
          isOpen={isOneDriveModalOpen}
          onClose={onCloseOneDriveModal}
          onSelect={handleOneDriveSelect}
        />

        {/* Google Drive Modal */}
        <GoogleDriveModal
          isOpen={isGoogleDriveModalOpen}
          onClose={() => setIsGoogleDriveModalOpen(false)}
          onSelect={handleGoogleDriveSelect}
        />
      </div>

      {isMoveModalOpen && (
        <MoveModal
          isOpen={isMoveModalOpen}
          onClose={() => setIsMoveModalOpen(false)}
          folders={allFolders}
          onMove={async (destinationFolderId) => {
            await handleMoveDocuments(destinationFolderId);
            setIsMoveModalOpen(false);
          }}
        />
      )}
    </DndProvider>
  );

  /**
   * Minimum sorting logic for the current code
   * Define handleSort inside the component or import from elsewhere
   */
};

export default FileExplorer;
