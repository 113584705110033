// src/pages/admin.js

import React, { useState, useEffect, useCallback } from 'react';
import './admin.css';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { UserPlus, Users, FileText, ChevronRight } from 'lucide-react';
import { useNotification } from '../components/useNotification';
import LoadingSpinner from '../components/assets/loadingSpinner';
import UserCreationModal from '../components/userCreationModal';
import ManageUserGroupsModal from '../components/manageUserGroupsModal';
import { getIdToken } from '../services/userDataService';

const AdminPage = ({ userData }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [orgData, setOrgData] = useState({
    org_name: '',
    address: '',
    user_count: 0,
    available_licenses: 0,
    document_count: 0,
    users: [],
  });
  const [userGroups, setUserGroups] = useState([]);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
  const { addNotification } = useNotification();

  // Fetch organization users and statistics
  const fetchUsers = useCallback(async () => {
    console.log('Fetching users for Admin Dashboard');
    const token = await getIdToken();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/admin-dashboard?org_id=${userData.user_data.org_id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error('Failed to fetch users');
      }
      const usersData = await response.json();
      setOrgData(usersData);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      addNotification('Failed to fetch users', 'error');
      setIsLoading(false);
    }
  }, [userData.user_data.org_id, addNotification]);

  // Fetch user groups
  const fetchUserGroups = useCallback(async () => {
    console.log('Fetching user groups');
    const token = await getIdToken();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/fetchUserGroups?org_id=${userData.user_data.org_id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error('Failed to fetch user groups');
      }
      const groupsData = await response.json();
      setUserGroups(groupsData);
    } catch (error) {
      console.error('Error fetching user groups:', error);
      addNotification('Failed to fetch user groups', 'error');
    }
  }, [userData.user_data.org_id, addNotification]);

  useEffect(() => {
    fetchUsers();
    fetchUserGroups();
  }, [fetchUsers, fetchUserGroups]);

  // Handlers for adding users and groups
  const handleAddUserToGroup = useCallback(
    async (groupId, userId) => {
      const token = await getIdToken();
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/add-user-to-group`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            org_id: userData.user_data.org_id,
            group_id: groupId,
            user_id: userId,
            admin_user_id: userData.user_data.user_id,
          }),
        });

        const data = await response.json();

        if (response.ok) {
          addNotification('User added to group successfully', 'success');
          fetchUserGroups();
        } else {
          addNotification(`Failed to add user to group: ${data.error || 'Unknown error'}`, 'error');
        }
      } catch (error) {
        console.error('Error adding user to group:', error);
        addNotification('Error adding user to group: ' + error.message, 'error');
      }
    },
    [userData.user_data.org_id, userData.user_data.user_id, addNotification, fetchUserGroups]
  );

  const handleAddGroup = useCallback(
    async (name, description) => {
      const token = await getIdToken();
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/add-user-group`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            org_id: userData.user_data.org_id,
            name,
            description,
            admin_user_id: userData.user_data.user_id,
          }),
        });

        const data = await response.json();

        if (response.ok) {
          addNotification('User group added successfully', 'success');
          fetchUserGroups();
        } else {
          addNotification(`Failed to add user group: ${data.error || 'Unknown error'}`, 'error');
        }
      } catch (error) {
        console.error('Error adding user group:', error);
        addNotification('Error adding user group: ' + error.message, 'error');
      }
    },
    [userData.user_data.org_id, userData.user_data.user_id, addNotification, fetchUserGroups]
  );

  const handleAddUser = useCallback(
    async (newUser) => {
      const token = await getIdToken();
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/userProvisioner`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            org_id: userData.user_data.org_id,
            email: newUser.email,
            name: newUser.name,
            username: newUser.username,
            user_group: newUser.userGroup,
            admin_user_id: userData.user_data.user_id,
          }),
        });

        const data = await response.json();

        if (response.ok) {
          addNotification('User added successfully', 'success');
          fetchUsers();
        } else {
          addNotification(`Failed to Add User: ${data.error || 'Unknown error'}`, 'error');
        }
      } catch (error) {
        console.error('Error adding user:', error);
        addNotification('Error adding user: ' + error.message, 'error');
      }
    },
    [userData.user_data.org_id, userData.user_data.user_id, addNotification, fetchUsers]
  );

  return (
    <div className="admin-page">
      <div className="admin-content">
        {isLoading ? (
          <div className="loading-spinner-container">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <div className="admin-header">
              <div className="admin-title-section">
                <h1 className="admin-title">Administrator Dashboard</h1>
                <div className="admin-org-info">
                  <h2>{orgData.org_name}</h2>
                  <p>{orgData.address}</p>
                </div>
              </div>
              <div className="admin-metrics">
                <div className="metric-card">
                  <UserPlus size={24} />
                  <span className="metric-value">{orgData.user_count}</span>
                  <span className="metric-label">Named Users</span>
                </div>
                <div className="metric-card">
                  <Users size={24} />
                  <span className="metric-value">{orgData.available_licenses}</span>
                  <span className="metric-label">Available Seats</span>
                </div>
                <div className="metric-card">
                  <FileText size={24} />
                  <span className="metric-value">{orgData.document_count}</span>
                  <span className="metric-label">Uploaded Files</span>
                </div>
              </div>
            </div>

            <div className="admin-section">
              <div className="section-header">
                <h2 className="section-title">User Management</h2>
                <div className="admin-actions">
                  <button onClick={() => setIsUserModalOpen(true)} className="action-button">
                    <UserPlus size={18} />
                    Add User
                  </button>
                  <button onClick={() => setIsGroupModalOpen(true)} className="action-button">
                    <Users size={18} />
                    Manage Groups
                  </button>
                </div>
              </div>
              <div className="admin-table">
                <div className="table-header">
                  <div>Name</div>
                  <div>Email</div>
                  <div>Roles</div>
                  <div>Status</div>
                </div>
                <div className="table-body">
                  {orgData.users.map((user) => (
                    <div className="table-row" key={user.user_id}>
                      <div>{user.name}</div>
                      <div>{user.email}</div>
                      <div>{user.groups}</div>
                      <div>
                        <span className="status-badge active">Active</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {/* Modals */}
      <UserCreationModal
        isOpen={isUserModalOpen}
        onClose={() => setIsUserModalOpen(false)}
        onSubmit={handleAddUser}
        userGroups={userGroups}
      />
      <ManageUserGroupsModal
        isOpen={isGroupModalOpen}
        onClose={() => setIsGroupModalOpen(false)}
        userGroups={userGroups}
        allUsers={orgData.users}
        onAddGroup={handleAddGroup}
        onFetchGroups={fetchUserGroups}
        onAddUserToGroup={handleAddUserToGroup}
      />
    </div>
  );
};

export default AdminPage;