import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import DataGrid from 'react-data-grid';

const XLSXViewer = ({ fileUrl }) => {
  const [gridColumns, setGridColumns] = useState([]);
  const [gridRows, setGridRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAndParseXLSX = async () => {
      try {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        const reader = new FileReader();

        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });

          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

          const columns = jsonData[0].map((col, index) => ({
            key: index,
            name: col || `Column ${index + 1}`,
            resizable: true,
            sortable: true,
          }));

          const rows = jsonData.slice(1).map((row, index) => {
            const rowData = {};
            columns.forEach((col, colIndex) => {
              rowData[col.key] = row[colIndex];
            });
            return { id: index, ...rowData };
          });

          setGridColumns(columns);
          setGridRows(rows);
          setIsLoading(false);
        };

        reader.readAsArrayBuffer(blob);
      } catch (error) {
        console.error('Error parsing XLSX file:', error);
        setIsLoading(false);
      }
    };

    fetchAndParseXLSX();
  }, [fileUrl]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <DataGrid
        columns={gridColumns}
        rows={gridRows}
        style={{ height: '100%' }}
      />
    </div>
  );
};

export default XLSXViewer;