import { getIdToken } from '../services/userDataService';

export const fetchSharedChats = async (userData) => {
  try {
    console.log('userData', userData);
    const token = await getIdToken();
    const response = await fetch(process.env.REACT_APP_GET_SHARED_CHATS, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
      body: JSON.stringify({
        userId: userData.user_data.user_id,
        orgId: userData.user_data.org_id
      })
    });
    const data = await response.json();
    console.log('data', data);
    return data.shared_sessions || [];
  } catch (error) {
    console.error('Error fetching shared chats:', error);
    return [];
  }
};