// At the top of your Chat.js file or in a separate TypingIndicator.js file
import React from 'react';
import './typingIndicator.css'; // Assume you have a separate CSS file for the typing indicator styles

const TypingIndicator = () => (
  <div className="typingIndicator">
    <span></span>
    <span></span>
    <span></span>
  </div>
);

export default TypingIndicator;