import React, { useState, useEffect } from 'react'
import { AiOutlineEdit, AiOutlineDelete, AiOutlineShareAlt } from 'react-icons/ai'
import { FaTimes, FaSearch } from 'react-icons/fa'
import LoadingSpinner from '../assets/loadingSpinner'
import { useDeleteData } from '../../functions/deleteUserData'
import ShareSessionModal from '../shareSessionModal'
import { useNotification } from '../useNotification'
import { getIdToken } from '../../services/userDataService'
import { fetchSharedChats } from '../../functions/fetchSharedChats'

// shadcn/ui dialog imports (adjust to your structure)
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogDescription,
    DialogFooter,
    DialogClose,
    DialogPortal
} from '../ui/dialog'

import './historyModal.css' // If you have leftover styles you want to keep

// Helper function to categorize sessions
const categorizeSessions = (sessions) => {
    if (!sessions) return { today_list: [], yesterday: [], thisWeek: [], byMonthAndYear: {} }

    const today_list = []
    const yesterday = []
    const thisWeek = []
    const byMonthAndYear = {}

    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const oneDay = 24 * 60 * 60 * 1000
    const twoDay = 2 * oneDay
    const startOfWeek = new Date(today)
    startOfWeek.setDate(today.getDate() - today.getDay())

    sessions.forEach((session) => {
        const sessionDate = new Date(session.timestamp)
        const sessionDay = new Date(
            sessionDate.getFullYear(),
            sessionDate.getMonth(),
            sessionDate.getDate()
        )
        const timeDifference = today - sessionDay

        if (timeDifference <= oneDay) {
            today_list.push(session)
        } else if (timeDifference <= twoDay) {
            yesterday.push(session)
        } else if (sessionDay >= startOfWeek) {
            thisWeek.push(session)
        } else {
            const monthYear = `${sessionDate.toLocaleString('default', { month: 'long' })} ${sessionDate.getFullYear()}`
            if (!byMonthAndYear[monthYear]) {
                byMonthAndYear[monthYear] = []
            }
            byMonthAndYear[monthYear].push(session)
        }
    })

    return { today_list, yesterday, thisWeek, byMonthAndYear }
}

export default function HistoryModal({
    isOpen,
    onClose,
    userSessions,
    handleCardClick,
    isHistoryLoading,
    onRename,
    onLoadMore,
    hasMore,
    userData,
}) {
    const [editingSessionId, setEditingSessionId] = useState(null)
    const [newTitle, setNewTitle] = useState('')
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [sessionToDelete, setSessionToDelete] = useState(null)
    const [isShareModalOpen, setIsShareModalOpen] = useState(false)
    const [sessionToShare, setSessionToShare] = useState({ sessionId: null, title: '' })
    const [showSharedChats, setShowSharedChats] = useState(false)
    const [sharedChats, setSharedChats] = useState([])
    const [isLoadingShared, setIsLoadingShared] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')

    const { deleteData, isDeleting } = useDeleteData()
    const { addNotification } = useNotification()

    const { today_list, yesterday, thisWeek, byMonthAndYear } = categorizeSessions(userSessions)

    // ===========================
    // SHARING & FETCHING LOGIC
    // ===========================
    async function handleFetchSharedChats() {
        setIsLoadingShared(true)
        const fetchedSharedChats = await fetchSharedChats(userData)
        setSharedChats(fetchedSharedChats)
        setIsLoadingShared(false)
    }

    function handleSharedWithMeClick() {
        setShowSharedChats(!showSharedChats)
        if (!showSharedChats) {
            handleFetchSharedChats()
        }
    }

    async function handleShare(sessionId, sessionTitle, sharedWithUserId, sharedWithName, expirationDays) {
        try {
            const token = await getIdToken()
            await fetch(process.env.REACT_APP_SHARE_CHAT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${token}`,
                },
                body: JSON.stringify({
                    sessionId,
                    sharedWithUserId,
                    sessionTitle,
                    expirationDays,
                    sharedWithName,
                    userId: userData.user_data.user_id,
                    orgId: userData.user_data.org_id,
                }),
            })
            addNotification('Session shared successfully', 'success')
        } catch (error) {
            addNotification('Failed to share session', 'error')
        }
    }

    // ===========================
    // DELETION LOGIC
    // ===========================
    function handleDeleteClick(session_id) {
        setSessionToDelete(session_id)
        setIsDeleteModalOpen(true)
    }

    async function handleDeleteConfirm() {
        if (sessionToDelete) {
            const success = await deleteData(
                userData.user_data.user_id,
                userData.user_data.org_id,
                'chat',
                sessionToDelete
            )
            if (success) {
                setIsDeleteModalOpen(false)
                setSessionToDelete(null)
                onLoadMore() // Refresh the history
            }
        }
    }

    // ===========================
    // RENAME LOGIC
    // ===========================
    function handleRenameClick(sessionId, currentTitle) {
        setEditingSessionId(sessionId)
        setNewTitle(currentTitle)
    }

    function handleRenameSubmit(sessionId) {
        if (newTitle.trim() !== '') {
            onRename(sessionId, newTitle.trim())
            setEditingSessionId(null)
            setNewTitle('')
        }
    }

    // ===========================
    // FILTERING LOGIC
    // ===========================
    function filterSessions(sessions) {
        if (!searchQuery) return sessions
        return sessions.filter((session) =>
            session.title?.toLowerCase().includes(searchQuery.toLowerCase())
        )
    }

    function renderSessions(sessions) {
        const filteredSessions = filterSessions(sessions);
        return filteredSessions.map((session) => {
            const isEditing = editingSessionId === session.sessionId;
            const sessionTitle = session.title || 'Untitled Chat';
            const sessionTimestamp = new Date(session.timestamp).toLocaleString();

            return (
                <div
                    key={session.sessionId}
                    className="mb-2 p-3 pl-8 pr-8 bg-white border border-gray-200 rounded-xl shadow-lg cursor-pointer hover:shadow-xl transition duration-200" 
                /* or any other tailwind classes you like */
                >
                    {isEditing ? (
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleRenameSubmit(session.sessionId);
                            }}
                        >
                            <input
                                className="rename-input border p-1 rounded"
                                type="text"
                                value={newTitle}
                                onChange={(e) => setNewTitle(e.target.value)}
                                autoFocus
                            />
                            <button
                                type="submit"
                                className="submit-change-name ml-2 bg-blue-500 text-white px-2 py-1 rounded"
                            >
                                Save
                            </button>
                        </form>
                    ) : (
                        <div
                            className="flex items-center justify-between"
                            onClick={() => {
                                handleCardClick(session.sessionId);
                                onClose();
                            }}
                        >
                            {/* Left side: Title + Created date */}
                            <div className="flex flex-col ">
                                <div className="font-medium text-gray-800 overflow-ellipsis">
                                    {sessionTitle}
                                </div>
                                <div className="text-sm text-gray-500">
                                    Created at: {sessionTimestamp}
                                </div>
                            </div>

                            {/* Right side: Icons */}
                            <div className="flex space-x-4 text-gray-500">
                                <AiOutlineEdit
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleRenameClick(session.sessionId, sessionTitle);
                                    }}
                                    className="cursor-pointer hover:text-gray-700"
                                />
                                <AiOutlineDelete
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteClick(session.sessionId);
                                    }}
                                    className="cursor-pointer hover:text-gray-700"
                                />
                                <AiOutlineShareAlt
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setSessionToShare(session);
                                        setIsShareModalOpen(true);
                                    }}
                                    className="cursor-pointer hover:text-gray-700"
                                />
                            </div>
                        </div>
                    )}
                </div>
            );
        });
    }


    function renderSharedSessions(sessions) {
        return sessions.map((session) => (
            <li key={session.sharing_link} className="history-item">
                <div className="session-content" onClick={() => handleCardClick(session.original_session_id)}>
                    <div className="session-id">
                        {session.session_title || 'Untitled Shared Chat'}
                        <AiOutlineDelete
                            onClick={(e) => {
                                e.stopPropagation()
                                // TODO: delete shared chat
                            }}
                        />
                    </div>
                    <div className="shared-by">Shared by: {session.shared_with_name}</div>
                    <div className="expires-at">
                        Expires: {new Date(session.expires_at * 1000).toLocaleString()}
                    </div>
                </div>
            </li>
        ))
    }

    // ===========================
    // DIALOG RENDER
    // ===========================

    return (
        <Dialog
            open={isOpen}
            onOpenChange={(open) => {
                // If the Dialog tries to close (overlay click, ESC, etc.), call onClose
                if (!open) onClose()
            }}
        >
            <DialogContent className="max-w-3xl rounded-3xl">
                <DialogHeader>
                    <DialogTitle>{showSharedChats ? 'Shared Chats' : 'Chat History'}</DialogTitle>
                    <DialogDescription>
                        View or manage your chats, or see chats shared with you.
                    </DialogDescription>
                </DialogHeader>

                {/* Close button in the top-right corner */}
                <DialogClose asChild>
                    <button
                        className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
                        aria-label="Close"
                    >
                        <FaTimes />
                    </button>
                </DialogClose>

                {/* Shared & Search actions */}
                <div className="flex items-center space-x-4 my-4">
                    <button onClick={handleSharedWithMeClick} className="px-3 py-1 bg-transparent border-2 border-gray-700 rounded-xl">
                        {showSharedChats ? 'My Chats' : 'Shared With Me'}
                    </button>
                    <div className="flex items-center relative">
                        <FaSearch className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
                        <input
                            type="text"
                            placeholder="Search chats..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="pl-8 pr-2 py-1 border border-gray-500 rounded-xl w-96"
                        />
                    </div>
                </div>

                {/* Main content area for user sessions or shared sessions */}
                <div className="history-list max-h-[50vh] overflow-y-auto p-2 rounded">
                    {showSharedChats ? (
                        isLoadingShared ? (
                            <LoadingSpinner />
                        ) : sharedChats.length === 0 ? (
                            <div className="no-shared-chats">No chats shared with you yet.</div>
                        ) : (
                            <ul>{renderSharedSessions(sharedChats)}</ul>
                        )
                    ) : isHistoryLoading ? (
                        <LoadingSpinner />
                    ) : (
                        <>
                            <ul>
                                {today_list.length > 0 && (
                                    <>
                                        <h4 className="font-semibold mt-2">Today</h4>
                                        {renderSessions(today_list)}
                                    </>
                                )}
                                {yesterday.length > 0 && (
                                    <>
                                        <h4 className="font-semibold mt-2">Yesterday</h4>
                                        {renderSessions(yesterday)}
                                    </>
                                )}
                                {thisWeek.length > 0 && (
                                    <>
                                        <h4 className="font-semibold mt-2">This Week</h4>
                                        {renderSessions(thisWeek)}
                                    </>
                                )}
                                {Object.keys(byMonthAndYear).map((monthYear) => (
                                    <React.Fragment key={monthYear}>
                                        <h4 className="font-semibold mt-2">{monthYear}</h4>
                                        {renderSessions(byMonthAndYear[monthYear])}
                                    </React.Fragment>
                                ))}
                            </ul>
                            {hasMore && (
                                <button onClick={onLoadMore} className="load-more-button mt-2 bg-gray-200 px-3 py-1 rounded">
                                    Load More
                                </button>
                            )}
                        </>
                    )}
                </div>

                {/* Inline Delete Confirmation (could also be a nested <Dialog> if you prefer) */}
                {isDeleteModalOpen && (
                    <div className="delete-modal border rounded p-4 my-4 bg-gray-50">
                        <h3 className="font-semibold mb-2">Confirm Deletion</h3>
                        <p className="mb-4">Are you sure you want to delete? This action cannot be undone.</p>
                        <div className="delete-modal-actions flex space-x-2">
                            <button
                                onClick={handleDeleteConfirm}
                                className="px-4 py-2 bg-red-600 text-white rounded"
                            >
                                Yes, Delete
                            </button>
                            <button
                                onClick={() => setIsDeleteModalOpen(false)}
                                className="px-4 py-2 bg-gray-200 rounded"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                )}

                {/* If you want a Footer area for general actions, you could wrap the 
            delete modal inside <DialogFooter>. We'll keep it inline for now. */}

                {/* We still use your existing ShareSessionModal for the share flow */}
                <ShareSessionModal
                    isOpen={isShareModalOpen}
                    onClose={() => setIsShareModalOpen(false)}
                    sessionId={sessionToShare.sessionId}
                    onShare={handleShare}
                    orgId={userData.user_data.org_id}
                    sessionTitle={sessionToShare.title}
                    userData={userData}
                />
            </DialogContent>
        </Dialog>
    )
}
