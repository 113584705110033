import React, { useState, useEffect } from 'react';
import { X, ChevronDown, ChevronUp, UserPlus } from 'lucide-react';
import './manageUserGroupsModal.css';
import { AiOutlinePlus } from 'react-icons/ai';

const ManageUserGroupsModal = ({ isOpen, onClose, userGroups, allUsers, onAddGroup, onFetchGroups, onAddUserToGroup }) => {
    const [expandedGroups, setExpandedGroups] = useState({});
    const [newGroupName, setNewGroupName] = useState('');
    const [newGroupDescription, setNewGroupDescription] = useState('');
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedUser, setSelectedUser] = useState('');

    useEffect(() => {
        if (isOpen) {
            onFetchGroups();
        }
    }, [isOpen, onFetchGroups]);

    if (!isOpen) return null;

    const toggleGroup = (groupId) => {
        setExpandedGroups(prev => ({ ...prev, [groupId]: !prev[groupId] }));
    };

    const handleAddGroup = () => {
        if (newGroupName.trim()) {
            onAddGroup(newGroupName.trim(), newGroupDescription.trim());
            onFetchGroups();
            setNewGroupName('');
            setNewGroupDescription('');
        }
    };

    const handleAddUserToGroup = () => {
        if (selectedGroup && selectedUser) {
            onAddUserToGroup(selectedGroup, selectedUser);
            setSelectedUser('');
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content manage-groups-modal">
                <div className="modal-header">
                    <div className="user-groups-modal-title">Manage User Groups</div>
                    <button onClick={onClose} className="modal-close-button">
                        <X size={24} />
                    </button>
                </div>
                <div className="user-groups-list">
                    {userGroups.map(group => (
                        <div key={group.group_id} className="user-group">
                            <div className="group-header" onClick={() => toggleGroup(group.group_id)}>
                                <div className='user-group-name'>{group.group_name}</div>
                                {expandedGroups[group.group_id] ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                            </div>
                            {expandedGroups[group.group_id] && (
                                <div className="group-users">
                                    <p>Description: {group.group_description}</p>
                                    <h4>Users:</h4>
                                    {group.users.length > 0 ? (
                                        group.users.map(user => (
                                            <div key={user.user_id} className="group-user">{user.name} ({user.email})</div>
                                        ))
                                    ) : (
                                        <p>No users in this group</p>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <div className="add-user-to-group">
                    <select
                        value={selectedGroup}
                        onChange={(e) => setSelectedGroup(e.target.value)}
                        className="modal-select"
                    >
                        <option value="">Select a group</option>
                        {userGroups.map(group => (
                            <option key={group.group_id} value={group.group_id}>{group.group_name}</option>
                        ))}
                    </select>
                    <select
                        value={selectedUser}
                        onChange={(e) => setSelectedUser(e.target.value)}
                        className="modal-select"
                    >
                        <option value="">Select a user</option>
                        {allUsers.map(user => (
                            <option key={user.user_id} value={user.user_id}>{user.name} ({user.email})</option>
                        ))}
                    </select>
                    <button onClick={handleAddUserToGroup} className="modal-submit-button">
                        <UserPlus size={20} />
                        Add User to Group
                    </button>
                </div>
                <div className="add-group-form">
                    <input
                        type="text"
                        value={newGroupName}
                        onChange={(e) => setNewGroupName(e.target.value)}
                        placeholder="New group name"
                        className="modal-input"
                    />
                    <input
                        type="text"
                        value={newGroupDescription}
                        onChange={(e) => setNewGroupDescription(e.target.value)}
                        placeholder="Group description"
                        className="modal-input"
                    />
                    <button onClick={handleAddGroup} className="modal-submit-button">
                        <AiOutlinePlus size={20} />
                        Add New User Group
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ManageUserGroupsModal;