// src/modules/reportBuilder/dashboard.js
'use client';

import React, { useState, useContext, useEffect } from 'react';
import { Input } from '../../components/ui/input.tsx';
import { Button } from '../../components/ui/button';
import { Card } from '../../components/ui/card';
import axios from 'axios';
import { IoMdInformationCircle, IoMdCalendar, IoMdCloudUpload } from 'react-icons/io';
import { getIdToken } from '../../services/userDataService';
import { ReportContext } from '../../context/ReportContext'; // Import ReportContext
import { useNotification } from '../../components/useNotification'; // Ensure this hook is available

const ReportBuilderDashboard = ({ userData }) => {
    const [columnMappings, setColumnMappings] = useState({
        instructor: 'Instructor Name',
        class: 'Class Name',
        time: 'Class Time',
        client: 'Client Name',
        rating: 'Rating',
        feedback: 'Client Feedback',
    });

    const [dateRange, setDateRange] = useState({
        start: '',
        end: '',
    });

    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { reportUrl, setReportUrl } = useContext(ReportContext); // Access reportUrl from context

    const [showTooltip, setShowTooltip] = useState(false);
    const { addNotification } = useNotification(); // Access the notification system

    const handleInputChange = (field, value, type) => {
        if (type === 'column') {
            setColumnMappings({ ...columnMappings, [field]: value });
        } else {
            setDateRange({ ...dateRange, [field]: value });
        }
    };

    const handleFileChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedFile(e.target.files[0]);
        }
    };

    const handleSubmit = async () => {
        if (!selectedFile) {
            setError('Please select a file before generating the report.');
            return;
        }
        setIsLoading(true);
        setError(null);
        const token = await getIdToken();

        try {
            // 1. Get a presigned URL from your generatePresignedURLService
            const fileName = selectedFile.name;
            const contentType = selectedFile.type || 'application/octet-stream'; // fallback if missing
            const orgId = userData.user_data.org_id;
            const version = '1';

            const presignedRes = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/getPresignedURL`,
                {
                    orgId,
                    fileName,
                    contentType,
                    version,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const { presignedUrl, bucket, key } = presignedRes.data;

            // 2. Upload the file to S3 using the presigned URL
            await axios.put(presignedUrl, selectedFile, {
                headers: {
                    'Content-Type': contentType,
                },
            });

            // 3. After uploading to S3, call the report-builder API with S3 reference and metadata
            await axios.post(
                `${process.env.REACT_APP_BASE_URL}/reportBuilder/start`,
                {
                    bucket,
                    key,
                    columnMappings,
                    user_id: userData.user_data.user_id,
                    dateRange,
                }
            );

            // Optionally, notify the user that the report generation has started
            addNotification('Report generation started. You will be notified once it\'s ready.', 'info');

        } catch (err) {
            console.error(err);
            setError('Failed to process feedback. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="h-full w-full ">
            <div className="mx-auto h-full p-6">
                <h2 className="text-3xl mb-4 text-gray-800">
                    Instructor Feedback Report Builder
                </h2>

                <div className="h-[93%] flex">
                    {/* Column Mappings */}
                    <div className='h-full mr-5 w-1/4'>
                        <Card className="p-6 mb-6 shadow-md bg-white h-full rounded-2xl border border-solid border-gray-200">
                            <div className="flex items-center mb-4">
                                <h3 className="text-xl font-semibold text-gray-700 mr-2 mt-2">Column Mappings</h3>
                                <div
                                    className="relative inline-block text-gray-400 cursor-pointer"
                                    onMouseEnter={() => setShowTooltip(true)}
                                    onMouseLeave={() => setShowTooltip(false)}
                                >
                                    <IoMdInformationCircle className="h-5 w-5" />
                                    {showTooltip && (
                                        <div className="absolute z-10 top-7 left-0 bg-white border border-gray-300 rounded p-2 text-sm shadow-md w-56">
                                            Define which Excel columns correspond to each field.
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="grid grid-cols-1 sm:grid-cols-1 gap-6 mb-4">
                                <div>
                                    <label className="block mb-1 font-medium text-gray-700">Instructor Column</label>
                                    <Input
                                        value={columnMappings.instructor}
                                        onChange={(e) => handleInputChange('instructor', e.target.value, 'column')}
                                        className='rounded-xl border border-solid border-gray-300'
                                    />
                                </div>
                                <div>
                                    <label className="block mb-1 font-medium text-gray-700">Class Column</label>
                                    <Input
                                        value={columnMappings.class}
                                        onChange={(e) => handleInputChange('class', e.target.value, 'column')}
                                        className='rounded-xl border border-solid border-gray-300'
                                    />
                                </div>
                                <div>
                                    <label className="block mb-1 font-medium text-gray-700">Time Column</label>
                                    <Input
                                        value={columnMappings.time}
                                        onChange={(e) => handleInputChange('time', e.target.value, 'column')}
                                        className='rounded-xl border border-solid border-gray-300'
                                    />
                                </div>
                                <div>
                                    <label className="block mb-1 font-medium text-gray-700">Client Column</label>
                                    <Input
                                        value={columnMappings.client}
                                        onChange={(e) => handleInputChange('client', e.target.value, 'column')}
                                        className='rounded-xl border border-solid border-gray-300'
                                    />
                                </div>
                                <div>
                                    <label className="block mb-1 font-medium text-gray-700">Rating Column</label>
                                    <Input
                                        value={columnMappings.rating}
                                        onChange={(e) => handleInputChange('rating', e.target.value, 'column')}
                                        className='rounded-xl border border-solid border-gray-300'
                                    />
                                </div>
                                <div>
                                    <label className="block mb-1 font-medium text-gray-700">Feedback Column</label>
                                    <Input
                                        value={columnMappings.feedback}
                                        onChange={(e) => handleInputChange('feedback', e.target.value, 'column')}
                                        className='rounded-xl border border-solid border-gray-300'
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className='flex flex-col w-3/4'>
                        {/* Date Range */}
                        <Card className="p-4 mb-6 h-1/2 shadow-md bg-white rounded-2xl border border-solid border-gray-200">
                            <h3 className="text-xl font-semibold mb-4 text-gray-700 mt-2">Date Range (Optional)</h3>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                <div>
                                    <label className="block mb-1 font-medium text-gray-700">Start Date</label>
                                    <div className="relative">
                                        <Input
                                            type="date"
                                            value={dateRange.start}
                                            onChange={(e) => handleInputChange('start', e.target.value, 'date')}
                                            className='rounded-xl border border-solid border-gray-300'
                                        />
                                        <IoMdCalendar className="h-5 w-5 text-gray-400 absolute right-2 top-2.5 pointer-events-none" />
                                    </div>
                                </div>
                                <div>
                                    <label className="block mb-1 font-medium text-gray-700">End Date</label>
                                    <div className="relative">
                                        <Input
                                            type="date"
                                            value={dateRange.end}
                                            onChange={(e) => handleInputChange('end', e.target.value, 'date')}
                                            className='rounded-xl border border-solid border-gray-300'
                                        />
                                        <IoMdCalendar className="h-5 w-5 text-gray-400 absolute right-2 top-2.5 pointer-events-none" />
                                    </div>
                                </div>
                            </div>
                        </Card>

                        {/* File Upload */}
                        <Card className="p-6 shadow-md bg-white h-1/2 rounded-2xl border border-solid border-gray-200">
                            <h3 className="text-xl font-semibold mb-4 text-gray-700 mt-2">Upload Feedback Data</h3>
                            <p className="text-gray-600 mb-2 text-sm">Upload your Excel file containing the feedback data.</p>
                            <div className="relative">
                                <Input
                                    type="file"
                                    accept=".xlsx, .xls"
                                    onChange={handleFileChange}
                                    className='rounded-xl border border-solid border-gray-300'
                                />
                                <IoMdCloudUpload className="h-5 w-5 text-gray-400 absolute right-2 top-2.5 pointer-events-none" />
                            </div>
                            {selectedFile && (
                                <p className="mt-2 text-green-700 text-sm">File selected: {selectedFile.name}</p>
                            )}

                            {/* Submit Button & Error */}
                            <div className="flex items-center space-x-4 mb-4 mt-14">
                                <Button
                                    onClick={handleSubmit}
                                    disabled={isLoading}
                                    className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded transition-colors"
                                >
                                    {isLoading ? 'Processing...' : 'Generate Report'}
                                </Button>
                                {error && <p className="text-red-600 text-sm">{error}</p>}
                                {reportUrl && (
                                    <Button
                                        onClick={() => window.open(reportUrl, '_blank')}
                                        className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
                                    >
                                        Download Report
                                    </Button>
                                )}
                            </div>
                        </Card>
                    </div>
                </div>


                {/* Report Display */}


            </div>
        </div>
    );
}

export default ReportBuilderDashboard;
