import React, { useState, useEffect, useRef, useCallback } from 'react';
import { IoMdClose } from 'react-icons/io';
import { debounce, set } from 'lodash';
import './sharePopup.css';
import { getIdToken } from '../../services/userDataService';

const SharePopup = ({ isOpen, onClose, documentToShare, onShare, userData }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const searchRef = useRef(null);
    const selectedUsersSet = new Set(selectedUsers.map(u => u.user_id));

    const fetchUsers = async () => {
        setIsLoading(true);
        try {
            const fetchUsersURL = process.env.REACT_APP_FETCH_USERS_URL;
            const response = await fetch(`${fetchUsersURL}?org_id=${userData.user_data.org_id}`, {
                method: 'GET',
                headers: {
                    'Authorization': await getIdToken()
                }
            });
            const data = await response.json();
            console.log('Fetched users:', data);
            if (data.users) {
                setUsers(data.users);
            }
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            fetchUsers();
            setSearchTerm('');
            setFilteredUsers([]);
            setSelectedUsers([]);
        }
    }, [isOpen]);

    const debouncedSearch = useCallback(
        debounce((value) => {
            console.log('Searching for:', value);
            if (value.trim()) {
                const filtered = users.filter(user =>
                    user.name.toLowerCase().includes(value.toLowerCase()) ||
                    user.email.toLowerCase().includes(value.toLowerCase())
                );
                console.log('Filtered users:', filtered);
                setFilteredUsers(filtered);
            } else {
                setFilteredUsers([]);
            }
        }, 300),
        [users]
    );

    useEffect(() => {
        debouncedSearch(searchTerm);
    }, [searchTerm, debouncedSearch]);

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
    };

    const handleUserSelect = (user) => {
        console.log('Selecting/Deselecting user:', user);
        setSelectedUsers(prev => {
            const newSelection = selectedUsersSet.has(user.user_id)
                ? prev.filter(u => u.user_id !== user.user_id)
                : [...prev, user];
            console.log('New selection:', newSelection);
            return newSelection;
        });
        searchRef.current?.focus();
    };

    console.log('Current selected users:', selectedUsers);

    if (!isOpen) return null;

    return (
        <div className="share-popup-overlay" onClick={onClose}>
            <div className="share-popup" onClick={(e) => e.stopPropagation()}>
                <div className="share-popup-header">
                    <h2>Share "{documentToShare.document_name}"</h2>
                    <button className="share-popup-close" onClick={onClose}><IoMdClose /></button>
                </div>
                <div className="share-popup-content">
                    <div className="share-search-container">
                        <input
                            ref={searchRef}
                            type="text"
                            placeholder="Search users..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="share-search-input"
                        />
                        {isLoading && <div className="share-loading">Loading...</div>}
                        {filteredUsers.length > 0 && (
                            <ul className="share-autocomplete-dropdown">
                                {filteredUsers.map(user => (
                                    <li
                                        key={user.user_id}
                                        onClick={() => handleUserSelect(user)}
                                        className="share-autocomplete-item"
                                    >
                                        <input
                                            type="checkbox"
                                            checked={selectedUsersSet.has(user.user_id)}
                                            readOnly
                                        />
                                        {user.name} ({user.email})
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <div className="share-selected-users">
                        {selectedUsers.map(user => (
                            <div key={user.user_id} className="share-selected-user">
                                {user.name}
                                <button onClick={() => handleUserSelect(user)} className="share-remove-user">×</button>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="share-popup-footer">
                    <button
                        className="share-popup-button share-clear"
                        onClick={() => setSelectedUsers([])}
                    >
                        Clear
                    </button>
                    <button
                        className="share-popup-button share-submit"
                        onClick={() => {
                            onShare(documentToShare, selectedUsers);
                            onClose();
                        }}
                        disabled={selectedUsers.length === 0}
                    >
                        Share
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SharePopup;