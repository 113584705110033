// src/components/fileExplorer/VersionConflictModal.js

import React from 'react';
import './VersionConflictModal.css'; // Create appropriate styles
import { AiOutlineClose } from 'react-icons/ai';

/**
 * VersionConflictModal Component
 * 
 * Props:
 * - isOpen: Boolean to control modal visibility
 * - onClose: Function to close the modal
 * - onAddNewVersion: Function to handle adding a new version
 * - onReplaceVersion: Function to handle replacing the current version
 */
const VersionConflictModal = ({ isOpen, onClose, onAddNewVersion, onReplaceVersion }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" role="dialog" aria-modal="true" aria-labelledby="version-conflict-title">
      <div className="modal-content">
        <button className="modal-close-button" onClick={onClose} aria-label="Close Modal">
          <AiOutlineClose />
        </button>
        <h2 id="version-conflict-title">Document Already Exists</h2>
        <p>This document already exists in your organization's database.</p>
        <div className="modal-buttons">
          <button onClick={onAddNewVersion} className="modal-button add-version-button">
            Add New Version
          </button>
          {/* <button onClick={onReplaceVersion} className="modal-button replace-version-button">
            Replace Current Version
          </button> */}
          <button onClick={onClose} className="modal-button cancel-button">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default VersionConflictModal;
