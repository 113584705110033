import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import './bidai.css';
import { motion } from "framer-motion"; // <--- Import Framer Motion
import { getIdToken } from "../../services/userDataService";
import { useNotification } from "../../components/useNotification";
import { ChevronDown, ChevronUp, Filter, Info, Settings, User } from "lucide-react";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Textarea } from "../../components/ui/textarea";
import { Skeleton } from "../../components/ui/skeleton";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";

import FilterModal from "./FilterModal";
// import { Select, SelectTrigger, SelectContent, SelectItem, SelectValue } from "../../components/ui/select";
import { Dialog, DialogTrigger, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "../../components/ui/dialog";
import { BidAiContext } from "../../context/BidAiContext";
import { Document, Packer, Paragraph, TextRun, HeadingLevel } from "docx";
import SettingsModal from "./SettingsModal";

const BidAI = ({ userData }) => {
  const [selectedRFPFile, setSelectedRFPFile] = useState(null);
  const [projectStatus, setProjectStatus] = useState("Extracting Template...");
  const [phase, setPhase] = useState("");
  const [sections, setSections] = useState([]);
  const [expandedSection, setExpandedSection] = useState(null);
  const [editingSection, setEditingSection] = useState(null);
  const [tempContent, setTempContent] = useState({});
  const [filters, setFilters] = useState({
    folders: "",
    tags: "",
    project: "",
    document: "",
    dateRange: { from: "", to: "" },
  });
  const [job_id, setJobId] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [tagList, setTagList] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [projects, setProjects] = useState([]);

  const [projectName, setProjectName] = useState("");
  const [companyName, setCompanyName] = useState(userData.org_data.name);
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyInfo, setCompanyInfo] = useState("");

  const [chatInput, setChatInput] = useState("");
  // We'll track loading on a per-section basis so that each section can have its own spinner
  const [chatLoadingBySection, setChatLoadingBySection] = useState({});

  // New State for “Load Saved Bid Project” modal
  const [isLoadModalOpen, setIsLoadModalOpen] = useState(false);
  const [savedProjects, setSavedProjects] = useState([]); // List of projects to display in modal

  const { addNotification } = useNotification();
  const fileInputRef = useRef(null);
  const FETCH_DOCUMENTS_URL = process.env.REACT_APP_FETCH_DOCUMENTS_URL;
  const [sectionHeights, setSectionHeights] = useState({}); // Add sectionHeights state
  const sectionRefs = useRef({});
  const { shouldRefresh, clearRefresh } = useContext(BidAiContext);
  const [useBidAIToggle, setUseBidAIToggle] = useState(true); // Default to true
  const [selectedTemplateFile, setSelectedTemplateFile] = useState(null);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const templateFileInputRef = useRef(null);

  // -------------------------------------
  // 1. LOAD AN EXISTING PROJECT
  // -------------------------------------
  const openLoadModal = async () => {
    setIsLoadModalOpen(true);
    // call fetchBidProjects so that as soon as the modal opens, it populates the list
    await fetchBidProjects();
  };

  console.log(userData)
  const closeLoadModal = () => {
    setIsLoadModalOpen(false);
  };

  const fetchBidProjects = async () => {
    try {
      const token = await getIdToken();
      // (Write an endpoint that returns a list of BidAI projects — e.g. /bidAI/list or /fetchProjects)
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/bidAI/fetchProjects`,
        { org_id: userData.user_data.org_id },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
        }
      );
      // Suppose the response is { projects: [{ jobId, projectName, status, createdAt }, ...] }
      setSavedProjects(response.data.projects || []);
    } catch (err) {
      console.error('fetchBidProjects error:', err);
      addNotification('Failed to fetch BidAI Projects', 'error');
    }
  };

  const loadProject = async (project) => {
    //  project === { jobId, projectName, status, ... }
    setJobId(project.jobId);
    setProjectName(project.projectName);
    setProjectStatus(project.status || "Processing...");
    setPhase("processing");
    closeLoadModal();

    // Now let’s actually fetch the data from your getData function
    // This will populate 'sections' (and other details if your getData returns them)
    await fetchDataWithJobID(project.jobId);
  };

  const deleteProject = async (project) => {
    try {
      // Make an API call to remove that project
      // For example: /bidAI/delete?job_id=${project.jobId}
      await axios.delete(`${process.env.REACT_APP_BASE_URL}/bidAI/delete?job_id=${project.jobId}`);

      addNotification('Project deleted successfully', 'success');
      // Re-fetch the list
      fetchBidProjects();
    } catch (error) {
      addNotification('Failed to delete project', 'error');
    }
  };

  // Helper function: fetch data for a specific job ID
  const fetchDataWithJobID = async (thisJobId) => {
    try {
      const response = await fetch(
        `https://1dtysfxwbb.execute-api.ca-central-1.amazonaws.com/bidAI/getData?job_id=${thisJobId}`
      );
      const data = await response.json();
      console.log("Fetched bid project data:", data);
      setSections(data.sections || []);
      setProjectStatus(data.projectStatus || "Processing...");
      setCompanyName(data.companyName || "");
      setCompanyAddress(data.companyAddress || "");
      setCompanyInfo(data.companyInfo || "");
      setProjectName(data.projectName || "");
    } catch (err) {
      console.error('fetchDataWithJobID error:', err);
    }
  };

  // Original fetchData (for when job_id is already set)
  const fetchData = async () => {
    if (!job_id) return;
    await fetchDataWithJobID(job_id);
  };


  useEffect(() => {
    if (job_id && shouldRefresh) {
      fetchData().then(() => {
        // After fetching data, stop the refresh flag so it doesn't loop
        clearRefresh();
      }).catch(err => console.error(err));
    }
  }, [shouldRefresh, job_id, clearRefresh]);

  const fetchAllTags = async () => {
    try {
      const token = await getIdToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/tags?org_id=${userData.user_data.org_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log('Fetched tags:', response.data.tags);
        setTagList(response.data.tags);
      } else {
        throw new Error('Failed to fetch tags.');
      }
    } catch (error) {
      console.error('Error fetching tags:', error);
      addNotification('Failed to fetch tags. Please try again.', 'error');
    }
  }

  // Fetch documents
  const fetchDocuments = async () => {
    try {
      const token = await getIdToken();
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/fetchDocuments?org_id=${userData.user_data.org_id}&user_id=${userData.user_data.user_id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
        }
      });
      const fetchedDocuments = response.data || [];
      setDocuments(fetchedDocuments);
      console.log('Fetched documents:', fetchedDocuments);
      // Set initial filters with allDocumentsSelected as true
      setFilters(prevFilters => ({
        ...prevFilters,
        documents: [],
        allDocumentsSelected: true
      }));
    } catch (error) {
      addNotification('Failed to fetch documents', 'error');
    }
  };

  const handleStart = async () => {
    if (!selectedRFPFile) {
      addNotification("No RFP file selected!", "error");
      return;
    }

    setPhase("uploading");

    try {
      // 1. Collect Data
      const projectData = {
        projectName,
        companyName,
        companyAddress,
        companyInfo,
        filters,
        useBidAIToggle, // Replace with the actual state if you have a variable for it
        rfpFile: {
          name: selectedRFPFile.name,
          type: selectedRFPFile.type,
          // You might want to include the file content or a unique identifier here
        },
        // Optional information (add as needed)
        templateFile: null, // Placeholder - adapt based on how you handle template file selection
        successfulBids: [],  // Placeholder - adapt based on how you select successful bid files
      };

      // Request presigned URL and upload to S3
      const token = await getIdToken();
      const presignedRes = await fetch(`${process.env.REACT_APP_BASE_URL}/bidAI/start`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          fileName: selectedRFPFile.name,
          fileType: selectedRFPFile.type,
          userId: userData.user_data.user_id,
          orgId: userData.user_data.org_id,
        }),
      });

      if (!presignedRes.ok) throw new Error('Failed to get upload URL');
      const { uploadUrl, key, documentId } = await presignedRes.json();

      const uploadRes = await fetch(uploadUrl, {
        method: 'PUT',
        body: selectedRFPFile,
        headers: { 'Content-Type': selectedRFPFile.type },
      });

      if (!uploadRes.ok) throw new Error('Failed to upload file');

      // 2. Send Data to Backend (Modified)
      const processRes = await fetch(`${process.env.REACT_APP_BASE_URL}/bidAI/getURL`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          key,
          documentId,
          fileName: selectedRFPFile.name,
          fileType: selectedRFPFile.type,
          userId: userData.user_data.user_id,
          orgId: userData.user_data.org_id,
          database: userData.vector_databases[0].database_id,
          projectData, // Pass the collected data
        }),
      });

      if (!processRes.ok) throw new Error('Failed to start processing');

      // At this point, the backend should be processing
      const { job_id } = await processRes.json();
      setJobId(job_id);
      setPhase("processing");
      setProjectStatus("Processing File and Extracting Template...");
    } catch (err) {
      console.error('Upload error:', err);
      setError(err.message);
      setPhase("idle");
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Reset states
    setUploading(true);
    setError(null);
    setSuccess(false);

    try {
      // First get the presigned URL
      const presignedRes = await fetch(`${process.env.REACT_APP_BASE_URL}/bidAI/start`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fileName: file.name,
          fileType: file.type,
          userId: userData.user_data.user_id,
          orgId: userData.user_data.org_id,
        }),
      });

      if (!presignedRes.ok) throw new Error('Failed to get upload URL');

      const { uploadUrl, key, documentId } = await presignedRes.json();

      // Upload to S3
      const uploadRes = await fetch(uploadUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      });

      if (!uploadRes.ok) throw new Error('Failed to upload file');
      // Start processing
      const processRes = await fetch(`${process.env.REACT_APP_BASE_URL}/bidAI/getURL`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          key,
          documentId,
          fileName: file.name,
          fileType: file.type,
          userId: userData.user_data.user_id,
          orgId: userData.user_data.org_id,
          database: userData.vector_databases[0].database_id,
          filters: JSON.stringify(filters),
        }),
      });

      if (!processRes.ok) throw new Error('Failed to start processing');

      setSuccess(true);
    } catch (err) {
      console.error('Upload error:', err);
      setError(err.message);
    } finally {
      setUploading(false);
    }
  };

  const fetchProjects = async () => {
    try {
      const token = await getIdToken();
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/fetchProjects`,
        { 'org_id': userData.user_data.org_id },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': await getIdToken(),
          }
        });
      const fetchedProjects = response.data['projects'] || [];
      setProjects(fetchedProjects);
    } catch (error) {
      addNotification('Failed to fetch projects', 'error');
    }
  }

  useEffect(() => {
    fetchAllTags();
    fetchDocuments();
    fetchProjects();
  }, []);

  const handleSelectChange = (field, value) => {
    setFilters((prev) => ({ ...prev, [field]: value }));
  };

  const handleChatSubmit = async (e, sectionId) => {
    e.stopPropagation();
    e.preventDefault();
    // Show spinner for this section
    setChatLoadingBySection((prev) => ({ ...prev, [sectionId]: true }));

    // The text we’ll send to the AI as the base to revise
    const originalText =
      editingSection === sectionId
        ? tempContent[sectionId] // user is editing, so use the "in-progress" content
        : sections.find((s) => s.section_id === sectionId)?.section_content;
    // The user’s request/prompt
    const userSuggestion = chatInput.trim();

    if (!originalText || !userSuggestion) {
      // In production, show an error or a toaster
      setChatLoadingBySection((prev) => ({ ...prev, [sectionId]: false }));
      return;
    }

    try {
      // Example payload
      const payload = {
        sectionId,
        originalText,
        userSuggestion,
        // ...any other info your Lambda might need, e.g. orgId, userId
      };

      // Make the request to your revision Lambda
      const reviseUrl = `${process.env.REACT_APP_BASE_URL}/bidAI/reviseContent`;
      const response = await fetch(reviseUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Revise request failed.');
      }

      const { revisedText } = await response.json();  // e.g. { revisedText: "...some new text..." }

      // If the user is currently editing this section, update the local tempContent
      if (editingSection === sectionId) {
        setTempContent((prev) => ({
          ...prev,
          [sectionId]: revisedText,
        }));
      }
      // Otherwise, if you want to overwrite the main content (not in edit mode):
      else {
        setSections((prevSections) =>
          prevSections.map((s) =>
            s.section_id === sectionId
              ? { ...s, section_content: revisedText }
              : s
          )
        );
      }

      // Optionally clear the user’s input box after success
      setChatInput("");
    } catch (error) {
      console.error('Revision request error:', error);
      // Show an error notification if desired
    } finally {
      // Hide spinner
      setChatLoadingBySection((prev) => ({ ...prev, [sectionId]: false }));
    }
  };


  const toggleSection = (sectionId) => {
    if (expandedSection === sectionId) {
      setExpandedSection(null);
      setEditingSection(null); // Also exit edit mode if section is closed
    } else {
      setExpandedSection(sectionId);
      // Keep editingSection state as it is
    }
  };

  const handleExportDocx = async () => {
    const allParagraphs = [];

    sections.forEach((section, index) => {
      // Optional: Insert a heading paragraph for the section name
      if (section.section_name) {
        allParagraphs.push(
          new Paragraph({
            text: `SECTION ${index + 1}: ${section.section_name}`,
            heading: HeadingLevel.HEADING_2,
            spacing: { after: 300 },
          })
        );
      }

      // Convert the (potentially markdown) content to docx paragraphs
      const docxParagraphs = parseMarkdownToDocxParagraphs(section.section_content || "");
      allParagraphs.push(...docxParagraphs);

      // Extra blank line between sections, optional
      allParagraphs.push(new Paragraph({ text: "" }));
    });

    // Create the docx Document
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: allParagraphs,
        },
      ],
    });

    // Generate a Blob
    const blob = await Packer.toBlob(doc);

    // Trigger download
    const url = URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = `${projectName.replace(/\s+/g, "_")}_combined.docx`;
    anchor.click();
    URL.revokeObjectURL(url);
  };

  function parseMarkdownToDocxParagraphs(mdContent) {
    const lines = mdContent.split("\n");
    const allParagraphs = [];

    lines.forEach((line) => {
      const paragraphs = parseMarkdownLineToParagraphs(line);
      allParagraphs.push(...paragraphs);
    });

    return allParagraphs;
  }

  function parseMarkdownLineToParagraphs(line) {
    // 1) Detect Heading
    if (line.startsWith("#### ")) {
      // e.g. "#### Level 4 Heading"
      const headingText = line.replace(/^####\s*/, "");
      return [
        new Paragraph({
          heading: HeadingLevel.HEADING_4,
          children: parseBoldSegments(headingText),
        }),
      ];
    } else if (line.startsWith("### ")) {
      // e.g. "### My Subheading"
      const headingText = line.replace(/^###\s*/, "");
      // Return [ Paragraph(HEADING_3) ]
      return [
        new Paragraph({
          heading: HeadingLevel.HEADING_3,
          children: parseBoldSegments(headingText),
        }),
      ];
    } else if (line.startsWith("## ")) {
      // e.g. "## My Heading"
      const headingText = line.replace(/^##\s*/, "");
      // Return [ Paragraph(HEADING_2) ]
      return [
        new Paragraph({
          heading: HeadingLevel.HEADING_2,
          children: parseBoldSegments(headingText),
        }),
      ];
    }

    // 2) Otherwise treat as normal paragraph
    return [new Paragraph({ children: parseBoldSegments(line) })];
  }

  /**
   * parseBoldSegments(line) -> returns an array of TextRun
   * 
   * This scans for "**bold**" segments. Everything else is normal text.
   */
  function parseBoldSegments(line) {
    const result = [];

    // Regex to split on **...**
    // This approach finds pairs of "**" and returns [normalText, boldText, normalText, ...]
    const regex = /\*\*(.+?)\*\*/g;

    let lastIndex = 0;
    let match;

    while ((match = regex.exec(line)) !== null) {
      // match[0] is the entire "**foo**"
      // match[1] is the text "foo"

      const startIndex = match.index;
      // The text *before* "**" is normal
      if (startIndex > lastIndex) {
        const normalText = line.slice(lastIndex, startIndex);
        if (normalText) {
          result.push(new TextRun({ text: normalText }));
        }
      }

      // The text *inside* "**" is bold
      const boldText = match[1];
      result.push(new TextRun({ text: boldText, bold: true }));

      lastIndex = regex.lastIndex;
    }

    // If there's leftover text after the last **bold** match
    if (lastIndex < line.length) {
      const leftover = line.slice(lastIndex);
      if (leftover) {
        result.push(new TextRun({ text: leftover }));
      }
    }

    // If line is empty, push an empty text run so docx doesn't ignore it
    if (result.length === 0) {
      result.push(new TextRun(""));
    }

    return result;
  }


  const handleEditClick = (event, sectionId) => {
    event.stopPropagation();

    if (expandedSection !== sectionId) {
      setExpandedSection(sectionId);
    }

    setEditingSection(sectionId);
    setTempContent((prevTempContent) => ({
      ...prevTempContent,
      [sectionId]: sections.find((s) => s.section_id === sectionId).section_content,
    }));
  };

  const updateSection = async (sectionId, newContent) => {
    try {
      // Example payload
      const payload = {
        job_id,           // the job_id for this project
        section_id: sectionId,
        new_content: newContent
      };

      // You may also need orgId, userId, etc., depending on your backend’s needs:
      // payload.orgId = userData.user_data.org_id;
      // payload.userId = userData.user_data.user_id;

      // Call your backend route for updating section content:
      const updateUrl = `${process.env.REACT_APP_BASE_URL}/bidAI/updateSection`;
      const res = await fetch(updateUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      });

      if (!res.ok) {
        throw new Error('Failed to update section on the backend.');
      }

      // Optionally get a response body:
      // const data = await res.json();
    } catch (err) {
      console.error('Error updating section:', err);
      addNotification('Failed to save your changes to the backend.', 'error');
    }
  };


  const handleSave = async (sectionId) => {
    const updatedSections = sections.map((s) =>
      s.section_id === sectionId ? { ...s, content: tempContent[sectionId] } : s
    );
    await updateSection(sectionId, tempContent[sectionId]);
    setSections(updatedSections);
    setEditingSection(null); // Only exit editing mode
    fetchData();
    setTempContent({ ...tempContent, [sectionId]: null });
  };

  const handleCancel = (sectionId) => {
    setEditingSection(null);
    setTempContent({ ...tempContent, [sectionId]: null });
  };

  const handleContentChange = (sectionId, newContent) => {
    setTempContent({ ...tempContent, [sectionId]: newContent });
  };

  // Function to update section height in the state
  const updateSectionHeight = (sectionId) => {
    if (sectionRefs.current[sectionId]) {
      setSectionHeights((prevHeights) => ({
        ...prevHeights,
        [sectionId]: sectionRefs.current[sectionId].scrollHeight,
      }));
    }
  };

  const handleSaveProject = async () => {
    // Example: Save current state to localStorage or send to your backend
    const saveData = {
      projectName,
      companyName,
      companyAddress,
      companyInfo,
      sections
    };
    localStorage.setItem('savedBid', JSON.stringify(saveData));
    setPhase("");
    setSections([]);
    setProjectName("");
    setCompanyName(userData.org_data.name);
  };

  const handleExport = () => {
    // Example: Export the current state as JSON file
    const exportData = {
      projectName,
      companyName,
      companyAddress,
      companyInfo,
      sections
    };
    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportData, null, 2));
    const dlAnchorElem = document.createElement('a');
    dlAnchorElem.setAttribute("href", dataStr);
    dlAnchorElem.setAttribute("download", projectName.replace(/\s+/g, '_') + "_bid_export.json");
    dlAnchorElem.click();
  };

  // Effect to initialize and update section heights
  useEffect(() => {
    if (!sections || !Array.isArray(sections)) return;
    sections.forEach((section) => {
      updateSectionHeight(section.section_id);
    });
  }, [sections, tempContent]);


  // Add framer-motion variants for some subtle animations
  const containerVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.4, ease: "easeOut" }
    }
  };

  const sectionVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.3, ease: "easeOut" }
    }
  };

  const collapsibleVariants = {
    open: { height: 'auto', opacity: 1, transition: { duration: 0.3 } },
    closed: { height: 0, opacity: 0, transition: { duration: 0.3 } }
  };

  return (
    phase === "processing" ? (
      <motion.div
        className="w-full h-full flex flex-col overflow-hidden p-4 pb-4 gap-4"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        {/* Top Section */}
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <Card className="rounded-3xl mb-4 border border-solid border-gray-200">
            <CardHeader className="m-0 h-1/2 w-1/2">
              <div className="flex flex-row justify-between items-center">
                <CardTitle className="text-3xl">{projectName}</CardTitle>
                <div className="text-blue-500">{companyName}</div>
                {/* <div className="text-gray-600">Date {new Date().toLocaleDateString()}</div> */}
              </div>
            </CardHeader>
            <CardContent className="flex flex-row justify-between gap-8 h-1/2 pb-0 mb-4">
              <div className="text-2xl text-green-600 animate-pulse mt-2 mb-0 ml-12">{projectStatus}</div>
              <div className="flex flex-row gap-8">
                <Button
                  className="bg-blue-500 text-white hover:bg-blue-600 px-4 py-2 rounded-xl"
                  onClick={handleSaveProject}
                >
                  Start a New Project
                </Button>
                <Button
                  className="bg-green-500 text-white hover:bg-green-600 px-4 py-2 rounded-xl"
                  onClick={handleExport}
                >
                  Export
                </Button>
                <Button
                  className="bg-indigo-500 text-white hover:bg-indigo-600 px-4 py-2 rounded-xl"
                  onClick={handleExportDocx}
                >
                  Export as DOCX
                </Button>
              </div>
            </CardContent>
          </Card>
        </motion.div>

        {/* Sections */}
        {sections.length === 0 ? (
          <div className="flex flex-col items-center gap-4 h-2/3">
            {Array.from({ length: 8 }).map((_, i) => (
              <div key={i} className="flex-1 w-[98%]">
                <Skeleton className="rounded-full w-full h-full" />
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-col gap-2 h-[93%] overflow-y-auto custom-scrollbar p-4">
            {sections.map((section) => (
              <motion.div
                key={section.section_id}
                className="w-full mb-2"
                variants={sectionVariants}
                initial="hidden"
                animate="visible"
              >
                <div
                  className="flex flex-col bg-white rounded-2xl shadow-md border border-solid border-gray-200"

                >
                  <div
                    className="flex flex-row items-center justify-between px-6 h-[11vh]"

                  >
                    <div className="text-xl font-semibold text-gray-900">{section.section_name}</div>
                    <div className="flex flex-row items-center gap-4">
                      <div className="text-green-600 text-sm">{section.section_status}</div>
                      <div className="text-gray-600">
                        {expandedSection === section.section_id ? (
                          <ChevronUp className="w-6 h-6 cursor-pointer" onClick={() => toggleSection(section.section_id)} />
                        ) : (
                          <ChevronDown className="w-6 h-6 cursor-pointer" onClick={() => toggleSection(section.section_id)} />
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`overflow-hidden transition-max-height duration-500 ease-in-out`}
                    style={{
                      maxHeight: expandedSection === section.section_id
                        ? `${sectionHeights[section.section_id]}px`
                        : '0px',
                    }}
                    ref={(el) => (sectionRefs.current[section.section_id] = el)} // Set ref for each section
                  >
                    <div className="p-6 pt-3">
                      <div className="flex flex-row gap-8">
                        <div className="w-2/3">
                          <div className="flex flex-row items-center">
                            <Label className="text-lg">Content</Label>
                            <Button
                              variant="link"
                              className="bg-transparent text-blue-500 p-0 ml-2"
                              onClick={(event) => handleEditClick(event, section.section_id)}
                            >
                              Edit
                            </Button>
                          </div>
                          <Textarea
                            className="w-full mt-2 rounded-xl border-gray-300 resize-none"
                            style={{ height: '250px' }}
                            value={
                              editingSection === section.section_id
                                ? tempContent[section.section_id]
                                : section.section_content
                            }
                            onChange={(e) =>
                              handleContentChange(section.section_id, e.target.value)
                            }
                            readOnly={editingSection !== section.section_id}
                          />
                          {editingSection === section.section_id && (
                            <div className="mt-4 flex flex-row items-center">
                              <Input
                                type="text"
                                placeholder="Suggest changes..."
                                className="w-full rounded-2xl border border-gray-300"
                                value={chatInput}
                                onChange={(e) => setChatInput(e.target.value)}
                              />
                              <Button className="ml-4 rounded-full" onClick={(e) => handleChatSubmit(e, section.section_id)}>
                                Send
                              </Button>
                            </div>
                          )}
                        </div>
                        <div className="w-1/3 h-full flex flex-col gap-4">
                          <Label className="text-lg flex items-center">
                            Questions
                            <span className="ml-2 text-gray-500">
                              {section.questions ? `${Math.min(3, section.questions.length)} of ${section.questions.length}` : '0 of 0'}
                            </span>
                            {section.questions && section.questions.length > 0 && (
                              <Dialog>
                                <DialogTrigger asChild>
                                  <Button variant="link" className="p-0 ml-2 text-sm bg-transparent text-blue-500">
                                    View All
                                  </Button>
                                </DialogTrigger>
                                <DialogContent className="sm:max-w-[425px]">
                                  <DialogHeader>
                                    <DialogTitle>Questions for {section.name}</DialogTitle>
                                    <DialogDescription>
                                      All questions related to this section.
                                    </DialogDescription>
                                  </DialogHeader>
                                  <div className="grid gap-4 py-4">
                                    {section.questions.map((question) => (
                                      <div key={question.QuestionID} className="text-sm">
                                        {question.Question}
                                      </div>
                                    ))}
                                  </div>
                                </DialogContent>
                              </Dialog>
                            )}
                          </Label>
                          <div className="mt-2 space-y-2">
                            {section.questions ? section.questions.slice(0, 3).map((question) => (
                              <div key={question.QuestionID} className="text-sm">
                                {question.Question}
                              </div>
                            )) : (
                              <div className="text-sm text-gray-500">No questions available.</div>
                            )}
                          </div>
                          {editingSection === section.section_id && (
                            <div className="mt-4 flex-grow flex flex-row justify-between items-end">
                              <Button
                                className="bg-green-500 text-white rounded-xl ml-4"
                                onClick={(e) => { e.stopPropagation(); handleSave(section.section_id); }}
                              >
                                Save
                              </Button>
                              <Button
                                className="bg-red-500 text-white rounded-xl ml-2"
                                onClick={(e) => { e.stopPropagation(); handleCancel(section.section_id); }}
                              >
                                Cancel
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        )}
      </motion.div>
    ) : (
      <div className="w-full h-full flex flex-col p-4 pb-4 gap-4">
        {/* Left Column (1/2) */}
        <div className="w-full flex flex-col h-full gap-8">
          {/* Submit New RFP Card */}
          <Card className="flex-shrink-0 flex flex-col h-1/3 w-full border border-solid border-gray-200">
            <CardHeader className="flex flex-row justify-between items-center">
              <div className="flex flex-row justify-between w-1/4 items-center">
                <CardTitle className="text-2xl font-light m-0 mt-1">Start A New Project</CardTitle>
                <Button className="bg-black hover:bg-gray-700 text-white px-4 m-2 rounded-2xl" style={{ height: '2rem' }} onClick={() => fileInputRef.current.click()}>Select RFP File</Button>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger className="bg-transparent w-min h-min ml-2  p-0">
                      <Info className="text-black bg-transparent" style={{ width: "1rem" }} />
                    </TooltipTrigger>
                    <TooltipContent className="w-48 p-4 bg-black text-white">
                      <p>Select the File Containing Details About the RFP You Are Looking To Create a Bid For</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
              <div className="flex flex-row items-center justify-around w-1/3">
                <Button
                  className="bg-green-600 hover:bg-green-700 hover:shadow-xl transition-all duration-300 text-white px-4 m-2 w-[60%] rounded-2xl justify-self-end self-center"
                  onClick={openLoadModal}
                >
                  Load Saved Bid Project
                </Button>
                <Button className="bg-transparent rounded-full" variant="ghost" onClick={() => setFilterModalOpen(true)}>
                  <Filter className="text-black min-h-6 min-w-6" />
                </Button>
                <input
                  type="file"
                  accept=".pdf"
                  ref={fileInputRef} // create a ref with useRef
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      setSelectedRFPFile(file);
                      setPhase("fileSelected");
                    }
                  }}
                />
                <Settings className="cursor-pointer" onClick={() => setSettingsOpen(true)} />
              </div>
            </CardHeader>
            <CardContent className="flex flex-col gap-4 h-full">
              {/* “Load Saved Bid Project” Button */}
              <div className="flex flex-col sm:flex-row items-start sm:items-end gap-4">
                {selectedRFPFile && (
                  <div className="mt-2 flex flex-row items-center">
                    <Button variant="ghost" className="bg-transparent hover:bg-transparent hover:text-red-600 text-black text-xl" onClick={() => { setSelectedRFPFile(null); setPhase("idle"); }}>X</Button>
                    <span className="mr-2">{selectedRFPFile.name}</span>
                  </div>
                )}
              </div>
            </CardContent>
            <CardFooter className="w-full flex-1 flex flex-row justify-around gap-4 self-end items-end">
            </CardFooter>
          </Card>

          {/* Bottom Panel (fills remaining height, scrollable if needed) */}
          <div className="w-full flex flex-row h-2/3">
            <Card className="flex-grow flex flex-row border border-solid border-gray-200">
              {/* Project Information Section */}
              <div className="flex-grow flex flex-col h-full w-2/5">
                <CardHeader className="flex flex-row justify-between items-center">
                  <CardTitle className="text-3xl font-light m-0 mt-1">Project Information</CardTitle>
                </CardHeader>
                <CardContent className="flex flex-col gap-2">
                  <div className="flex flex-row gap-12">
                    <div className="flex flex-col w-1/2 gap-8 pr-32 pl-8">
                      <div>
                        <Label className="text-lg">Project Name</Label>
                        <Input placeholder="Project Name" className="text-2xl w-full h-12 rounded-3xl border border-gray-400" value={projectName} onChange={(e) => setProjectName(e.target.value)} />
                      </div>
                      <div>
                        <Label className="text-lg">Company Name</Label>
                        <Input placeholder="Company Name" className="w-full h-12 rounded-3xl border border-gray-400" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                      </div>
                      <div>
                        <Label className="text-lg">Company Address</Label>
                        <Input placeholder="Company Address" className="w-full h-12 rounded-3xl border border-gray-400" value={companyAddress} onChange={(e) => setCompanyAddress(e.target.value)} />
                      </div>
                    </div>
                    <div className="flex flex-col w-1/2 pr-8">
                      <Label className="text-xl mb-8">Company Information</Label>
                      <Textarea placeholder="Tell Argus a bit about your company" className="rounded-3xl border border-gray-300 h-48" value={companyInfo} onChange={(e) => setCompanyInfo(e.target.value)}></Textarea>
                      <div className="flex flex-row gap-4 mt-8">
                        <Button className="bg-blue-500 hover:bg-blue-600 text-xl h-8 text-white w-28 rounded-3xl" onClick={handleStart}>
                          Start
                        </Button>
                        <Button className="bg-red-500 hover:bg-red-600 text-xl h-8 text-white w-28 rounded-3xl">
                          Reset
                        </Button>
                      </div>
                    </div>
                  </div>
                  {/* <div className="flex-grow flex flex-col w-1/3">
                <CardHeader className="flex flex-row justify-between items-center">
                  <CardTitle className="text-xl font-light m-0 mt-1">Optional Information</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="flex flex-row gap-2 align-center items-center ">
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger className="bg-transparent w-min h-min ml-2  p-0">
                          <Info className="text-black bg-transparent" style={{ width: "1rem" }} />
                        </TooltipTrigger>
                        <TooltipContent className="w-48 p-4 bg-black text-white">
                          <p>Pre-define the template sections by uploading a .json file with the template structure</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                    <Button className="bg-black hover:bg-gray-700 text-white px-4 m-2 rounded-2xl" style={{ height: '2rem' }} onClick={() => templateFileInputRef.current.click()}>Select Template File</Button>
                    <input
                      type="file"
                      accept=".json" // Or appropriate file types
                      ref={templateFileInputRef}
                      style={{ display: 'none' }}
                      onChange={(e) => setSelectedTemplateFile(e.target.files[0])}
                    />
                  </div>
                  <Label className="text-md font-semibold">Recognized Sections</Label>
                  <div className="flex flex-col gap-2 mt-2">
                    <Skeleton className="rounded-full h-6 w-full" />
                    <Skeleton className="rounded-full h-6 w-full" />
                    <Skeleton className="rounded-full h-6 w-full" />
                    <Skeleton className="rounded-full h-6 w-full" />
                    <Skeleton className="rounded-full h-6 w-full" />
                  </div>
                  <div className="flex flex-col min-h-[200px] flex-grow gap-2 align-center items-center justify-between ">
                    <div className="flex flex-row w-[70%] align-center items-center justify-between">
                    </div> */}

                </CardContent>
              </div>
            </Card>
          </div>

          <FilterModal
            open={filterModalOpen}
            onClose={() => setFilterModalOpen(false)}
            tagList={tagList}
            documents={documents}
            projects={projects}
            currentFilters={filters}
            onApplyFilters={(newFilters) => {
              // This is where you do setFilters(newFilters)
              // or partially merge them
              setFilters((prev) => ({ ...prev, ...newFilters }));
            }}
          />

          <SettingsModal
            open={settingsOpen}
            onClose={setSettingsOpen}
            useBidAIToggle={useBidAIToggle}
            setUseBidAIToggle={setUseBidAIToggle}
          />

          {/* ------------------------------------- */}
          {/*  The “Load Saved Bid Project” Dialog  */}
          {/* ------------------------------------- */}
          <Dialog open={isLoadModalOpen} onOpenChange={setIsLoadModalOpen} className="rounded-2xl">
            <DialogContent className="sm:max-w-[1000px] max-h-[600px] h-full rounded-3xl">
              <DialogHeader>
                <DialogTitle>Load Saved Bid Project</DialogTitle>
                <DialogDescription>
                  Select a previously saved BidAI project to continue work
                </DialogDescription>
              </DialogHeader>
              <div className="grid gap-4 py-4 max-h-[400px] overflow-y-auto">
                {/* TABLE OR LIST OF PROJECTS */}
                {savedProjects.length === 0 ? (
                  <div>No saved projects found.</div>
                ) : (
                  <table className="w-full border-collapse">
                    <thead>
                      <tr className="border-b">
                        <th className="p-2 text-left">Project Name</th>
                        <th className="p-2 text-left">Status</th>
                        {/* <th className="p-2 text-left">Created</th> */}
                        <th className="p-2 text-left">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {savedProjects.map((proj) => (
                        <tr key={proj.jobId} className="border-b">
                          <td className="p-2">{proj.projectName}</td>
                          <td className="p-2">{proj.status}</td>
                          {/* <td className="p-2">{proj.createdAt}</td> */}
                          <td className="p-2">
                            <Button
                              className="mr-2 bg-blue-500 text-white hover:bg-blue-600 rounded-2xl"
                              onClick={() => loadProject(proj)}
                            >
                              Load
                            </Button>
                            <Button
                              className="bg-red-500 text-white hover:bg-red-600 rounded-2xl"
                              onClick={() => deleteProject(proj)}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
              <DialogFooter>
                <Button variant="outline" onClick={closeLoadModal} className="mr-2 bg-gray-500 text-white hover:bg-gray-600 rounded-2xl">
                  Cancel
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>

      </div>
    )
  );
};

export default BidAI;
