import React, { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { useUsers } from '../hooks/useUsers';
import './shareSessionModal.css';


const ShareSessionModal = ({ isOpen, onClose, sessionId, onShare, orgId, sessionTitle, userData }) => {
  const [selectedUser, setSelectedUser] = useState({ id: '', name: '' });
  const [expirationDays, setExpirationDays] = useState(30);
  const { users, loading, error } = useUsers(orgId);

  if (!isOpen) return null;

  const handleShare = () => {
    onShare(sessionId, sessionTitle, selectedUser.id, userData.user_data.name, expirationDays);
    onClose();
  };

  if (loading) return <div className="share-modal-overlay"><div className="share-modal">Loading users...</div><div>X</div></div>;
  if (error) return <div className="share-modal-overlay"><div className="share-modal">Error loading users. Please try again.</div></div>;

  const filteredUsers = users.filter(user => user.user_id !== userData.user_data.user_id);

  return (
    <div className="share-modal-overlay">
      <div className="share-modal">
        <button className="close-button" onClick={onClose}>
          <AiOutlineClose />
        </button>
        <h2>Share Chat Session</h2>
        <select
          value={selectedUser.id}
          onChange={(e) => {
            const user = users.find(u => u.user_id === e.target.value);
            setSelectedUser({ id: user.user_id, name: user.name });
          }}
          className="user-select"
        >
          <option value="">Select a user</option>
          {filteredUsers.map(user => (
            <option key={user.user_id} value={user.user_id}>
              {user.name} ({user.username})
            </option>
          ))}
        </select>
        <div className="expiration-setting">
          <label htmlFor="expiration">Expires in:</label>
          <select
            id="expiration"
            value={expirationDays}
            onChange={(e) => setExpirationDays(Number(e.target.value))}
          >
            <option value={7}>7 days</option>
            <option value={30}>30 days</option>
            <option value={90}>90 days</option>
          </select>
        </div>
        <button className="share-button" onClick={handleShare} disabled={!selectedUser.id}>
          Share
        </button>
      </div>
    </div>
  );
};

export default ShareSessionModal;