function getSessionId() {
    // Check if a sessionId already exists in localStorage
    let sessionId = localStorage.getItem('sessionId');
    if (!sessionId) {
      // Generate a new sessionId (e.g., using UUID)
      sessionId = generateUUID(); // Implement generateUUID or use a library
      localStorage.setItem('sessionId', sessionId);
    }
    console.log('sessionId:', sessionId);
    return sessionId;
  }
  
  // Utility function to generate UUID (simplified version)
function generateUUID() {
    return 'xxxxxxxx-xxxx-xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

export { getSessionId, generateUUID };