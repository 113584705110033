import React, { useState } from 'react';
import { X } from 'lucide-react';
import './userCreationModal.css';

const UserCreationModal = ({ isOpen, onClose, onSubmit, userGroups }) => {
  const [newUser, setNewUser] = useState({
    email: '',
    name: '',
    username: '',
    userGroup: '',
  });

  const handleInputChange = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    onSubmit(newUser);
    setNewUser({ email: '', name: '', username: '', userGroup: '' });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <div className="modal-title">Add New User</div>
          <button onClick={onClose} className="modal-close-button">
            <X size={24} />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="modal-form">
          <input
            type="email"
            name="email"
            value={newUser.email}
            onChange={handleInputChange}
            placeholder="Email"
            required
            className="modal-input"
          />
          <input
            type="text"
            name="name"
            value={newUser.name}
            onChange={handleInputChange}
            placeholder="Name"
            required
            className="modal-input"
          />
          <input
            type="text"
            name="username"
            value={newUser.username}
            onChange={handleInputChange}
            placeholder="Username"
            required
            className="modal-input"
          />
          <select
            name="userGroup"
            value={newUser.userGroup}
            onChange={handleInputChange}
            className="modal-select"
          >
            <option value="">Select User Group (Optional)</option>
            {userGroups.map((group) => (
              <option key={group.id} value={group.id}>
                {group.name}
              </option>
            ))}
          </select>
          <button
            type="submit"
            className="modal-submit-button"
          >
            Add User
          </button>
        </form>
      </div>
    </div>
  );
};

export default UserCreationModal;