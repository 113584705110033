import React, { useEffect, useState } from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import { loginRequest } from '../components/msalConfig';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import axios from 'axios';
import { 
    FolderIcon, 
    FileIcon, 
    ChevronLeftIcon, 
    XIcon,
    CheckCircleIcon
} from 'lucide-react';
import './OneDriveModal.css';

const OneDriveModal = ({ isOpen, onClose, onSelect }) => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    
    const [accessToken, setAccessToken] = useState(null);
    const [items, setItems] = useState([]);
    const [currentPath, setCurrentPath] = useState('root');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedItems, setSelectedItems] = useState(new Set());
    
    const acquireToken = async () => {
        try {
            const response = await instance.acquireTokenSilent({
                ...loginRequest,
                account: account,
            });
            setAccessToken(response.accessToken);
        } catch (err) {
            if (err instanceof InteractionRequiredAuthError) {
                instance.acquireTokenPopup(loginRequest).then((response) => {
                    setAccessToken(response.accessToken);
                }).catch((error) => {
                    console.error(error);
                    setError('Authentication failed.');
                });
            }
        }
    };

    useEffect(() => {
        if (isOpen) {
            if (!account) {
                instance.loginPopup(loginRequest)
                    .then(() => acquireToken())
                    .catch((error) => setError('Authentication failed.'));
            } else {
                acquireToken();
            }
        }
    }, [isOpen]);

    const fetchItems = async (path) => {
        if (!accessToken) return;
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(
                `https://graph.microsoft.com/v1.0/me/drive/${path}/children`,
                {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
            );
            setItems(response.data.value);
        } catch (err) {
            setError('Failed to fetch OneDrive items.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (accessToken) {
            fetchItems(currentPath);
        }
    }, [accessToken, currentPath]);

    const handleSelect = (item) => {
        if (item.folder) {
            navigateToFolder(item.id);
        } else {
            setSelectedItems(prev => {
                const newSet = new Set(prev);
                if (newSet.has(item.id)) {
                    newSet.delete(item.id);
                } else {
                    newSet.add(item.id);
                }
                return newSet;
            });
        }
    };

    const handleUpload = () => {
        const selectedFiles = items.filter(item => selectedItems.has(item.id));
        onSelect(selectedFiles, accessToken);
        setSelectedItems(new Set());
    };

    const navigateToFolder = (folderId) => {
        setCurrentPath(`items/${folderId}`);
    };

    const navigateUp = () => {
        if (currentPath === 'root') return;
        setCurrentPath(prev => {
            const parts = prev.split('/');
            parts.pop();
            return parts.length > 1 ? parts.join('/') : 'root';
        });
    };

    if (!isOpen) return null;

    return (
        <div className="onedrive-modal-overlay">
            <div className="onedrive-modal">
                <div className="modal-header">
                    <h2>Select from OneDrive</h2>
                    <button onClick={onClose} className="close-button">
                        <XIcon className="icon" />
                    </button>
                </div>

                <div className="modal-navigation">
                    {currentPath !== 'root' && (
                        <button onClick={navigateUp} className="back-button">
                            <ChevronLeftIcon className="icon" />
                            Back
                        </button>
                    )}
                </div>

                <div className="one-drive-modal-content">
                    {loading ? (
                        <div className="loading-container">
                            <div className="loading-spinner"></div>
                        </div>
                    ) : error ? (
                        <div className="error-message">{error}</div>
                    ) : items.length === 0 ? (
                        <div className="empty-message">No items found</div>
                    ) : (
                        <div className="items-grid">
                            {items.map((item) => (
                                <div
                                    key={item.id}
                                    onClick={() => handleSelect(item)}
                                    className={`item ${item.folder ? 'folder' : 'file'} ${selectedItems.has(item.id) ? 'selected' : ''}`}
                                >
                                    {item.folder ? (
                                        <FolderIcon className="icon folder-icon" />
                                    ) : (
                                        <FileIcon className="icon file-icon" />
                                    )}
                                    <span className="item-name">{item.name}</span>
                                    {!item.folder && selectedItems.has(item.id) && (
                                        <CheckCircleIcon className="icon check-icon" />
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <div className="modal-footer">
                    <span className="selected-count">
                        {selectedItems.size} items selected
                    </span>
                    <button
                        onClick={handleUpload}
                        disabled={selectedItems.size === 0}
                        className={`upload-button ${selectedItems.size === 0 ? 'disabled' : ''}`}
                    >
                        Upload Selected Files
                    </button>
                </div>
            </div>
        </div>
    );
};

export default OneDriveModal;