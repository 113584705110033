import React, { useState } from 'react';
import { FaBars, FaBell, FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import ProfileModal from './ProfileModal';
import './topbar.css';

const NOTIFICATIONS_API_URL = process.env.REACT_APP_BASE_URL + '/notifications';

async function fetchLast5Notifications(userId) {
    const payload = {
        action: 'get',
        data: {
            user_id: userId
        }
    };

    const response = await fetch(NOTIFICATIONS_API_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    });

    if (!response.ok) {
        throw new Error(`Error fetching notifications: ${response.statusText}`);
    }

    const responseBody = await response.json();
    // Depending on your integration, you might need to do:
    // const parsedBody = JSON.parse(responseBody.body);
    // const allNotifications = parsedBody.notifications || [];

    const allNotifications = responseBody.notifications || [];
    return allNotifications.slice(0, 5); // keep only 5
}

const TopBar = ({ toggleSidebar, userDisplayName, companyName, userData }) => {
    const navigate = useNavigate();

    const [showNotifications, setShowNotifications] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [showProfileModal, setShowProfileModal] = useState(false);

    const handleNotificationsClick = async () => {
        setShowNotifications(prev => !prev);

        try {
            const data = await fetchLast5Notifications(userData.user_data.user_id);
            setNotifications(data);
        } catch (error) {
            console.error('Failed to fetch notifications', error);
        }
    };

    const handleProfileClick = () => {
        setShowProfileModal(true);
    };

    const closeProfileModal = () => {
        setShowProfileModal(false);
    };

    const handleSettingsClick = () => {
        navigate('/settings');
    };

    return (
        <header className="topbar">
            <div className="topbar-left">
                <FaBars onClick={toggleSidebar} className="topbar-menu-icon" />
            </div>

            <div className="topbar-center">
                <div className="topbar-search">
                    <FaSearch className="topbar-search-icon" />
                    <input type="text" placeholder="Search..." className="topbar-search-input" />
                </div>
            </div>

            <div className="topbar-right">
                <div className="topbar-icon-wrapper" onClick={handleNotificationsClick}>
                    <FaBell className="topbar-icon" />
                    <div className={`notifications-dropdown ${showNotifications ? 'open' : ''}`}>
                        <h4>Notifications</h4>
                        <ul>
                            {notifications.map(n => (
                                <li key={n.notification_id}>{n.message}</li>
                            ))}
                        </ul>
                        <div className="view-more">
                            <a href="/notifications">View More</a>
                        </div>
                    </div>
                </div>

                <div className="topbar-user" onClick={handleProfileClick}>
                    <div className="topbar-user-avatar">
                        {userDisplayName.charAt(0).toUpperCase()}
                    </div>
                    <span className="topbar-user-name">{userDisplayName}</span>
                </div>

                <div className="topbar-settings-icon" onClick={handleSettingsClick}>
                    ⚙️
                </div>
            </div>

            {showProfileModal && <ProfileModal userData={userData} onClose={closeProfileModal} />}
        </header>
    );
};

export default TopBar;
