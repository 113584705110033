import React, { useState, useEffect, useRef } from "react";
import './chat.css';
import ArgusLogo from '../components/assets/ArgusMain.png';
import TypingIndicator from '../utils/typingIndicator';
import { generateUUID } from '../functions/sessions';
import fetchMessagesForSession from '../functions/fetchMessages';
import { currentAuthenticatedUser } from "../functions/authService";
import { IoMdSend, IoMdAdd, IoMdArrowBack } from "react-icons/io";
import { FaFilter, FaHistory } from "react-icons/fa";
import ChatHistoryPanel from '../components/chatHistory';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import rehypeRaw from 'rehype-raw';
import LoadingSpinner from '../components/assets/loadingSpinner';
import FilterPanel from "../components/filtersPanel";
import { getIdToken, getUserGroups } from "../services/userDataService";
import process from "process";
import axios from 'axios';
import { useNotification } from "../components/useNotification";
import FiltersModal from "../components/modals/filtersModal";
import HistoryModal from "../components/modals/historyModal";


// Main Chat component
const Chat = ({ initialFilters, userData, tags }) => {
    // State variables
    const [isHistoryLoading, setIsHistoryLoading] = useState(true);
    const [isChatLoading, setIsChatLoading] = useState(false);
    const [userSessions, setUserSessions] = useState([]);
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const inputRef = useRef(null);
    const [streamingMessage, setStreamingMessage] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [chatTitle, setChatTitle] = useState('New Chat');
    const [sessionId, setSessionId] = useState(generateUUID());
    const [user, setUser] = useState(null);
    const [isHistoryOpen, setIsHistoryOpen] = useState(false); // State to control chat history
    const [isFilterOpen, setIsFilterOpen] = useState(false); // State to control filter panel
    const [documents, setDocuments] = useState([]);
    const [projects, setProjects] = useState([]);
    const [filters, setFilters] = useState({
        documents: [],
        documentType: [],
        project: [],
        tags: [],
        customInstructions: '',
        allDocumentsSelected: true
    });

    // Custom hook for notifications
    const { addNotification } = useNotification();

    // Environment variables
    const BASE_API_URL = process.env.REACT_APP_BASE_URL;
    const FETCH_DOCUMENTS_URL = 'fetchDocuments';
    const SESSION_GETTER = process.env.REACT_APP_BASE_URL + '/fetchSessions';
    const UPDATE_CHAT_TITLES = process.env.REACT_APP_UPDATE_CHAT_TITLES;
    const GENERATE_SUMMARY = process.env.REACT_APP_BASE_URL + '/generateSummary';

    // Set user data when it changes
    useEffect(() => {
        if (userData) {
            setUser(userData);
        }
        console.log('User:', userData);
    }, [userData]);

    // Handle filter changes
    const handleFilterChange = (newFilters) => {
        const allDocumentsSelected = newFilters.documents.length === 0;
        setFilters({
            ...newFilters,
            allDocumentsSelected
        });
        console.log('Filters:', {
            ...newFilters,
            allDocumentsSelected,
            documents: allDocumentsSelected ? 'All Documents' : newFilters.documents
        });
    };

    // Helper to check if filters are active
    // Helper to check if filters are active
    const areFiltersActive = () => {
        const { documents, project, tags, customInstructions, allDocumentsSelected } = filters;

        const documentsActive = !allDocumentsSelected && documents.length > 0;
        const projectsActive = project && project.length > 0;
        const tagsActive = tags && tags.length > 0;
        const instructionsActive = customInstructions && customInstructions.trim() !== '';

        return documentsActive || projectsActive || tagsActive || instructionsActive;
    };

    // Helper to get active filters string
    const getActiveFiltersString = () => {
        const { documents, project, tags, customInstructions, allDocumentsSelected } = filters;
        let parts = [];
        if (!allDocumentsSelected && documents.length > 0) {
            parts.push(`Documents: ${documents.join(', ')}`);
        }
        if (project && project.length > 0) {
            parts.push(`Projects: ${project.join(', ')}`);
        }
        if (tags && tags.length > 0) {
            parts.push(`Tags: ${tags.join(', ')}`);
        }
        if (customInstructions && customInstructions.trim() !== '') {
            parts.push(`Instructions: ${customInstructions}`);
        }
        return parts.join(' | ');
    };

    // Fetch user sessions
    const fetchSessions = async (initial = false) => {
        if (!hasMore && !initial) return;

        setIsHistoryLoading(true);
        try {
            const userId = await currentAuthenticatedUser();
            const limit = 10;
            let url = `${SESSION_GETTER}?userId=${encodeURIComponent(userId)}&limit=${limit}`;

            if (lastEvaluatedKey && !initial) {
                url += `&lastEvaluatedKey=${encodeURIComponent(lastEvaluatedKey)}`;
            }

            const response = await fetch(url);
            if (!response.ok) {
                addNotification('Failed to fetch sessions', 'error');
            }
            const data = await response.json();
            setUserSessions(prevSessions => initial ? data.sessions : [...prevSessions, ...data.sessions]);
            setLastEvaluatedKey(data.lastEvaluatedKey);
            setHasMore(!!data.lastEvaluatedKey);
        } catch (error) {
            addNotification('Failed to fetch sessions', 'error');
        } finally {
            setIsHistoryLoading(false);
        }
    };

    // Fetch documents and projects when user data changes
    useEffect(() => {
        if (userData) {
            fetchDocuments();
            fetchProjects();
        }
    }, [userData]);

    // Fetch sessions when component mounts
    useEffect(() => {
        fetchSessions(true);
    }, [userData]);

    // Fetch projects
    const fetchProjects = async () => {
        try {
            const token = await getIdToken();
            const response = await axios.post(`${BASE_API_URL}/fetchProjects`,
                { 'org_id': userData.user_data.org_id },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': await getIdToken(),
                    }
                });
            const fetchedProjects = response.data['projects'] || [];
            console.log('Fetched projects:', fetchedProjects);
            setProjects(fetchedProjects);
        } catch (error) {
            addNotification('Failed to fetch projects', 'error');
        }
    }

    // Fetch documents
    const fetchDocuments = async () => {
        try {
            console.log('fetching documents');
            const token = await getIdToken();
            const response = await axios.get(`${BASE_API_URL}/fetchDocuments?org_id=${userData.user_data.org_id}&user_id=${userData.user_data.user_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`,
                }
            });
            const fetchedDocuments = response.data || [];
            console.log('Fetched documents:', fetchedDocuments);
            setDocuments(fetchedDocuments);
            // Set initial filters with allDocumentsSelected as true
            setFilters(prevFilters => ({
                ...prevFilters,
                documents: [],
                allDocumentsSelected: true
            }));
        } catch (error) {
            addNotification('Failed to fetch documents', 'error');
        }
    }

    // Handle loading more sessions
    const handleLoadMore = () => {
        if (!isHistoryLoading) {
            fetchSessions();
        }
    };

    // Store sessionId in localStorage
    useEffect(() => {
        localStorage.setItem('sessionId', sessionId);
    }, [sessionId]);

    // Ref for scrolling to bottom of chat
    const messagesEndRef = useRef(null);
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    // Scroll to bottom when messages change
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    // Handle clicking on a chat history card
    const handleCardClick = async (sessionId) => {
        setIsChatLoading(true);
        try {
            const sessionMessages = await fetchMessagesForSession(sessionId);
            setSessionId(sessionId);
            setMessages(sessionMessages);
            const session = userSessions.find(s => s.sessionId === sessionId);
            setChatTitle(session ? session.title : 'Untitled Chat');
        } catch (error) {
            addNotification('Failed to fetch messages', 'error');
        } finally {
            setIsChatLoading(false);
        }
    }

    // Reset filters to default state
    const resetFilters = () => {
        setFilters({
            documents: [],
            documentType: [],
            project: [],
            uploadedOn: [],
            tags: [],
            customInstructions: '',
            allDocumentsSelected: true
        });
    };

    // Handle starting a new chat
    const handleNewChat = () => {
        setSessionId(generateUUID());
        setMessages([]);
        setChatTitle('New Chat');
        resetFilters();
    }

    // Update chat title
    const updateChatTitle = async (sessionId, newTitle) => {
        try {
            const response = await fetch(UPDATE_CHAT_TITLES, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: 'rename',
                    sessionId: sessionId,
                    newTitle: newTitle
                }),
            });

            if (!response.ok) {
                addNotification('Failed to update chat title', 'error');
            }

            const result = await response.json();

            setChatTitle(newTitle);
            // Update the title in userSessions state
            setUserSessions(prevSessions =>
                prevSessions.map(session =>
                    session.sessionId === sessionId ? { ...session, title: newTitle } : session
                )
            );
        } catch (error) {
            addNotification(`Failed to update chat title: ${error}`, 'error');
        }
    }

    // Send a message
    const sendMessage = async (e) => {
        if (e) e.preventDefault();
        if (!input.trim()) return;
        const userId = await currentAuthenticatedUser();
        const org_id = userData?.user_data.org_id;

        const userMessage = { id: Date.now(), text: input, sender: 'me' };
        setMessages((prevMessages) => [...prevMessages, userMessage]);

        setInput('');
        inputRef.current.style.height = 'auto';
        const database = userData?.vector_databases;
        console.log('database:', database);
        sendMessageToApi(input, sessionId, userId, database, filters, org_id)
            .catch(error => {
                addNotification(`Failed to send message: ${error}`, 'error');
            });
    };

    // Send message to API
    async function sendMessageToApi(query, sessionId, userId, database, filters, org_id) {
        const apiUrl = `https://z2yugr6ergc3wnbz3pv2fzampm0fwvco.lambda-url.ca-central-1.on.aws/`;
        setIsTyping(true);

        const preparedFilters = {
            ...filters,
            documents: filters.allDocumentsSelected ? documents.map(doc => doc.document_name) : filters.documents
        };
        delete preparedFilters.allDocumentsSelected;

        console.log('Sending message:', JSON.stringify({
            query,
            sessionId,
            userId,
            database,
            filters: preparedFilters,
            orgId: org_id
        }));

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': await getIdToken()
                },
                body: JSON.stringify({
                    query,
                    sessionId,
                    userId,
                    database,
                    filters: preparedFilters,
                    orgId: org_id,
                    isAllSelected: filters.allDocumentsSelected
                })
            });

            if (!response.ok) {
                addNotification(`HTTP error! status: ${response.status}`, 'error');
            }

            const data = await response.json();
            const answer = data['answer'];
            const sources = JSON.parse(data['source_documents']);

            console.log('Answer:', data);

            setIsTyping(false);
            setMessages((prevMessages) => [...prevMessages, { id: Date.now() + Math.random(), text: answer, sender: 'bot', sources }]);
            setStreamingMessage(null);

            // Generate summary
            const summary_response = await fetch(GENERATE_SUMMARY, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': await getIdToken()
                },
                body: JSON.stringify({
                    sessionId: sessionId,
                    database: user?.vector_databases,
                    filters // Include filters in the summary request as well
                })
            });

            if (!summary_response.ok) {
                addNotification(`HTTP error! status: ${summary_response.status}`, 'error');
            }

            return data;
        } catch (error) {
            addNotification(`Failed to send message: ${error}`, 'error');
            setIsTyping(false);
        }
    }

    // Auto-grow textarea
    function autoGrowTextArea(event) {
        const textarea = event.target;
        textarea.style.height = 'auto';
        textarea.style.height = `${Math.min(textarea.scrollHeight, 120)}px`;
    }

    // Toggle chat history panel
    const togglePanel = () => {
        setIsOpen(!isOpen);
    }

    // Toggle chat history modal
    const toggleHistoryPanel = () => {
        setIsHistoryOpen(!isHistoryOpen);
    }

    // Toggle filter modal
    const toggleFilterPanel = () => {
        setIsFilterOpen(!isFilterOpen);
    }

    // Render component
    return (
        <div className="chat-page">

            {areFiltersActive() && (
                <div className="active-filters-bar">
                    Active Filters: {getActiveFiltersString()}
                </div>
            )}
            <FaFilter onClick={toggleFilterPanel} className="filter-modal-icon" />
            <FaHistory onClick={toggleHistoryPanel} className="history-modal-icon" />

            {/* Modals */}
            <FiltersModal
                isOpen={isFilterOpen}
                onClose={toggleFilterPanel}
                filters={filters}
                onFilterChange={handleFilterChange}
                documents={documents}
                projects={projects}
                tags={tags}
            />

            <HistoryModal
                isOpen={isHistoryOpen}
                onClose={toggleHistoryPanel}
                userSessions={userSessions}
                handleCardClick={handleCardClick}
                isHistoryLoading={isHistoryLoading}
                onRename={updateChatTitle}
                onLoadMore={handleLoadMore}
                hasMore={hasMore}
                userData={userData}
            />

            {/* Main chat content */}
            <div className="chat-content">
                <div className="chatAndFilters">
                    <div className={`chatContainer ${isHistoryOpen ? 'reduced' : ''}`}>
                        {isChatLoading ? (
                            <div className="loading-spinner-container">
                                <LoadingSpinner />
                            </div>
                        ) : messages.length === 0 ? (
                            <div className="watermark">
                                <img src={ArgusLogo} alt="Argus" className="main-watermark" />
                                <div className="description-text">
                                    <p>Argus is your intelligent assistant.</p>
                                    <p>Type a message to start a conversation and get assistance with your queries.</p>
                                </div>
                            </div>
                        ) : (
                            <div className="chatHistory">
                                {messages.map((message) => (
                                    <div key={message.id} className={`message-${message.sender}`}>
                                        <ReactMarkdown
                                            className="message"
                                            children={message.text}
                                            remarkPlugins={[remarkBreaks]}
                                            rehypePlugins={[rehypeRaw]}
                                        />
                                    </div>
                                ))}
                                {isTyping && <TypingIndicator />}
                                <div ref={messagesEndRef} />
                            </div>
                        )}
                        <form className="messageForm" onSubmit={sendMessage}>
                            <textarea
                                ref={inputRef}
                                className="messageInput"
                                value={input}
                                onChange={(e) => {
                                    setInput(e.target.value);
                                    autoGrowTextArea(e);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && !e.shiftKey) {
                                        e.preventDefault();
                                        sendMessage(e);
                                    }
                                }}
                                placeholder="Type a message..."
                            />
                            <div className="buttonContainer">
                                <IoMdSend
                                    type="submit"
                                    className="send"
                                    onClick={sendMessage} />
                                <IoMdAdd
                                    type="button"
                                    className="newChat"
                                    onClick={handleNewChat} />
                            </div>
                        </form>
                    </div>
                </div>

                {/* {isHistoryOpen && (
                    <ChatHistoryPanel
                        history={userSessions}
                        refreshHistory={() => fetchSessions(true)}
                        onCardClick={handleCardClick}
                        onRename={updateChatTitle}
                        onLoadMore={handleLoadMore}
                        isLoading={isHistoryLoading}
                        hasMore={hasMore}
                        userIdFunc={currentAuthenticatedUser()}
                        userData={userData}
                    />
                )} */}
            </div>
        </div>
    );
}

// SourceList component to display sources for each message
const SourceList = ({ sources }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    }

    return (
        <div className="source-list">
            <button onClick={toggleExpanded} className="source-button">
                {isExpanded ? 'Hide Sources' : 'View Sources'}
            </button>
            {isExpanded && (
                <ul>
                    {sources.map((source, index) => (
                        <li key={index}>
                            {source.document.document_title}, Page {source.document.page}, Section {source.document.section_title}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Chat;
