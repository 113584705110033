// bidAiContext.js
import React, { createContext, useState } from 'react';

export const BidAiContext = createContext();

export const BidAiProvider = ({ children }) => {
    const [shouldRefresh, setShouldRefresh] = useState(false);

    const triggerRefresh = () => {
        setShouldRefresh(prev => !prev);
    };

    const clearRefresh = () => {
        setShouldRefresh(false);
    };

    return (
        <BidAiContext.Provider value={{ shouldRefresh, triggerRefresh, clearRefresh }}>
            {children}
        </BidAiContext.Provider>
    );
};
