import React, { useState } from 'react';
import { getIdToken } from '../../services/userDataService';
import { useNotification } from '../../components/useNotification';
import './jobDescriptionUpload.css';

const JobDescriptionUpload = ({ userData }) => {
    const [jobTitle, setJobTitle] = useState('');
    const [jobDescription, setJobDescription] = useState('');
    const [isDescriptionSubmitted, setIsDescriptionSubmitted] = useState(true);
    const [results, setResults] = useState(null);
    
    // 1. Loading state
    const [loading, setLoading] = useState(false);

    const { addNotification } = useNotification();

    const handleTitleChange = (e) => {
        setJobTitle(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setJobDescription(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!jobTitle || !jobDescription) {
            alert('Please provide a job title and description.');
            return;
        }

        // 2. Start loading
        setLoading(true);

        try {
            const token = await getIdToken();
            console.log('Submitting job description:', jobTitle, jobDescription);

            const response = await fetch(
                // process.env.REACT_APP_BASE_URL + '/ingestJobDescription',
                'https://gurxcdfqo2er6ichkqsalwpowa0bvuoi.lambda-url.ca-central-1.on.aws/',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        jobTitle,
                        jobDescription,
                        database: userData.vector_databases[0].database_id,
                    }),
                }
            );

            if (response.ok) {
                const result = await response.json();
                addNotification('Job description submitted successfully!', 'success');
                setIsDescriptionSubmitted(false);
                setResults(result.top_candidates);
                console.log('Job processed successfully:', result);
            } else {
                console.error('Failed to process job description');
            }
        } catch (error) {
            console.error('Error submitting job description:', error);
        } finally {
            // 3. Stop loading once request completes
            setLoading(false);
        }
    };

    const handleReset = () => {
        setJobTitle('');
        setJobDescription('');
        setResults(null);
    };

    return (
        <div className='job-description-upload-main-area'>
            <div className='recruit-ai-header-container'>
                <div className='recruit-ai-header'>RecruitAI</div>
                <button
                    onClick={() => setIsDescriptionSubmitted(true)}
                    className='reset-button'
                >
                    Start Over
                </button>
            </div>

            {/* 4. If loading, display overlay/spinner */}
            {loading && (
                <div className="loading-overlay">
                    <div className="loading-spinner"></div>
                    <div className="loading-text">Processing Job Description...</div>
                </div>
            )}

            {isDescriptionSubmitted ? (
                <div className='job-description-upload-section'>
                    <div className='job-description-upload-header'>
                        Job Description Upload
                    </div>

                    <form onSubmit={handleSubmit} className='job-description-form'>
                        <input
                            className='job-description-job-title-input'
                            type="text"
                            placeholder="Job Title"
                            value={jobTitle}
                            onChange={handleTitleChange}
                        />

                        <textarea
                            className='job-description-description-input'
                            placeholder="Job Description"
                            value={jobDescription}
                            onChange={handleDescriptionChange}
                        />

                        <div className='job-description-buttons-container'>
                            {/* Disable buttons if loading */}
                            <button
                                className='job-description-upload-button'
                                type="submit"
                                disabled={loading}
                            >
                                Submit Description
                            </button>
                            <button
                                type="button"
                                className='job-description-reset-button'
                                onClick={handleReset}
                                disabled={loading}
                            >
                                Clear
                            </button>
                        </div>
                    </form>
                </div>
            ) : (
                <div className='job-description-results'>
                    {results?.job_title && <div>{results.job_title}</div>}

                    {results && results.map((item, index) => (
                        <div key={index} className="result-card">
                            <h3>{item.resume.parsedData.name}</h3>
                            <h2>{item.combined_score.toFixed(2)} % Match</h2>
                            <div className='resume-scores'>
                                <div className='secondary-score'>
                                    <h3>Personal Score</h3>
                                    <div className='small-score-number'>{item.personal_score}</div>
                                </div>
                                <div className='secondary-score'>
                                    <h3>Education Score</h3>
                                    <div className='small-score-number'>{item.education_score}</div>
                                </div>
                                <div className='secondary-score'>
                                    <h3>Experience Score</h3>
                                    <div className='small-score-number'>{item.experience_score}</div>
                                </div>
                            </div>
                            <div className='reasoning-header'>Reasoning</div>
                            <p><strong>Personal</strong></p>
                            <div>{item.personal_reasoning}</div>
                            <p><strong>Education</strong></p>
                            <div>{item.education_reasoning}</div>
                            <p><strong>Experience</strong></p>
                            <div>{item.experience_reasoning}</div>
                            <p><strong>Contact Info:</strong> {item.resume.parsedData.contactInfo}</p>
                            <p><strong>Summary:</strong> {item.resume.parsedData.personal_summary}</p>
                            <div>
                                <strong>Education:</strong>
                                <ul>
                                    {item.resume.parsedData.education.map((edu, idx) => (
                                        <li key={idx}>
                                            {edu.degree} from {edu.institution} ({edu.year})
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div>
                                <strong>Experience:</strong>
                                <ul>
                                    {item.resume.parsedData.work_experience.map((exp, idx) => (
                                        <li key={idx}>
                                            {exp.position} at {exp.company} ({exp.years}): {exp.description}
                                            </li>
                                    ))}
                                </ul>
                            </div>
                            <div>
                                <strong>Skills:</strong>
                                <ul>
                                    {item.resume.parsedData.skills.map((skill, idx) => (
                                        <li key={idx}>{skill}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default JobDescriptionUpload;
