// src/context/ReportContext.js
import React, { createContext, useState, useCallback } from 'react';

export const ReportContext = createContext();

export const ReportProvider = ({ children }) => {
    const [shouldRefresh, setShouldRefresh] = useState(false);

    const triggerReportRefresh = () => {
        setShouldRefresh(prev => !prev);
    };

    const clearReportRefresh = () => {
        setShouldRefresh(false);
    };


    return (
        <ReportContext.Provider value={{ shouldRefresh, triggerReportRefresh, clearReportRefresh }}>
            {children}
        </ReportContext.Provider>
    );
};
