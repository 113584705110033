import React, { useState, useEffect } from 'react';
import { getIdToken } from '../services/userDataService';
import { max } from 'lodash';
import './emailList.css';
import LoadingSpinner from './assets/loadingSpinner';
const EmailList = ({ userData }) => {
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const OAUTH_EMAIL_HANDLER = process.env.REACT_APP_OAUTH_EMAIL_HANDLER;
  const START_EMAIL_INDEXING = process.env.REACT_APP_START_EMAIL_INDEXING;

  const fetchEmails = async () => {
    setLoading(true);
    setError(null);
    console.log('UserData:', userData);
    try {
      const idToken = await getIdToken();
      console.log('ID Token:', idToken);

      const response = await fetch(OAUTH_EMAIL_HANDLER, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': idToken
        },
        body: JSON.stringify({
          action: 'fetch_emails',
          provider: 'gmail',
          user_id: userData.user_data.user_id
        }),
      });

      console.log('Response status:', response.status);
      const responseText = await response.text();
      console.log('Response text:', responseText);

      if (!response.ok) {
        throw new Error(`Failed to fetch emails: ${response.status} ${responseText}`);
      }

      const data = JSON.parse(responseText);
      console.log('Parsed data:', data);

      setEmails(data.emails || []);
    } catch (err) {
      console.error('Error in fetchEmails:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const startIndexing = async () => {
    setLoading(true);
    setError(null);
    console.log('UserData:', userData.user_data.user_id);
    try {
      const idToken = await getIdToken();
      console.log('ID Token:', idToken);

      const response = await fetch(START_EMAIL_INDEXING, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': idToken
        },
        body: JSON.stringify({
          provider: 'gmail',
          user_id: userData.user_data.user_id,
          max_emails: 1000,
          org_vector_db: userData.vector_databases[0].database_id
        }),
      });

      console.log('Response status:', response.status);
      const responseText = await response.text();
      console.log('Response text:', responseText);

      if (!response.ok) {
        throw new Error(`Failed to start indexing: ${response.status} ${responseText}`);
      }

      console.log('Indexing started successfully');
    } catch (err) {
      console.error('Error in startIndexing:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmails();
  }, [userData]);

  return (
    <div className="email-main-area">
      <h2 className="email-page-header">Your Emails</h2>
      <div className='emails-buttons'>
        <button className='settings-button' onClick={fetchEmails} disabled={loading}>
          {loading ? 'Fetching...' : 'Refresh Emails'}
        </button>
        <button className='settings-button' onClick={() => startIndexing()} disabled={loading}>
          Start Indexing Job
        </button>
      </div>
      {error && <p className="error-message">{error}</p>}
      {emails.length > 0 ? (
        <ul className="emails-preview-list">
          {emails.map((email) => (
            <div key={email.id} className="email-item">
              <h3 className="email-subject">{email.subject}</h3>
              <p className='email-from'>From: {email.from}</p>
              <p className='email-to'>To: {email.to}</p>
            </div>
          ))}
        </ul>
      ) : (
        <>
        {loading ? <LoadingSpinner /> : 'no emails found'}    
        </>
      )}
    </div>
  );
};

export default EmailList;