// src/components/bidai/SettingsModal.jsx
import React from "react";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "../../components/ui/dialog"; // or your actual import path
import { Switch } from "../../components/ui/switch";
import { Label } from "../../components/ui/label";
import { Button } from "../../components/ui/button";

const SettingsModal = ({
  open,
  onClose,
  useBidAIToggle,
  setUseBidAIToggle,
}) => {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px] rounded-3xl">
        <DialogHeader>
          <DialogTitle>Settings</DialogTitle>
          <DialogDescription>
            Adjust your BidAI preferences here.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          {/* --- The Switch for Use BidAI File Toggle --- */}
          <div className="flex flex-col gap-2">
            <Label className="text-md font-semibold">Use BidAI File Toggle</Label>
            <Switch
              checked={useBidAIToggle}
              onCheckedChange={(checked) => setUseBidAIToggle(checked)}
            />
            <p className="text-sm text-gray-500 mt-1">
              Enabling this toggle will limit search results to only items
              that have the <strong>BidAI eligible</strong> setting enabled
              in the file explorer.
            </p>
          </div>
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={() => onClose(false)}>
            Close
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SettingsModal;
