import { useState } from 'react';
import process from 'process';
import { getIdToken } from '../services/userDataService';
import { useNotification } from '../components/useNotification';


export const useDeleteData = () => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [error, setError] = useState(null);

    const {addNotification} = useNotification();

    const DELETE_API = process.env.REACT_APP_DELETE_API;

    const deleteData = async (userId, orgId, deleteType, itemId = null, vector_db) => {
        setIsDeleting(true);
        setError(null);
        console.log('Deleting data', userId, orgId, deleteType, itemId);
        try {
            const idToken = await getIdToken();
            const response = await fetch(DELETE_API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': idToken,
                },
                body: JSON.stringify({
                    'user_id': userId,
                    'org_id': orgId,
                    'delete_type': deleteType,
                    'item_id': itemId,
                    'vector_db': vector_db,
                    'delete_shared': deleteType === 'chat'
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to delete data');
            }

            addNotification('Data deleted successfully', 'success');
            return true;
        } catch (err) {
            addNotification('Failed to delete data', 'error');
            return false;
        } finally {
            setIsDeleting(false);
        }
    };

    return { deleteData, isDeleting, error };
};