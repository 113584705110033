import React from 'react';
import './notification.css';
import { useNotification } from './useNotification'; // We'll create this hook next

const Notification = () => {
    const { notifications, removeNotification } = useNotification();

    return (
        <div className="notification-container">
            {notifications.map(({ id, message, type }) => (
                <div key={id} className={`notification ${type}`}>
                    {message}
                    <button onClick={() => removeNotification(id)} className='close-notification-button'>×</button>
                </div>
            ))}
        </div>
    );
};

export default Notification;