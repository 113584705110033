import React, { useState, useRef } from 'react';
import './resumeUpload.css';
import { getIdToken } from '../../services/userDataService';
import { useNotification } from '../../components/useNotification';

const MAX_FILES = 5;

const ResumeUpload = ({ userData }) => {
    const [files, setFiles] = useState([]);
    const fileInputRef = useRef(null);
    const { addNotification } = useNotification();

    // NEW: Loading state for spinner
    const [loading, setLoading] = useState(false);

    const addFiles = (newFiles) => {
        if (files.length + newFiles.length > MAX_FILES) {
            const allowedCount = MAX_FILES - files.length;
            if (allowedCount <= 0) {
                addNotification(`You can only upload up to ${MAX_FILES} files.`, 'error');
                return;
            }
            // If there's room for only some of them, slice
            const subset = newFiles.slice(0, allowedCount);
            setFiles((prev) => [...prev, ...subset]);
            addNotification(`You can only upload up to ${MAX_FILES} files. Truncated extra files.`, 'info');
        } else {
            setFiles((prev) => [...prev, ...newFiles]);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFiles = Array.from(e.dataTransfer.files);
        // Optionally filter to PDF only:
        const pdfs = droppedFiles.filter(f => f.type === 'application/pdf');
        addFiles(pdfs);
    };

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        // Optionally filter to PDF only:
        const pdfs = selectedFiles.filter(f => f.type === 'application/pdf');
        addFiles(pdfs);
    };

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleClear = () => {
        setFiles([]);
    };

    const handleSubmit = async () => {
        if (files.length === 0) {
            addNotification('No files to upload.', 'error');
            return;
        }

        setLoading(true);  // SHOW spinner

        try {
            console.log('Submitting files:', files);
            const token = await getIdToken();

            // Convert each file to base64
            const fileData = await Promise.all(files.map(file => fileToBase64(file)));

            const response = await fetch(process.env.REACT_APP_BASE_URL + '/ingestResume', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                body: JSON.stringify({
                    pdf_data: fileData,
                    database: userData.vector_databases[0].database_id
                }),
            });

            if (!response.ok) {
                addNotification('Failed to Upload Resume(s)', 'error');
                return;
            }

            const result = await response.json();
            console.log('Upload result:', result);
            addNotification('Resume(s) uploaded successfully!', 'success');
            handleClear(); // Clear after success
        } catch (err) {
            console.error('Failed to upload files:', err);
            addNotification(`Failed to upload resumes: ${err.message}`, 'error');
        } finally {
            setLoading(false); // HIDE spinner
        }
    };

    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve({
                    name: file.name,
                    data: reader.result
                });
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    return (
        <div className='resume-upload-main-area'>
            {/* Loading Overlay (conditionally shown) */}
            {loading && (
                <div className="loading-overlay">
                    <div className="loading-spinner"></div>
                    <div className="loading-text">Processing Resume(s)...</div>
                </div>
            )}
            <div className='resume-upload-header'>
                <div>Resume Upload</div>
                <div className='button-container'>
                    {/* Disable buttons while loading so user can't click multiple times */}
                    <button onClick={handleSubmit} disabled={loading}>Submit</button>
                    <button onClick={handleClear} className="clear" disabled={loading}>Clear</button>
                </div>
            </div>

            {files.length === 0 ? (
                <div
                    className='drag-drop-area'
                    onDrop={handleDrop}
                    onDragOver={(e) => e.preventDefault()}
                    onClick={handleClick}
                >
                    Drag and drop files here or click to select (Max {MAX_FILES})
                    <input
                        type="file"
                        accept=".pdf"
                        multiple
                        onChange={handleFileChange}
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                    />
                </div>
            ) : (
                <div className='file-list'>
                    {files.map((file, index) => (
                        <div key={index} className='file-card'>
                            {file.name}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ResumeUpload;
