import React, { useRef, useState, useEffect } from 'react'
import { FaTimes, FaSearch } from 'react-icons/fa'
// Adjust the import path according to how your shadcn components are organized
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogClose
} from "../ui/dialog"
import './filtersModal.css'

// We can remove your filtersModal.css or keep only the styling for the content (checkbox group, etc.)

export default function FiltersModal({
  isOpen,
  onClose,
  filters,
  onFilterChange,
  documents,
  projects,
  tags = []
}) {
  const [localFilters, setLocalFilters] = useState(filters)
  const [documentSearch, setDocumentSearch] = useState('')
  const [projectSearch, setProjectSearch] = useState('')
  const [filteredDocuments, setFilteredDocuments] = useState(documents)
  const [filteredProjects, setFilteredProjects] = useState(projects)

  // keep localFilters in sync with parent
  useEffect(() => {
    setLocalFilters(filters)
  }, [filters])

  // filter documents by name
  useEffect(() => {
    setFilteredDocuments(
      documents.filter((doc) =>
        doc.document_name.toLowerCase().includes(documentSearch.toLowerCase())
      )
    )
  }, [documents, documentSearch])

  // filter projects by name
  useEffect(() => {
    setFilteredProjects(
      projects.filter((project) =>
        project.name.toLowerCase().includes(projectSearch.toLowerCase())
      )
    )
  }, [projects, projectSearch])

  function handleFilterChange(filterType, value) {
    setLocalFilters((prev) => ({
      ...prev,
      [filterType]: value,
    }))
  }

  function handleCheckboxChange(filterType, value) {
    setLocalFilters((prev) => {
      const currentValues = prev[filterType] || []
      const updatedValues = currentValues.includes(value)
        ? currentValues.filter((item) => item !== value)
        : [...currentValues, value]
      return { ...prev, [filterType]: updatedValues }
    })
  }

  function applyFilters() {
    const finalFilters = {
      ...localFilters,
      documents: localFilters.documents,
      tags: localFilters.tags,
    }
    onFilterChange(finalFilters)
    onClose()
  }

  function clearFilters() {
    setLocalFilters({
      documents: [],
      project: [],
      tags: [],
      customInstructions: '',
    })
    setDocumentSearch('')
    setProjectSearch('')
  }

  // If you want the Dialog to unmount entirely when closed, 
  // you can conditionally render, or just let shadcn handle it.
  // But it's typical to do controlled usage of <Dialog>:
  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        // Whenever the dialog tries to close, call onClose.
        if (!open) onClose()
      }}
    >
      <DialogContent className="dialog-object h-[70%] z-50 rounded-3xl overflow-y-auto pr-8"> 
        {/* Adjust styling classes as needed */}
        <DialogHeader>
          <DialogTitle>Filter Options</DialogTitle>
          <DialogDescription>
            Select documents, projects, tags, or add custom instructions.
          </DialogDescription>
        </DialogHeader>

        {/* Close button - can also use <DialogClose> or a custom button */}
        <DialogClose asChild>
          <button
            className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
          >
            <FaTimes />
          </button>
        </DialogClose>

        {/* Documents Filter */}
        <div className="mb-0 border-b pb-4">
          <h3 className="text-lg font-semibold mb-2">Documents</h3>
          <div className="relative mb-2 flex items-center">
            <FaSearch className="absolute ml-2 text-gray-700" />
            <input
              type="text"
              placeholder="Search documents..."
              className="pl-8 pr-2 py-1 border border-gray-500 rounded-xl w-full"
              value={documentSearch}
              onChange={(e) => setDocumentSearch(e.target.value)}
            />
          </div>
          <div className="max-h-48 overflow-y-auto space-y-1">
            {filteredDocuments.map((doc) => (
              <label key={doc.document_id} className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  value={doc.document_name}
                  checked={localFilters.documents?.includes(doc.document_name)}
                  onChange={() => handleCheckboxChange('documents', doc.document_name)}
                />
                <span>{doc.document_name}</span>
              </label>
            ))}
          </div>
        </div>

        {/* Projects Filter */}
        <div className="mb-2 border-b pb-4">
          <h3 className="text-lg font-semibold mt-0 mb-2">Projects</h3>
          <div className="relative mb-2 flex items-center">
            <FaSearch className="absolute ml-2 text-gray-700" />
            <input
              type="text"
              placeholder="Search projects..."
              className="pl-8 pr-2 py-1 border border-gray-500 rounded-xl w-full"
              value={projectSearch}
              onChange={(e) => setProjectSearch(e.target.value)}
            />
          </div>
          <div className="max-h-32 overflow-y-auto space-y-1">
            {filteredProjects.map((project) => (
              <label key={project.project_id} className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  value={project.project_id}
                  checked={localFilters.project?.includes(project.project_id)}
                  onChange={() => handleCheckboxChange('project', project.project_id)}
                />
                <span>{project.name}</span>
              </label>
            ))}
          </div>
        </div>

        {/* Tags Filter */}
        <div className="mb-2 border-b pb-4">
          <h3 className="text-lg font-semibold mt-0 mb-2">Tags</h3>
          <div className="max-h-32 overflow-y-auto space-y-1">
            {tags.map((tag) => (
              <label key={tag} className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  value={tag}
                  checked={localFilters.tags?.includes(tag)}
                  onChange={() => handleCheckboxChange('tags', tag)}
                />
                <span>{tag}</span>
              </label>
            ))}
          </div>
        </div>

        {/* Custom Instructions */}
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2 mt-0">Custom Instructions</h3>
          <textarea
            className="w-full h-36 border border-gray-400 rounded-3xl p-4"
            placeholder="Enter any custom instructions"
            value={localFilters.customInstructions || ''}
            onChange={(e) => handleFilterChange('customInstructions', e.target.value)}
          />
        </div>

        <DialogFooter>
          <button
            onClick={clearFilters}
            className="px-4 py-2 bg-transparent rounded-full border-2 border-red-600 text-red-600 hover:bg-red-600 hover:text-white transition-colors duration-300 mr-2"
          >
            Clear Filters
          </button>
          <button
            onClick={applyFilters}
            className="px-4 py-2 bg-transparent border-blue-600 border-2 text-blue-600 rounded-full hover:bg-blue-600 transition-colors duration-300 hover:text-white"
          >
            Apply Filters
          </button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
