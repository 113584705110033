// src/App.js

import React, { useState, useEffect, useContext, useRef } from 'react';
import { Amplify } from 'aws-amplify';
import { Authenticator, ThemeProvider } from '@aws-amplify/ui-react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import Chat from './pages/chat.js';
import SemanticSearch from './pages/semanticSearch.js';
import './App.css';
import { currentAuthenticatedUser } from './functions/authService';
import fetchMessagesForSession from './functions/fetchMessages';
import FileExplorer from './components/fileExplorer/FileExplorer.js';
import EmailList from './components/emailList.js';
import UserSettings from './pages/userSettings.js';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './components/msalConfig.js';
import { fetchUserData, getCachedUserData, getUserGroups } from './services/userDataService';
import AdminPage from './pages/admin.js';
import { NotificationProvider } from './components/notificationContext.js';
import Notification from './components/notificationComponent.js';
import useWebSocketListener from './components/useWebSocketListener.js';
import { useNotification } from './components/useNotification.js';
import RecruitAIDashboard from './modules/recruitAI/dashboard.js';
import Sidebar from './sidebar/sidebar.js';
import TopBar from './topbar/topbar.js';
import { DocumentProvider, DocumentContext } from './context/DocumentContext'; // Import DocumentProvider and DocumentContext
import { myTheme } from './theme'; // Import your custom theme
import { customAuthenticatorComponents } from './components/customAuthenticatorComponents.js';
import './components/customAuthenticatorStyles.css'
import DocumentPage from './pages/DocumentPage.js';
import BidAI from './pages/BidAI/main.js';
import ReportBuilderDashboard from './modules/reportBuilder/dashboard.js';
import { ReportProvider } from './context/ReportContext.js';
import { ReportContext } from './context/ReportContext';
import { BidAiProvider } from './context/BidAiContext';
import { BidAiContext } from './context/BidAiContext';
import SettingsPage from './pages/SettingsPage.js';
import { motion } from 'framer-motion';
import ArgusLogo from 'src/components/assets/ArgusMain.png';
import ReportBuilder from './modules/reportBuilder/ReportBuilder.js';

/**
 * ErrorHandlerComponent
 * Captures global errors and logs them using the custom error handler.
 */
const ErrorHandlerComponent = () => {
  // const { logError } = useErrorHandler();

  React.useEffect(() => {
    const handleGlobalError = (event) => {
      event.preventDefault();
      // logError(event.error, 'Unhandled exception');
    };

    window.addEventListener('error', handleGlobalError);

    return () => {
      window.removeEventListener('error', handleGlobalError);
    };
  }, []);

  return null;
};

Amplify.configure(awsExports);

/**
 * LoadingComponent
 * Displays a loading spinner overlay.
 */
const LoadingComponent = () => (
  <div className="loading-overlay">
    <div className="loading-spinner"></div>
    <p>Loading user data...</p>
  </div>
);


export const getDocumentType = (documentName) => {
  if (!documentName) return 'unknown';
  const parts = documentName.split('.');
  console.log('parts:', parts);
  if (parts.length < 2) return 'unknown';
  const extension = parts.pop().toLowerCase();
  const typeMap = {
    'pdf': 'pdf',
    'docx': 'docx',
    'doc': 'doc',
    'xlsx': 'xlsx',
    'xls': 'xls',
    'zip': 'zip',
    // Add more mappings as needed
  };
  return typeMap[extension] || 'unknown';
};


/**
 * WebSocketHandler
 * Manages WebSocket connections and handles incoming messages to update documents in real-time.
 */
const WebSocketHandler = ({ userData }) => {
  const { addNotification } = useNotification();
  const { addOrUpdateDocument, updateDocumentStatus, navigateToFolder, currentFolder } = useContext(DocumentContext);
  const { triggerReportRefresh } = useContext(ReportContext); // Access setReportUrl from ReportContext
  const { triggerRefresh } = useContext(BidAiContext);
  const WebSocketUrl = userData
    ? `wss://1q9u7s3adf.execute-api.ca-central-1.amazonaws.com/dev?user_id=${userData.user_data.user_id}&org_id=${userData.user_data.org_id}`
    : null;
  const { isConnected, lastMessage } = useWebSocketListener(WebSocketUrl);
  const lastUpdateRef = useRef({ job_id: null, status: null });
  useEffect(() => {
    if (isConnected) {
      console.log('Connected to WebSocket');
    } else {
      console.log('Disconnected from WebSocket. Attempting to reconnect...');
    }
  }, [isConnected]);

  useEffect(() => {
    if (lastMessage) {
      console.log('user data:', userData);
      console.log('Received message:', lastMessage);

      // Safeguard: Ensure the message is an object
      let messageObj = lastMessage;
      if (typeof lastMessage === 'string') {
        try {
          messageObj = JSON.parse(lastMessage);
        } catch (error) {
          console.error('Error parsing lastMessage:', error);
          return; // Exit early if parsing fails
        }
      }

      console.log('messageObj:', messageObj);

      const { type, document_name, job_id, status, report_url } = messageObj;

      if (type === 'report_url_notification') {
        addNotification('Your feedback report is ready for download!', 'success');
      } else if (type === 'upload_status') {
        // Handle other message types as before
        const documentType = getDocumentType(document_name);
        handleUploadStatus(job_id, document_name, status, documentType);
      } else if (type === 'bidai_update') {
        // Check if we've already processed this same update
        if (lastUpdateRef.current.job_id === job_id && lastUpdateRef.current.status === status) {
          // Same update, do nothing
          return;
        }

        // Update the ref to remember this update
        lastUpdateRef.current = { job_id, status };

        // Now actually trigger a refresh
        triggerRefresh();
      } else if (type === 'report_builder_update') {
        addNotification('Your report is ready for download!', 'success');
        // Now actually trigger a refresh
        if (lastUpdateRef.current.job_id === job_id && lastUpdateRef.current.status === status) {
          // Same update, do nothing
          return;
        }
        
        // Update the ref to remember this update
        lastUpdateRef.current = { job_id, status };

        triggerReportRefresh();
      }
      else if (type === 'error') {
        handleErrorMessage(document_name, status);
      } else {
        console.log('Unknown message type:', type);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage]);

  /**
   * Handles upload status updates from WebSocket messages.
   */
  const handleUploadStatus = (job_id, documentName, status, documentType = 'unknown') => {
    const safeDocumentName = documentName || 'Unknown Document';
    let message = `Document "${safeDocumentName}"'s status has been updated to ${status}`;
    let notificationType;

    switch (status) {
      case 'processing':
        notificationType = 'info';
        break;
      case 'indexed':
        notificationType = 'success';
        break;
      default:
        notificationType = 'info';
    }

    addNotification(message, notificationType);

    // Update the DocumentContext
    if (status === 'processing') {
      // Add the document if it's new
      // addOrUpdateDocument({
      //   document_uuid: job_id, // Ensure this matches your unique identifier
      //   job_id: job_id,
      //   document_name: safeDocumentName,
      //   status,
      //   added_by: userData.user_data.user_id || 'Unknown',
      //   size: 'N/A',
      //   document_type: documentType, // Initially unknown
      //   url: 'none',
      //   org_id: userData.user_data.org_id,
      // });
      navigateToFolder(currentFolder);
    } else if (status === 'document_indexed') {
      // Update the document's status and type
      navigateToFolder(currentFolder);
      // addOrUpdateDocument({
      //   document_uuid: job_id,
      //   status,
      // });
    }
  };


  /**
   * Handles error messages from WebSocket.
   */
  const handleErrorMessage = (documentName, errorMessage) => {
    // Safeguard: Provide a default name if documentName is undefined
    const safeDocumentName = documentName || 'Unknown Document';

    let message = `Error processing "${safeDocumentName}": ${errorMessage}`;
    addNotification(message, 'error');
  };

  return null;
};

/**
 * App Component
 * The root component of the application, managing authentication, routing, and global state.
 */
export default function App() {
  // State variables
  const [databaseList, setDatabaseList] = useState([]);
  const [database, setDatabase] = useState(() => {
    return localStorage.getItem('selectedDatabase') || 'test-base';
  });
  const [documentTitles, setDocumentTitles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [filters, setFilters] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState(() => {
    const savedDocs = localStorage.getItem('selectedDocuments');
    return savedDocs ? JSON.parse(savedDocs) : [];
  });

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [userSessions, setUserSessions] = useState([]);
  const [sessionId, setSessionId] = useState(null);
  const [messages, setMessages] = useState([]);

  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme') || 'light';
    return savedTheme === 'dark';
  });
  const [userData, setUserData] = useState(null);
  const [isUserDataLoading, setIsUserDataLoading] = useState(true);
  const [userDataError, setUserDataError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [tags, setTags] = useState([]);


  /**
   * Toggle sidebar state.
   */
  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  /**
   * Fetch user sessions from the backend.
   */
  const fetchSessions = async () => {
    try {
      const userId = await currentAuthenticatedUser();
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/fetchSessions?userId=${encodeURIComponent(userId)}`);
      if (!response.ok) {
        throw new Error('Failed to fetch sessions');
      }
      const data = await response.json();
      setUserSessions(data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error Fetching Sessions', error);
    }
  };

  useEffect(() => {
    fetchSessions();
  }, []);

  useEffect(() => {
    const fetchTags = async () => {
      if (userData && userData.org_data && userData.org_data.org_id) {
        const orgId = userData.org_data.org_id;
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/tags?org_id=${orgId}`);
        if (response.ok) {
          const data = await response.json();
          console.log('tags:', data.tags);
          setTags(data.tags);
        } else {
          console.error('Failed to fetch tags');
        }
      }
    };

    fetchTags();
  }, [userData]);

  /**
   * Handle theme toggling and persist the preference.
   */
  useEffect(() => {
    document.body.classList.toggle('dark-mode', isDarkMode);
    localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  /**
   * Handle changes to filters.
   */
  const handleFiltersChange = (newFilters) => {
    setFilters(newFilters);
  };

  /**
   * Handle changes to selected documents and persist the selection.
   */
  const handleSelectedDocumentsChange = (newSelectedDocuments) => {
    setSelectedDocuments(newSelectedDocuments);
    localStorage.setItem('selectedDocuments', JSON.stringify(newSelectedDocuments));
  };

  /**
   * Handle changes to document titles.
   */
  const handleDocumentTitlesChange = (newDocumentTitles) => {
    setDocumentTitles(newDocumentTitles);
  };


  /**
   * Handle clicking on a session card to fetch its messages.
   */
  const handleCardClick = async (sessionId) => {
    const sessionMessages = await fetchMessagesForSession(sessionId);
    setSessionId(sessionId);
    setMessages(sessionMessages);
  };

  /**
   * Prevent indefinite loading by setting a timeout.
   */
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isUserDataLoading) {
        setIsUserDataLoading(false);
        setUserDataError("Loading timeout. Please refresh the page.");
        console.log(userData)
      }
    }, 30000); // 30 seconds timeout

    return () => clearTimeout(timeoutId);
  }, [isUserDataLoading]);

  /**
   * Toggle dark mode.
   */
  const toggleDarkMode = () => {
    setIsDarkMode(prevMode => !prevMode);
  };

  /**
   * Load user data, handle caching and retries.
   */
  useEffect(() => {
    const loadUserData = async () => {
      setIsUserDataLoading(true);
      try {
        const cachedData = null;
        const userGroups = await getUserGroups();

        if (cachedData) {
          cachedData.userGroups = userGroups;
          setUserData(cachedData);
          setIsAuthenticated(true);
        } else {
          const freshData = await fetchUserData();
          freshData.userGroups = userGroups;
          if (freshData) {
            setUserData(freshData);
            setIsAuthenticated(true);
          } else {
            throw new Error("Failed to fetch user data");
          }
        }
      } catch (error) {
        console.error('Error loading user data:', error);
        setUserDataError(error.message);
        if (retryCount < 3) {
          setRetryCount(prevCount => prevCount + 1);
          setTimeout(() => loadUserData(), 3000); // Retry after 3 seconds
        }
      } finally {
        setIsUserDataLoading(false);
      }
    };

    loadUserData();
  }, [retryCount]);

  return (
    <NotificationProvider>
      <ReportProvider>
        <BidAiProvider>
          <Notification />
          <ErrorHandlerComponent />
          <MsalProvider instance={msalInstance}>
            <ThemeProvider theme={myTheme}> {/* Apply the custom theme here */}
              <Authenticator
                components={customAuthenticatorComponents}
                hideSignUp={true}
              >
                {({ signOut, user }) => (
                  <Router>
                    <DocumentProvider userData={userData}>
                      {isAuthenticated && userData && (
                        <WebSocketHandler userData={userData} />
                      )}
                      <main className='app-container'>
                        {isAuthenticated && userData ? (
                          <>
                            <div className='top-bar-container'>
                              <div className={`corner-logo-block ${sidebarCollapsed ? 'collapsed' : ''}`}>
                                <div className="sidebar-header">
                                  <motion.div className='argus-logo-placeholder'>
                                    {sidebarCollapsed ?
                                      <img src={ArgusLogo} alt="Argus Logo" className="argus-logo" />
                                      :
                                      <span className="topbar-company-name">
                                        {userData.org_data.name}
                                      </span>}
                                  </motion.div>
                                </div>
                              </div>
                              <TopBar
                                toggleSidebar={toggleSidebar}
                                userDisplayName={userData.user_data.name || 'User'}
                                companyName={userData.org_data.name || 'Company'}
                                userData={userData}
                                className='top-bar'
                              />
                            </div>
                            <div className={`top-bar-and-page-content ${sidebarCollapsed ? 'collapsed' : ''}`}>
                              <Sidebar sidebarCollapsed={sidebarCollapsed} userData={userData} />
                              <Routes>
                                <Route path='/document/:documentId' element={<DocumentPage userData={userData} />} />
                                <Route path='/' element={<Chat filters={filters} userData={userData} tags={tags} />} />
                                <Route path='/search' element={<SemanticSearch userData={userData} />} />
                                {/* Conditionally render Admin route */}
                                {userData?.userGroups?.includes('OrgAdmin') && (
                                  <Route path='/admin' element={<AdminPage userData={userData} />} />
                                )}
                                <Route path='/files' element={<FileExplorer userId={user.userId} userData={userData} />} />
                                <Route path='/email-connected' element={<EmailList userData={userData} />} />
                                <Route path='/recruit-ai' element={<RecruitAIDashboard userData={userData} />} />
                                <Route path='report-builder' element={<ReportBuilderDashboard userData={userData} />} />
                                <Route
                                  path='/email-test'
                                  element={
                                    <MsalProvider instance={msalInstance}>
                                      <EmailList />
                                    </MsalProvider>
                                  }
                                />
                                <Route
                                  path='settings'
                                  element={<SettingsPage userData={userData} />}
                                />
                                <Route
                                  path='/bidai'
                                  element={<BidAI userData={userData} />}
                                />
                                <Route
                                  path='/report-builder-beta'
                                  element={<ReportBuilder userData={userData} />}
                                />
                                <Route
                                  path='/user-settings'
                                  element={
                                    <UserSettings
                                      userData={userData}
                                    />
                                  }
                                />
                              </Routes>
                            </div>
                          </>
                        ) : isUserDataLoading ? (
                          <LoadingComponent />
                        ) : (
                          <div>
                            <LoadingComponent />
                          </div>
                        )}
                      </main>
                    </DocumentProvider>
                  </Router>
                )}
              </Authenticator>
            </ThemeProvider>
          </MsalProvider>
        </BidAiProvider>
      </ReportProvider>
    </NotificationProvider>
  );
}