// src/theme.js

import { createTheme } from '@aws-amplify/ui-react';

export const myTheme = createTheme({
  name: 'my-custom-theme',
  tokens: {
    colors: {
      brand: {
        primary: {
          10: '#f0f4ff', // Lightest blue
          80: '#0068d7', // Primary blue
          90: '#0056b3', // Darker blue
        },
        background: {
          primary: '#ffffff', // White background
          secondary: '#f5f5f5', // Light gray
        },
        text: {
          primary: '#000000', // Black text
          secondary: '#333333', // Dark gray text
        },
      },
    },
    components: {
      // Customize specific components
      authenticator: {
        container: {
          borderRadius: '12px', // Adjust to desired corner rounding
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)', // optional
        },
        formSection: {
          borderRadius: '12px', // Additional rounding inside form if needed
        }
      },
      Button: {
        primary: {
          backgroundColor: 'var(--amplify-colors-brand-primary-80)',
          color: 'var(--amplify-colors-text-primary)',
          _hover: {
            backgroundColor: 'var(--amplify-colors-brand-primary-90)',
          },
        },
      },
      // Add more component customizations as needed
    },
  },
});
