// Sidebar.js
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
    FaSearch, FaComments, FaUpload, FaFolder, FaUsers, FaSignOutAlt,
    FaFileContract, FaIdBadge, FaFileExport, FaPaperPlane
} from 'react-icons/fa';  // Import an icon for the feedback button
import { signOut } from '@aws-amplify/auth';
import FeedbackModal from './FeebackModal';
import './sidebar.css';
import axios from 'axios';

const Sidebar = ({ sidebarCollapsed, userData }) => {
    const location = useLocation();
    const navigate = useNavigate();

    // 1. State to control whether the feedback modal is open
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);

    const handleLogout = async () => {
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + '/logout',
                {
                    'org_id': userData.user_data.org_id,
                    'user_id': userData.user_data.user_id
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.status !== 200) {
                console.error('Error logging out: ', response);
                return;
            }
            await signOut();
            window.location.href = 'https://useargus.com';
        } catch (error) {
            console.error('Error signing out: ', error);
        }
    };

    const isOrgAdmin = userData?.userGroups?.includes('OrgAdmin');

    const menuItems = [
        { path: '/', icon: FaComments, label: 'Assistant' },
        { path: '/search', icon: FaSearch, label: 'Search' },
        { path: '/files', icon: FaFolder, label: 'Files' },
        ...(isOrgAdmin ? [{ path: '/admin', icon: FaUsers, label: 'Admin' }] : []),
        { path: '/bidai', icon: FaFileContract, label: 'BidAI' },
        { path: '/recruit-ai', icon: FaIdBadge, label: 'RecruitAI' },
        { path: '/report-builder', icon: FaFileExport, label: 'Report Builder' },
        { path: '/report-builder-beta', icon: FaFileExport, label: 'Report Builder Beta' },
    ];

    return (
        <motion.nav
            className={`sidebar ${sidebarCollapsed ? 'collapsed' : ''}`}
            initial={false}
            animate={{ width: sidebarCollapsed ? '5vw' : '15vw' }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
        >


            <div className='sidebar-menu'>
                {menuItems.map((item) => (
                    <motion.div
                        key={item.path}
                        className={`sidebar-menu-item ${location.pathname === item.path ? 'selected' : ''}`}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        <Link to={item.path} className='sidebar-link'>
                            <item.icon className='sidebar-icon' />
                            {!sidebarCollapsed && <span>{item.label}</span>}
                        </Link>
                    </motion.div>
                ))}
            </div>

            <div className='sidebar-footer'>
                <motion.div
                    className="sidebar-logout"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                >
                    <button onClick={handleLogout} className="logout-button">
                        <FaSignOutAlt className="sidebar-icon" />
                        {!sidebarCollapsed && <span>Logout</span>}
                    </button>
                </motion.div>
                {/* Feedback button */}
                <motion.div
                    className="sidebar-feedback"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                >
                    <button
                        onClick={() => setShowFeedbackModal(true)}
                        className="feedback-button"
                    >
                        <FaPaperPlane className="sidebar-icon" />
                        {!sidebarCollapsed && <span>Send Feedback</span>}
                    </button>
                </motion.div>
            </div>
            {/* Render the Feedback Modal */}
            {showFeedbackModal && (
                <FeedbackModal
                    onClose={() => setShowFeedbackModal(false)}
                />
            )}
        </motion.nav>
    );
};

export default Sidebar;
