function processMessages(data) {
    // Assuming data is the array of dictionaries you fetched
    return data.map((item) => [
      { id: `q-${item.timestamp}`, text: item.question, sender: 'me' },
      { id: `r-${item.timestamp}`, text: item.response, sender: 'bot' },
    ]).flat(); // Flattens the array of arrays into a single array
  }

const fetchMessagesForSession = async (sessionId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_GET_SESSION_MESSAGES}?sessionId=${encodeURIComponent(sessionId)}`);
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      const data = await response.json();
      const processedMessages = processMessages(data['0']['messages']);
      console.log(data);
      return processedMessages; // Assuming the API returns a JSON object with a messages array
    } catch (error) {
      console.error('There was an error fetching the messages:', error);
    }
  };

export default fetchMessagesForSession;