// src/components/customAuthenticatorComponents.js

import React from 'react';
import { View, Image, Text, Heading, Button, useTheme } from '@aws-amplify/ui-react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import ArgusLogo from './assets/Argus.png'; // Adjust path based on your folder structure

const CustomHeader = () => {
  const { tokens } = useTheme();

  return (
    <View textAlign="center" padding={tokens.space.large}>
      <Image
        alt="Argus Logo"
        src={ArgusLogo} // Replace with your logo URL
        style={{ width: '250px' }}
      />
    </View>
  );
};

const CustomFooter = () => {
  const { tokens } = useTheme();

  return (
    <View textAlign="center" padding={tokens.space.large}>
      <Text color={tokens.colors.neutral[80]}>
        &copy; {new Date().getFullYear()} Argus. All Rights Reserved.
      </Text>
    </View>
  );
};

const CustomSignInHeader = () => {
  const { tokens } = useTheme();

  return (
    <Heading
      padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
      level={3}
      color="#333333"
      style={{ fontWeight: 'bold', fontSize: '24px', textAlign: 'center' }}
    >
      Welcome to Argus
    </Heading>
  );
};

const CustomSignInFooter = () => {
  const { toForgotPassword } = useAuthenticator();

  return (
    <View textAlign="center">
      <Button
        fontWeight="normal"
        onClick={toForgotPassword}
        size="small"
        variation="link"
      >
        Forgot Password?
      </Button>
    </View>
  );
};

const CustomSignUpHeader = () => {
  const { tokens } = useTheme();

  return (
    <Heading
      padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
      level={3}
      color="#333333"
    >
      Create a new Argus account
    </Heading>
  );
};

const CustomSignUpFooter = () => {
  const { toSignIn } = useAuthenticator();

  return (
    <View textAlign="center">
      <Button
        fontWeight="normal"
        onClick={toSignIn}
        size="small"
        variation="link"
      >
        Back to Sign In
      </Button>
    </View>
  );
};

const CustomConfirmSignUpHeader = () => {
  const { tokens } = useTheme();

  return (
    <Heading
      padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
      level={3}
      color="#333333"
    >
      Confirm your Argus account
    </Heading>
  );
};

const CustomConfirmSignUpFooter = () => (
  <Text textAlign="center" color="#555555">
    Please check your email for the confirmation code.
  </Text>
);

// Define other states as needed (SetupTotp, ConfirmSignIn, etc.)

export const customAuthenticatorComponents = {
  Header: CustomHeader,
  Footer: CustomFooter,
  SignIn: {
    Header: CustomSignInHeader,
    Footer: CustomSignInFooter,
  },
  SignUp: {
    Header: CustomSignUpHeader,
    Footer: CustomSignUpFooter,
  },
  ConfirmSignUp: {
    Header: CustomConfirmSignUpHeader,
    Footer: CustomConfirmSignUpFooter,
  },
  // Add other customizations like SetupTotp, ConfirmSignIn, ForgotPassword, etc.
};
