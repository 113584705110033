// src/components/documentPage/DocumentPage.js
import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Download, RotateCcw, Trash2 } from 'lucide-react'; // Add these imports
import LoadingSpinner from '../components/assets/loadingSpinner';
import './DocumentPage.css';
import { useNotification } from '../components/useNotification';
import { getIdToken } from '../services/userDataService';
import ChunksModal from './modals/ChunksModal';
import { AiOutlineClose } from 'react-icons/ai';
import XLSXViewer from '../components/fileExplorer/xlsxViewer';

/**
 * DocumentPage Component
 * Displays all versions and chunk statuses of a selected document.
 */
const DocumentPage = (userData) => {
  const { documentId } = useParams(); // Get document ID from URL
  const navigate = useNavigate();
  const { addNotification } = useNotification();

  const [versions, setVersions] = useState([]);
  const [documentDetails, setDocumentDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Modal States
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chunks, setChunks] = useState([]);
  const [isChunksLoading, setIsChunksLoading] = useState(false);
  const [chunksError, setChunksError] = useState(null);

  const [viewerUrl, setViewerUrl] = useState(null);
  const [viewerType, setViewerType] = useState(null);

  // Reference to the "View Chunk Data" button for focus management
  const viewChunkButtonRef = useRef(null);

  console.log(userData);
  useEffect(() => {
    // Fetch document details and versions
    const fetchDocumentData = async () => {
      try {
        const token = await getIdToken(); // Ensure this function is accessible or import it
        // Fetch document details
        const docResponse = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/document/${documentId}`, // Replace with your API endpoint
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        setDocumentDetails(docResponse.data.document); // Adjust based on your API response structure
        console.log(docResponse.data.document);

        // Fetch all versions of the document
        const versionsResponse = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/document/${documentId}/versions`, // Replace with your API endpoint
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        setVersions(versionsResponse.data.versions); // Adjust based on your API response structure
      } catch (err) {
        console.error('Error fetching document data:', err);
        setError('Failed to load document data.');
        addNotification('Failed to load document data.', 'error');
      } finally {
        setIsLoading(false);
      }
    };

    fetchDocumentData();
  }, [documentId, addNotification]);


  const renderViewer = () => {
    console.log('Rendering viewer with:', { viewerUrl, viewerType });
    if (error) {
      return <div className="viewer-error">Error: {error}</div>;
    }
    switch (viewerType) {
      case 'xlsx':
      case 'xls':
        return <XLSXViewer fileUrl={viewerUrl} />;
      case 'pdf':
      case 'docx':
      case 'doc':
      default:
        return (
          <iframe
            src={viewerUrl}
            title="Document Viewer"
            className="viewer-iframe"
            onLoad={() => console.log('iframe loaded')}
            onError={(e) => console.error('iframe error:', e)}
          />
        );
    }
  };

  /**
   * Handle downloading a specific version
   */
  const handleDownloadVersion = async (versionNumber) => {
    try {
      console.log('Downloading version:', versionNumber);
      const token = await getIdToken();
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + `/document/download?document_uuid=${versionNumber.document_uuid}&org_id=${versionNumber.org_id}`, // Replace with your API endpoint
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { presignedUrl, documentType } = response.data; // Adjust based on your API response

      // Initiate download
      const link = document.createElement('a');
      link.href = presignedUrl;
      link.download = `${documentDetails.document_name}_v${versionNumber}.${getFileExtension(documentType)}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error('Error downloading version:', err);
      addNotification('Failed to download document version.', 'error');
    }
  };


  /**
   * Helper function to determine file extension from document type
   */
  const getFileExtension = (documentType) => {
    const mimeToExt = {
      pdf: 'pdf',
      docx: 'docx',
      xlsx: 'xlsx',
      // Add more as needed
    };
    return mimeToExt[documentType.toLowerCase()] || 'bin';
  };

  /**
   * Handle reverting to a specific version
   */
  const handleRevertVersion = async (versionNumber) => {
    try {
      const token = await getIdToken();
      const response = await axios.post(
        `https://your-backend-api.com/api/documents/${documentId}/versions/${versionNumber}/revert`, // Replace with your API endpoint
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        addNotification(`Document reverted to version ${versionNumber} successfully.`, 'success');
        // Optionally, refresh the versions list
        setIsLoading(true);
        setVersions([]);
        setDocumentDetails(null);
        // Re-fetch data
        const fetchDocumentData = async () => {
          try {
            const token = await getIdToken();
            const docResponse = await axios.get(
              `https://your-backend-api.com/api/documents/${documentId}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              }
            );

            setDocumentDetails(docResponse.data.document);
            console.log(docResponse.data.document);

            const versionsResponse = await axios.get(
              `https://your-backend-api.com/api/documents/${documentId}/versions`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              }
            );

            setVersions(versionsResponse.data.versions);
          } catch (err) {
            console.error('Error fetching document data:', err);
            setError('Failed to load document data.');
            addNotification('Failed to load document data.', 'error');
          } finally {
            setIsLoading(false);
          }
        };

        fetchDocumentData();
      } else {
        throw new Error('Failed to revert document version.');
      }
    } catch (err) {
      console.error('Error reverting version:', err);
      addNotification('Failed to revert document version.', 'error');
    }
  };

  /**
 * Open Modal to View Chunk Data
 */
  const openChunkModal = async () => {
    setIsModalOpen(true);
    setIsChunksLoading(true);
    setChunksError(null);
    try {
      const token = await getIdToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/document/${documentId}/chunks`, // Replace with your API endpoint
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setChunks(response.data.chunks); // Adjust based on your API response structure
      console.log(response.data.chunks);
    } catch (err) {
      console.error('Error fetching chunks:', err);
      setChunksError('Failed to load chunk data.');
      addNotification('Failed to load chunk data.', 'error');
    } finally {
      setIsChunksLoading(false);
    }
  };

  const handleEnableBidAI = async (newStatus) => {
    try {
      const token = await getIdToken();
      console.log(userData.userData.user_data.org_id);
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/orgs/${userData.userData.user_data.org_id}/documents/${documentId}/updateBidAIElegibility`, // Replace with your API endpoint
        { isBidAIEligible: newStatus },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        if (newStatus) {
          addNotification('BidAI enabled successfully.', 'success');
        } else {
          addNotification('BidAI disabled successfully.', 'success');
        }
        // Optionally, refresh the versions list
        setIsLoading(true);
        setVersions([]);
        setDocumentDetails(null);
        // Re-fetch data
        const fetchDocumentData = async () => {
          try {
            const token = await getIdToken();
            const docResponse = await axios.get(
              `${process.env.REACT_APP_BASE_URL}/document/${documentId}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              }
            );

            setDocumentDetails(docResponse.data.document);
            console.log(docResponse.data.document);

            const versionsResponse = await axios.get(
              `${process.env.REACT_APP_BASE_URL}/document/${documentId}/versions`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              }
            );

            setVersions(versionsResponse.data.versions);
          } catch (err) {
            console.error('Error fetching document data:', err);
            setError('Failed to load document data.');
            addNotification('Failed to load document data.', 'error');
          } finally {
            setIsLoading(false);
          }
        };

        fetchDocumentData();
      } else {
        throw new Error('Failed to enable BidAI.');
      }
    } catch (err) {
      console.error('Error enabling BidAI:', err);
      addNotification('Failed to enable BidAI.', 'error');
    }
  }

  /**
* Close Chunk Modal
*/
  const closeChunkModal = () => {
    setIsModalOpen(false);
    setChunks([]);
    setChunksError(null);
    // Return focus to the "View Chunk Data" button
    if (viewChunkButtonRef.current) {
      viewChunkButtonRef.current.focus();
    }
  };

  /**
 * Handle viewing a document
 */
  const handleView = (url, type) => {
    callViewFunction(url, type);
  };

  /**
   * Call the view function to fetch the presigned URL and set viewer state
   */
  const callViewFunction = async (url, documentType) => {
    console.log('Calling view function with:', { url, documentType });
    try {
      const encodedUrl = encodeURIComponent(url);
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/inClientViewer?file_url=${encodedUrl}`, {}, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await getIdToken()}`,
        },
      });

      const parsed_data = response.data;
      console.log('Parsed data:', parsed_data);

      if (parsed_data.presigned_url) {
        setViewerUrl(parsed_data.presigned_url);
        setViewerType(documentType);
        console.log('Set viewer URL and type:', { url: parsed_data.presigned_url, type: documentType });
      } else {
        throw new Error('No URL found in the response');
      }
    } catch (error) {
      console.error('Error with fetch:', error);
      addNotification('Failed to load viewer', 'error');
    }
  };


  /**
   * Handle deleting a specific version
   */
  const handleDeleteVersion = async (versionNumber) => {
    if (!window.confirm(`Are you sure you want to delete version ${versionNumber}? This action cannot be undone.`)) {
      return;
    }

    try {
      const token = await getIdToken();
      const response = await axios.delete(
        `https://your-backend-api.com/api/documents/${documentId}/versions/${versionNumber}`, // Replace with your API endpoint
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        addNotification(`Version ${versionNumber} deleted successfully.`, 'success');
        // Remove the deleted version from state
        setVersions((prevVersions) => prevVersions.filter((v) => v.version_number !== versionNumber));
      } else {
        throw new Error('Failed to delete document version.');
      }
    } catch (err) {
      console.error('Error deleting version:', err);
      addNotification('Failed to delete document version.', 'error');
    }
  };

  if (isLoading) {
    return (
      <div className="document-page-loading">
        <LoadingSpinner />
        <span>Loading document details...</span>
      </div>
    );
  }

  if (error) {
    return <div className="document-page-error">{error}</div>;
  }

  return (
    <div className="document-page-container">
      <button onClick={() => navigate('/files')} className="back-button">
        &larr; Back to Files
      </button>
      <h2>Document Details</h2>
      {documentDetails ? (
        <div className="document-info-container">
          <table className="details-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Current Version</th>
                <th>BidAI Eligible</th>
                <th>Created On</th>
                <th>Size</th>
                <th>Uploaded By</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{documentDetails.document_name}</td>
                <td>{documentDetails.document_type}</td>
                <td>{documentDetails.version}</td>
                <td>{documentDetails.isbidaieligible ? 'True' : 'False'}</td>
                <td>{new Date(documentDetails.date_added).toLocaleString()}</td>
                <td>{documentDetails.size ? `${Math.round(documentDetails.size / 1024)} KB` : 'N/A'}</td>
                <td>{documentDetails.added_by_name || 'N/A'}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <p>No document details available.</p>
      )}

      <div className="chunk-data-container">
        <button
          className="document-info-label button-link"
          onClick={openChunkModal}
          ref={viewChunkButtonRef}
        >
          View Chunk Data
        </button>
        <button
          className='document-info-label button-link'
          onClick={() => handleView(documentDetails.url, documentDetails.document_type)}
        >
          Open Document
        </button>
        <button
          className={`document-info-bidai-label ${documentDetails.isbidaieligible ? 'disable-bidai-btn' : 'enable-bidai-btn'
            }`}
          onClick={() => handleEnableBidAI(!documentDetails.isbidaieligible)}
          ref={viewChunkButtonRef}
        >
          {documentDetails.isbidaieligible ? 'Disable BidAI' : 'Enable BidAI'}
        </button>
      </div>
      <h3>Versions</h3>
      {versions.length === 0 ? (
        <p>No versions available for this document.</p>
      ) : (
        <div className="versions-container">
          <table className="versions-table">
            <thead>
              <tr>
                <th>Version</th>
                <th>Uploaded By</th>
                <th>Upload Date</th>
                <th>Document Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {versions.map((version) => (
                <tr
                  key={version.version}
                  className={`version-row ${version.version === documentDetails.version ? 'current-version-row' : ''}`}
                >
                  <td
                    onClick={version.version !== documentDetails.version ?
                      () => navigate(`/document/${version.document_uuid}`) :
                      undefined
                    }
                    className={`version-cell ${version.version === documentDetails.version ? 'current-version' : ''}`}
                  >
                    {version.version}
                  </td>
                  <td>{version.added_by_name || 'N/A'}</td>
                  <td>{new Date(version.date_added).toLocaleString()}</td>
                  <td>
                    {version.status === 'indexed' ? (
                      <span className="status-complete">Indexed</span>
                    ) : (
                      <span className="status-incomplete">
                        {version.status}
                      </span>
                    )}
                  </td>
                  <td>
                    <div className="action-buttons">
                      <button
                        onClick={() => handleDownloadVersion(version)}
                        className="document-icon-button"
                        title="Download version"
                        aria-label={`Download version ${version.version}`}
                      >
                        <Download className='documents-page-icon' />
                      </button>
                      <button
                        onClick={() => handleRevertVersion(version.version)}
                        className="document-icon-button revert"
                        title="Revert to this version"
                        aria-label={`Revert to version ${version.version}`}
                        disabled={true}
                      >
                        <RotateCcw className='documents-page-icon' />
                      </button>
                      <button
                        onClick={() => handleDeleteVersion(version.version)}
                        className="document-icon-button delete"
                        title="Delete version"
                        aria-label={`Delete version ${version.version}`}
                      >
                        <Trash2 className='documents-page-icon' />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Document Viewer Modal */}
      {viewerUrl && (
        <div className="fullscreen-viewer">
          <div className="viewer-header">
            <button
              className="close-button"
              onClick={() => {
                setViewerUrl(null);
                setViewerType(null);
              }}
              aria-label="Close Viewer"
            >
              <AiOutlineClose />
            </button>
          </div>
          <div className="viewer-content">{renderViewer()}</div>
        </div>
      )}

      {/* Chunks Modal */}
      <ChunksModal
        isOpen={isModalOpen}
        onClose={closeChunkModal}
        chunks={chunks}
        isLoading={isChunksLoading}
        error={chunksError}
        documentId={documentId}
        userData={userData}
      />

    </div>
  );
};

export default DocumentPage;
