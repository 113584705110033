import React, { useState, useEffect } from 'react';
import './candidates.css';
import { getIdToken } from '../../services/userDataService';
import { useNotification } from '../../components/useNotification';

const Candidates = () => {
    const [candidateList, setCandidateList] = useState([]);

    const { addNotification } = useNotification();

    const getResumes = async () => {
        const token = await getIdToken();

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/fetchResumeCandidates`, {
                method: 'GET',
                headers: {
                    'Authorization': token,
                },
            });

            if (response.ok) {
                const result = await response.json();
                console.log('Fetched candidates:', result);
                setCandidateList(result);
            } else {
                console.error('Failed to fetch candidates');
            }
        } catch (error) {
            console.error('Error fetching candidates:', error);
        }
    }

    useEffect(() => {
        getResumes();
    }, []);

    return (
        <div className='candidates-main-area'>
            <h2>Candidates</h2>
            {candidateList.length > 0 ? (
                <ul className='candidate-list'>
                    {candidateList.map(candidate => (
                        <li key={candidate.applicantId} className='candidate-item'>
                            <div className='candidate-icon'>👤</div>
                            <div className='candidate-info'>
                                <div className='candidate-name'>{candidate.parsedData.name}</div>
                                <div className='candidate-sub-info'>
                                    Last Position: {candidate.parsedData.work_experience[0].position}
                                </div>
                            </div>
                            {/* <div className='candidate-hover'>
                                <p>{candidate.parsedData.personal_summary}</p>
                                <button onClick={() => viewMore(candidate)}>View More</button>
                            </div> */}
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No candidates available yet.</p>
            )}
        </div>
    );
};

const viewMore = (candidate) => {
    alert(JSON.stringify(candidate, null, 2)); // Replace with a modal or popup
};

export default Candidates;
