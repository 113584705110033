import React, { useEffect, useState, useCallback } from 'react';
import { 
    FolderIcon, 
    FileIcon, 
    ChevronLeftIcon, 
    XIcon,
    CheckCircleIcon,
    ChevronRightIcon,
    HomeIcon
} from 'lucide-react';
import './GoogleDriveModal.css';

const GOOGLE_CLIENT_ID = '44517948580-v78sv71ahej13lhm4n00c57ilt3116im.apps.googleusercontent.com';
const API_SCOPE = 'https://www.googleapis.com/auth/drive.readonly';


const Breadcrumbs = ({ pathStack, onNavigate }) => {
    if (!pathStack.length) return null;

    return (
        <div className="breadcrumbs-container">
            <button 
                className="breadcrumb-item home"
                onClick={() => onNavigate(0)}
                title="My Drive"
            >
                <HomeIcon size={16} />
                <span>My Drive</span>
            </button>
            
            {pathStack.slice(1).map((folder, index) => (
                <React.Fragment key={folder.id}>
                    <ChevronRightIcon size={16} className="breadcrumb-separator" />
                    <button
                        className="breadcrumb-item"
                        onClick={() => onNavigate(index + 1)}
                        title={folder.name}
                    >
                        <span>{folder.name}</span>
                    </button>
                </React.Fragment>
            ))}
        </div>
    );
};

const GoogleDriveModal = ({ isOpen, onClose, onSelect }) => {
    const [accessToken, setAccessToken] = useState(null);
    const [items, setItems] = useState([]);
    const [currentPath, setCurrentPath] = useState('root');
    const [pathStack, setPathStack] = useState([{ id: 'root', name: 'My Drive' }]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedItems, setSelectedItems] = useState(new Set());
    const [isInitializing, setIsInitializing] = useState(true);

    // Initialize Google APIs and attempt sign-in
    useEffect(() => {
        if (!isOpen) return;

        const initializeGoogleAPIs = async () => {
            setIsInitializing(true);
            setLoading(true);
            
            try {
                // Load Google Identity Services
                await new Promise((resolve, reject) => {
                    const script = document.createElement('script');
                    script.src = 'https://accounts.google.com/gsi/client';
                    script.onload = resolve;
                    script.onerror = reject;
                    document.body.appendChild(script);
                });

                // Load Google API Client
                await new Promise((resolve, reject) => {
                    const script = document.createElement('script');
                    script.src = 'https://apis.google.com/js/api.js';
                    script.onload = resolve;
                    script.onerror = reject;
                    document.body.appendChild(script);
                });

                // Initialize GAPI client
                await new Promise((resolve) => window.gapi.load('client', resolve));
                await window.gapi.client.init({});
                await window.gapi.client.load('drive', 'v3');

                // Auto-attempt sign in
                const tokenClient = window.google.accounts.oauth2.initTokenClient({
                    client_id: GOOGLE_CLIENT_ID,
                    scope: API_SCOPE,
                    callback: (response) => {
                        if (response.access_token) {
                            setAccessToken(response.access_token);
                            window.gapi.client.setToken({ access_token: response.access_token });
                        }
                        setLoading(false);
                    },
                    error_callback: (err) => {
                        console.error('Auth error:', err);
                        setLoading(false);
                    }
                });

                // Attempt immediate sign-in
                tokenClient.requestAccessToken({ prompt: '' });
            } catch (err) {
                console.error('Error initializing Google APIs:', err);
                setError('Failed to initialize Google Drive connection');
                setLoading(false);
            } finally {
                setIsInitializing(false);
            }
        };

        initializeGoogleAPIs();
    }, [isOpen]);


    const navigateToBreadcrumb = (index) => {
        const newStack = pathStack.slice(0, index + 1);
        setPathStack(newStack);
        const targetFolder = newStack[newStack.length - 1];
        setCurrentPath(targetFolder.id);
        fetchItems(targetFolder.id);
    };

    // Fetch items when access token is available
    useEffect(() => {
        if (accessToken && !isInitializing) {
            fetchItems('root');
        }
    }, [accessToken, isInitializing]);

    const fetchItems = async (folderId) => {
        if (!accessToken || !window.gapi?.client?.drive) return;
        setLoading(true);
        setError(null);

        try {
            const response = await window.gapi.client.drive.files.list({
                q: `'${folderId}' in parents and trashed = false`,
                fields: 'files(id, name, mimeType, size)',
                orderBy: 'folder,name',
                pageSize: 100
            });

            const files = response.result.files.map(file => ({
                id: file.id,
                name: file.name,
                isFolder: file.mimeType === 'application/vnd.google-apps.folder',
                size: file.size,
                mimeType: file.mimeType
            }));

            setItems(files);
        } catch (err) {
            console.error('Error fetching files:', err);
            setError('Failed to fetch Google Drive items');
        } finally {
            setLoading(false);
        }
    };

    const handleSelect = (item) => {
        if (item.isFolder) {
            navigateToFolder(item);
        } else {
            setSelectedItems(prev => {
                const newSet = new Set(prev);
                if (newSet.has(item.id)) {
                    newSet.delete(item.id);
                } else {
                    newSet.add(item.id);
                }
                return newSet;
            });
        }
    };

    const navigateToFolder = (folder) => {
        setPathStack(prev => [...prev, folder]);
        setCurrentPath(folder.id);
        fetchItems(folder.id);
    };

    const navigateUp = () => {
        if (pathStack.length <= 1) return;
        
        const newStack = [...pathStack];
        newStack.pop();
        setPathStack(newStack);
        
        const previousFolder = newStack[newStack.length - 1];
        setCurrentPath(previousFolder.id);
        fetchItems(previousFolder.id);
    };

    const handleUpload = async () => {
        const selectedFiles = items.filter(item => selectedItems.has(item.id));
        onSelect(selectedFiles, accessToken);
        setSelectedItems(new Set());
    };

    if (!isOpen) return null;

    return (
        <div className="google-drive-modal-overlay">
            <div className="google-drive-modal">
                <div className="modal-header">
                    <h2>Select from Google Drive</h2>
                    <button onClick={onClose} className="close-button">
                        <XIcon className="icon" />
                    </button>
                </div>

                {loading && isInitializing ? (
                    <div className="google-modal-content">
                        <div className="loading-container">
                            <div className="loading-spinner"></div>
                            <p>Connecting to Google Drive...</p>
                        </div>
                    </div>
                ) : error ? (
                    <div className="google-modal-content">
                        <div className="error-container">
                            <p className="error-message">{error}</p>
                            <button 
                                onClick={() => window.location.reload()}
                                className="retry-button"
                            >
                                Retry
                            </button>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="modal-navigation">
                            <Breadcrumbs 
                                pathStack={pathStack} 
                                onNavigate={navigateToBreadcrumb}
                            />
                        </div>

                        <div className="google-modal-content">
                            {loading ? (
                                <div className="loading-container">
                                    <div className="loading-spinner"></div>
                                </div>
                            ) : items.length === 0 ? (
                                <div className="empty-message">No items found</div>
                            ) : (
                                <div className="items-grid">
                                    {items.map((item) => (
                                        <div
                                            key={item.id}
                                            onClick={() => handleSelect(item)}
                                            className={`item ${item.isFolder ? 'folder' : 'file'} ${selectedItems.has(item.id) ? 'selected' : ''}`}
                                        >
                                            {item.isFolder ? (
                                                <FolderIcon className="icon folder-icon" />
                                            ) : (
                                                <FileIcon className="icon file-icon" />
                                            )}
                                            <span className="item-name">{item.name}</span>
                                            {!item.isFolder && selectedItems.has(item.id) && (
                                                <CheckCircleIcon className="icon check-icon" />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        <div className="modal-footer">
                            <span className="selected-count">
                                {selectedItems.size} items selected
                            </span>
                            <button
                                onClick={handleUpload}
                                disabled={selectedItems.size === 0}
                                className={`upload-button ${selectedItems.size === 0 ? 'disabled' : ''}`}
                            >
                                Upload Selected Files
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default GoogleDriveModal;