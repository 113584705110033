import React, { useState } from 'react';
import './MoveModal.css'; // Create a CSS file as needed

const MoveModal = ({ isOpen, onClose, folders, onMove }) => {
    const [selectedFolder, setSelectedFolder] = useState(null);

    // Build a folder tree or at least show a list of folders
    // Assuming `folders` is a flat array with {id, name, parent_id}
    // We'll create a recursive component to display hierarchy.
    console.log(folders);

    const renderFolderTree = (parentId = null, level = 0) => {
        return folders
            .filter(folder => folder.parent_folder_id === parentId)
            .map(folder => (
                <div key={folder.id} style={{ marginLeft: level * 20 }}>
                    <label>
                        <input
                            type="radio"
                            name="targetFolder"
                            value={folder.id}
                            checked={selectedFolder === folder.id}
                            onChange={() => setSelectedFolder(folder.id)}
                        />
                        {folder.name}
                    </label>
                    {renderFolderTree(folder.id, level + 1)}
                </div>
            ));
    };

    return (
        isOpen && (
            <div className="modal-overlay">
                <div className="modal">
                    <h2>Select a Folder to Move Files Into</h2>
                    <div className="folder-list">
                        {/* If you want a "root" option: */}
                        <label>
                            <input
                                type="radio"
                                name="targetFolder"
                                value=""
                                checked={selectedFolder === null}
                                onChange={() => setSelectedFolder(null)}
                            />
                            Root
                        </label>
                        {renderFolderTree(null, 1)}
                    </div>
                    <div className="modal-buttons">
                        <button
                            onClick={() => {
                                onMove(selectedFolder);
                            }}
                            disabled={selectedFolder === null} // or allow null if root is allowed
                        >
                            Move Here
                        </button>
                        <button onClick={onClose}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    );
};

export default MoveModal;
