import React, { useState, useEffect } from 'react';
import { Cloud, Mail, Database } from 'lucide-react';
import './userSettings.css';

const SettingsPage = ({ userData }) => {  // Destructure the prop
    const [activeTab, setActiveTab] = useState('connections');
    const [isConnecting, setIsConnecting] = useState(false);
    const [connectedServices, setConnectedServices] = useState({
        onedrive: false
    });

    const MICROSOFT_OAUTH_URL = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';
    const CLIENT_ID = 'f22a28b7-d656-4a1d-a9d1-a34aecd2e9c6';
    const REDIRECT_URI = 'http://localhost:3001/user-settings';


    console.log('User data:', userData);
    // // Handle OAuth callback
    // const handleOAuthCallback = async (code) => {
    //     const codeVerifier = sessionStorage.getItem('pkce_verifier');
    //     if (!codeVerifier) {
    //         console.error('No code verifier found');
    //         return;
    //     }

    //     setIsConnecting(true);

    //     try {
    //         const response = await fetch('https://jbuyfsfyd0.execute-api.ca-central-1.amazonaws.com/default/onedrive/auth', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify({
    //                 code,
    //                 code_verifier: codeVerifier,
    //                 org_id: userData?.user_data?.org_id
    //             })
    //         });

    //         const data = await response.json();
    //         if (data.success) {
    //             setConnectedServices(prev => ({ ...prev, onedrive: true }));
    //             console.log('OneDrive connected successfully');
    //         } else {
    //             throw new Error(data.error || 'Failed to connect to OneDrive');
    //         }
    //     } catch (error) {
    //         console.error('Error connecting OneDrive:', error);
    //         // Could add error notification here
    //     } finally {
    //         setIsConnecting(false);
    //         sessionStorage.removeItem('pkce_verifier');
    //         window.history.pushState({}, '', window.location.pathname);
    //     }
    // };

    function generateCodeVerifier() {
        const array = new Uint8Array(32);
        window.crypto.getRandomValues(array);
        return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
    }

    async function generateCodeChallenge(verifier) {
        const encoder = new TextEncoder();
        const data = encoder.encode(verifier);
        const hash = await window.crypto.subtle.digest('SHA-256', data);
        return btoa(String.fromCharCode(...new Uint8Array(hash)))
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=+$/, '');
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (code) {
            const codeVerifier = sessionStorage.getItem('pkce_verifier');
            console.log("Code from Microsoft:", code);
            console.log("Code verifier from storage:", codeVerifier);
            console.log("OrgID:", userData.org_data.org_id);

            (async () => {
                const body = {
                    code,
                    code_verifier: codeVerifier,
                    org_id: userData.user_data.org_id,
                    user_id: userData.user_data.user_id,
                    database: userData.vector_databases[0].database_id
                };
                console.log("Sending to backend:", body);

                try {
                    const response = await fetch('https://jbuyfsfyd0.execute-api.ca-central-1.amazonaws.com/default/onedrive/auth', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(body)
                    });

                    const data = await response.json();
                    console.log("Response from backend:", data);

                    if (data.success) {
                        setConnectedServices(prev => ({ ...prev, onedrive: true }));
                    }
                } catch (error) {
                    console.error('Error:', error);
                }
            })();
        }
    }, []);

    const handleConnect = async (service) => {
        if (service === 'onedrive') {
            const codeVerifier = generateCodeVerifier();
            const codeChallenge = await generateCodeChallenge(codeVerifier);
            sessionStorage.setItem('pkce_verifier', codeVerifier);

            const params = new URLSearchParams({
                client_id: CLIENT_ID,
                response_type: 'code',
                redirect_uri: REDIRECT_URI,
                scope: 'Files.Read Files.Read.All offline_access',
                response_mode: 'query',
                code_challenge: codeChallenge,
                code_challenge_method: 'S256',
                state: userData.org_data.org_id
            });

            // Immediate redirect
            window.location.href = `${MICROSOFT_OAUTH_URL}?${params.toString()}`;
        }
    };


    // Update button rendering to show connection state
    const renderConnectButton = (service, label = 'Connect') => {
        if (service === 'onedrive') {
            return (
                <button
                    className="connect-button"
                    onClick={() => handleConnect(service)}
                    disabled={isConnecting || !userData?.user_data?.org_id}
                >
                    {isConnecting ? 'Connecting...' :
                        connectedServices.onedrive ? 'Connected' :
                            label}
                </button>
            );
        }
        return (
            <button className="connect-button" disabled>
                Coming Soon
            </button>
        );
    };


    return (
        <div className="settings-container">
            <div className="settings-header">
                <h1>Settings</h1>
            </div>

            <div className="tabs">
                <button
                    className={`tab ${activeTab === 'connections' ? 'active' : ''}`}
                    onClick={() => setActiveTab('connections')}
                >
                    Data Connections
                </button>
                <button
                    className={`tab ${activeTab === 'security' ? 'active' : ''}`}
                    onClick={() => setActiveTab('security')}
                >
                    Security
                </button>
                <button
                    className={`tab ${activeTab === 'users' ? 'active' : ''}`}
                    onClick={() => setActiveTab('users')}
                >
                    User Management
                </button>
            </div>

            <div className="tab-content">
                {activeTab === 'connections' && (
                    <div className="connections-grid">
                        <div className="connection-card">
                            <div className="card-header">
                                <div className="service-info">
                                    <Cloud size={24} />
                                    <h3>OneDrive</h3>
                                </div>
                                {renderConnectButton('onedrive')}
                            </div>
                            <p>Connect your OneDrive account to index and search your files</p>
                            <p className="status">
                                Status: {connectedServices.onedrive ? 'Connected' : 'Not Connected'}
                            </p>
                        </div>

                        <div className="connection-card">
                            <div className="card-header">
                                <div className="service-info">
                                    <Cloud size={24} />
                                    <h3>Google Drive</h3>
                                </div>
                                <button className="connect-button" disabled>
                                    Coming Soon
                                </button>
                            </div>
                            <p>Connect Google Drive to search across your team's documents</p>
                            <p className="status">Status: Coming Soon</p>
                        </div>

                        <div className="connection-card">
                            <div className="card-header">
                                <div className="service-info">
                                    <Mail size={24} />
                                    <h3>Outlook</h3>
                                </div>
                                <button className="connect-button" disabled>
                                    Coming Soon
                                </button>
                            </div>
                            <p>Index your Outlook emails for quick reference</p>
                            <p className="status">Status: Coming Soon</p>
                        </div>

                        <div className="connection-card">
                            <div className="card-header">
                                <div className="service-info">
                                    <Database size={24} />
                                    <h3>Database</h3>
                                </div>
                                <button className="connect-button" disabled>
                                    Coming Soon
                                </button>
                            </div>
                            <p>Connect your databases for comprehensive search</p>
                            <p className="status">Status: Coming Soon</p>
                        </div>
                    </div>
                )}

                {activeTab === 'security' && (
                    <div className="security-content">
                        <h2>Security Settings</h2>
                        <p>Security settings coming soon</p>
                    </div>
                )}

                {activeTab === 'users' && (
                    <div className="users-content">
                        <h2>User Management</h2>
                        <p>User management settings coming soon</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SettingsPage;