// hooks/useUsers.js
import { useState, useEffect } from 'react';
import { getIdToken } from '../services/userDataService';
import process from 'process';

export const useUsers = (orgId) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    const fetchUsers = async () => {
      if (!orgId) return;
      const idToken = await getIdToken();
      setLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/fetchUsers?org_id=${orgId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': idToken,
            }
        });
        const users_object = await response.json();
        console.log('users_object', users_object);
        setUsers(users_object.users);
      } catch (err) {
        console.error('Error fetching users:', err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [orgId]);

  return { users, loading, error };
};