import React from 'react';
import Candidates from './candidates';
import ResumeUpload from './resumeUpload';
import JobDescriptionUpload from './jobDescriptionUpload';
import './dashboard.css';

const RecruitAIDashboard = ({userData}) => {
  return (
    <div className='recruit-ai-container'>
      {/* Top Row: Job Description Section */}
      <div className='recruit-ai-left'>
        <JobDescriptionUpload userData={userData}/>
      </div>

      {/* Bottom Row: Candidates (left) + Resume (right) */}
      <div className='recruit-ai-right'>
        <Candidates />
        <ResumeUpload userData={userData} />
      </div>
    </div>
  );
};

export default RecruitAIDashboard;
