import React, { createContext, useState, useCallback } from 'react';

   export const NotificationContext = createContext();

   export const NotificationProvider = ({ children }) => {
     const [notifications, setNotifications] = useState([]);

     const addNotification = useCallback((message, type = 'info') => {
       const id = Date.now();
       setNotifications(prev => [...prev, { id, message, type }]);
       setTimeout(() => removeNotification(id), 10000); // Auto remove after 5 seconds
     }, []);

     const removeNotification = useCallback((id) => {
       setNotifications(prev => prev.filter(notification => notification.id !== id));
     }, []);

     return (
       <NotificationContext.Provider value={{ notifications, addNotification, removeNotification }}>
         {children}
       </NotificationContext.Provider>
     );
   };